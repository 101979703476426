import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims";
import { Card, Tag } from "../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_rally_delete,
  q_rally_edit,
  q_rally_info,
  q_rally_leader,
  q_rally_list,
} from "../queries/queries";
import {
  iso_format,
  getv,
  cdelay,
  nils,
  jstr,
  from_time_mini,
  nano,
} from "../utils/utils";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { class_cn, class_text, tablecn } from "../utils/cn_map";
import {
  CapsuleSwitch,
  F_DateInput,
  F_Input,
  F_OptionInput,
} from "../components/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext, useNowContext } from "../App";
import { useAccountContext } from "../wrappers/AccountWrapper";

const RallyContext = createContext();
const useRallyContext = () => useContext(RallyContext);

const RallyLeader = () => {
  const rcon = useRallyContext();
  const { qoleader, my, set_my } = rcon;

  const { hids: vhids } = useAccountContext();

  const [qualifiers, vmap, hmap] = useMemo(() => {
    let leader = getv(qoleader, "data.result") || {};
    let { qualifiers = [], vmap, hmap } = leader;
    return [qualifiers, vmap, hmap];
  }, [qoleader.dataUpdatedAt]);

  const show_qualifiers = useMemo(() => {
    return qualifiers.filter((r) => {
      r.show = my == true ? vhids.includes(r.hid) : true;
      return r.show;
    });
  }, [jstr(qualifiers), jstr(vhids), my]);

  const capmap = _.chain([
    [true, "My Bikes"],
    [false, "All Bikes"],
  ])
    .fromPairs()
    .value();

  return (
    <div className="w-full mx-auto overflow-auto">
      <Card className={"card-basic-bg card-grad-border"}>
        <div className={twMerge("", "fr-sc resp-gap-2")}>
          <CapsuleSwitch
            {...{
              label: "",
              options: [true, false],
              show_label: false,
              txt_fn: (o) => capmap[o],
              path: null,
              nf: my,
              set_nf: set_my,
              tag_cn: "xs:w-[5rem] lg:w-[8rem] py-1",
            }}
          />
          <div class="flex-1"></div>
        </div>
        {qoleader.isLoading ? (
          <Loader01c />
        ) : qiserr(qoleader) ? (
          <p class="text-red-300 text-center resp-text--1">
            {qiserr(qoleader)}
          </p>
        ) : qissuccesss(qoleader) && _.isEmpty(qualifiers) ? (
          <p class="text-yellow-300 text-center resp-text--1">
            {"No Qualifiers"}
          </p>
        ) : qissuccesss(qoleader) && _.isEmpty(show_qualifiers) ? (
          <p class="text-yellow-300 text-center resp-text--1">
            {"No Vault Bikes Qualified"}
          </p>
        ) : qissuccesss(qoleader) && !_.isEmpty(show_qualifiers) ? (
          <div class="">
            <table
              className={twMerge(
                tablecn.table,
                "w-max mx-auto thintdrowp4-table",
                "resp-text--2",
              )}
            >
              <thead>
                <tr class="text-acc0 font-digi text-center">
                  <td>Race</td>
                  <td>Bike</td>
                  <td>#F-Ty-Elem</td>
                  <td>Racing Vault</td>
                </tr>
              </thead>
              <tbody>
                {qualifiers.map((r) => {
                  let h = hmap[r.hid];
                  let v = vmap[r.racing_vault];

                  return (
                    <tr>
                      <td>
                        <Link target={"_blank"} to={`/race/${r.rid}`}>
                          <div class="fc-ss">
                            <div class="fr-ss">
                              <span class="text-acc0">{r.rid}</span>
                            </div>
                            <div class="fr-sc resp-gap-1">
                              <span
                                className={twMerge(
                                  class_cn(r.class),
                                  "rounded-md resp-p-1 resp-text--3",
                                )}
                              >
                                {class_text(r.class)}
                              </span>
                              <span>CB{r.cb}00</span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <Link target={"_blank"} to={`/bike/${r.hid}`}>
                          <div class="fc-ss resp-gap-2 text-acc0 font-digi">
                            <span>#{r.hid}</span>
                            <span className="text-white">{h.name}</span>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div class="grid grid-cols-3 text-center">
                          <span>F{h.fno}</span>
                          <span className="">{_.capitalize(h.type)}</span>
                          <div class="d">{h.element}</div>
                        </div>
                      </td>
                      <td>
                        <Link target={"_blank"} to={`/vault/${r.racing_vault}`}>
                          <div class="fc-ss">
                            <span className="text-acc0">{v}</span>
                            <span className="resp-text--4">
                              {r.racing_vault}
                            </span>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export const RallyPage = () => {
  const { psearch, upd_psearch } = useAppContext();
  const history = useNavigate();
  const params = useParams();
  const ralid = useMemo(() => getv(params, "ralid"), [params]);
  const [my, set_my] = useState(psearch?.my == "true" ? true : false);

  const [qoinfo, qoleader] = useQueries([
    q_rally_info({ ralid }, { ralid: ralid !== "new" }),
    q_rally_leader(
      { ralid },
      {
        enabled: ralid !== "new",
        staleTime: 60 * 1e3,
        refetchInterval: 60 * 1e3,
      },
    ),
  ]);
  const info = useMemo(
    () => getv(qoinfo, "data.result"),
    [qoinfo.dataUpdatedAt],
  );
  const leader = useMemo(
    () => getv(qoleader, "data.result"),
    [qoleader.dataUpdatedAt],
  );

  useEffect(() => {
    let r = {};
    r.my = my == true ? "true" : "false";
    upd_psearch({ ...r });
  }, [my]);

  const rcon = {
    ralid,

    qoinfo,
    info,

    qoleader,
    leader,

    my,
    set_my,
  };

  const { now } = useNowContext();

  return (
    <RallyContext.Provider value={rcon}>
      <div class="h-page">
        <div class="w-[60rem] mx-auto max-w-[98vw]">
          <div class="h-[3rem]"></div>
          <div class="fr-sc">
            <Link to={`/rally`}>
              <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
                <div class="fr-sc resp-gap-2 resp-text--2">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <span class="">All Rally</span>
                </div>
              </Tag>
            </Link>
          </div>
          {qoinfo.isLoading ? (
            <Loader01c />
          ) : qissuccesss(qoinfo) && !_.isEmpty(info) ? (
            <Card className="card-basic-bg card-grad-border xs:w-full lg:w-[30rem] max-w-[95vw]">
              <p class="text-center italic resp-text-1 font-digi">
                {info.name}
              </p>
              <div class="fr-sc">
                <span className="resp-text--1 bg-r2dark/60 font-digi rounded-md -skew-x-12 resp-p-2 ">
                  {info.qualifiers_n} Qualifiers
                </span>
                <div class="flex-1"></div>

                <div class="flex flex-col justify-end items-end">
                  <span
                    className={twMerge(
                      "resp-text--1 uppercase ",
                      info.status == "yettostart"
                        ? "text-yellow-300"
                        : info.status == "qualifying"
                          ? "text-green-300"
                          : "text-red-300",
                    )}
                  >
                    {info.status}
                  </span>
                  <span className="resp-text--2">
                    {now > nano(getv(info, "qualifying_times.st"))
                      ? `Started at ${iso_format(getv(info, "qualifying_times.st"))}`
                      : `Starts in ${from_time_mini(getv(info, "qualifying_times.st"))}`}
                  </span>
                  <span className="resp-text--2">
                    {now > nano(getv(info, "qualifying_times.ed"))
                      ? `Ended at ${iso_format(getv(info, "qualifying_times.ed"))}`
                      : `Ends in ${from_time_mini(getv(info, "qualifying_times.ed"))}`}
                  </span>
                </div>
              </div>
            </Card>
          ) : null}

          <hr class="w-[30rem] my-4 max-w-[90vw] mx-auto" />
          <RallyLeader />

          <div class="h-[5rem]"></div>
        </div>
      </div>
    </RallyContext.Provider>
  );
};

export const ListRallyPage = () => {
  const { psearch, upd_psearch } = useAppContext();
  const { hids: vhids, bikesob } = useAccountContext();

  const [mybikes, set_mybikes] = useState(
    psearch?.mybikes == "true" ? true : false,
  );

  const [qolist] = useQueries([
    q_rally_list(
      {
        filthids: mybikes ? vhids : null,
      },
      {
        staleTime: 2 * 60 * 1e3,
        refetchInterval: 2 * 60 * 1e3,
      },
    ),
  ]);

  const list = useMemo(
    () => getv(qolist, "data.result"),
    [qolist.dataUpdatedAt],
  );

  useEffect(() => {
    let r = {};
    r.mybikes = mybikes ? "true" : "false";
    upd_psearch({ ...r });
  }, [mybikes]);

  const capmap = _.chain([
    [true, "My Bikes"],
    [false, "All"],
  ])
    .fromPairs()
    .value();

  return (
    <div class="h-page">
      <div class="w-[60rem] mx-auto max-w-[98vw]">
        <div class="h-[3rem]"></div>

        <p class="text-center text-acc0 my-4 resp-text-1 font-digi">Rally</p>
        <hr class="w-[30rem] my-4 max-w-[90vw] mx-auto" />

        <div class="fr-sc my-2">
          <CapsuleSwitch
            {...{
              label: "",
              options: [true, false],
              show_label: false,
              txt_fn: (o) => capmap[o],
              path: null,
              nf: mybikes,
              set_nf: set_mybikes,
              tag_cn: "xs:w-[5rem] lg:w-[8rem] py-1",
            }}
          />
        </div>

        {qolist.isLoading ? (
          <Loader01c />
        ) : qiserr(qolist) ? (
          <p class="text-red-300 text-center resp-text--1">{qiserr(qolist)}</p>
        ) : qissuccesss(qolist) && _.isEmpty(list) ? (
          <p class="text-yellow-300 text-center resp-text--1">{"No Rally"}</p>
        ) : qissuccesss(qolist) && !_.isEmpty(list) ? (
          <div class="">
            {list.map((r) => {
              let commhids = _.intersection(vhids, r.hids);
              return (
                <Link target={"_self"} to={`/rally/${r.ralid}`}>
                  <Card className="w-full">
                    <div class="fr-sc resp-gap-2 resp-text--2">
                      <p class="text-acc0 font-mono ">{r.ralid}</p>
                      <p class="text-white font-digi resp-test--1 ">{r.name}</p>
                      <div class="flex-1"></div>
                      <p class="text-green-300 uppercase">{r.status}</p>
                    </div>

                    <div class="fr-sc resp-gap-4  font-digi italic my-2">
                      {commhids.map((hid) => {
                        let h = bikesob[hid] || {};
                        let qual = r.qual_hids.includes(hid);

                        return (
                          <div className="fr-sc resp-gap-1">
                            <FontAwesomeIcon
                              className={`text-${h.color}`}
                              icon={faCircle}
                            />
                            <span
                              className={twMerge(
                                qual ? "text-acc0" : "text-slate-500",
                              )}
                            >
                              {h.name ?? hid}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </Link>
              );
            })}
          </div>
        ) : null}
        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
