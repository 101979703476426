import {
  faChevronDown,
  faChevronUp,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useInfiniteQuery, useQueries } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import { set_state_ob } from "../components/input";
import { MiniElementTag, MiniGenderTag } from "../components/ShortComps";
import { Img, Tag } from "../components/utilityComps";
import { fpost } from "../queries/fetch";
import {
  btbk,
  qiserr,
  qissuccesss,
  q_dnamarket_activity_marketid,
  q_inbox_get,
  q_inbox_mark_read,
  q_vault_claim_training_bike,
} from "../queries/queries";
import { prizebox_img } from "../utils/links";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso_format,
  jstr,
  nils,
  to_time_mini,
} from "../utils/utils";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { IfInView } from "./FinishedRacesPage";
import { useVaultContext } from "./Vault";

const InboxContext = React.createContext();
const useInboxContext = () => React.useContext(InboxContext);

const ClaimTrainerBike = () => {
  let accon = useAccountContext();
  let { vault } = accon;
  const vault_name = useMemo(
    () => getv(accon, "vdoc.vault_name"),
    [jstr(accon)],
  );
  const [resp, set_resp] = useState(null);

  return (
    <div className="fc-cc resp-gap-2 resp-text--1">
      <p className="font-digi resp-text-0">
        Unleash your inner racer with a FREE Trainer Bike at DNA Racing!
      </p>
      <p>
        Gear up for an adrenaline-pumping ride! We're offering you a FREE
        Trainer Bike to experience the electrifying world of DNA Racing.{" "}
      </p>
      <div class="fr-sc resp-gap-1">
        {resp?.loading && <Loader01c size="s" />}
        <Link to="/claim-trainer">
          <Tag className="bg-acc0/40 -skew-x-12 font-digi">Claim Now</Tag>
        </Link>
      </div>
      {!nils(resp?.err) && <p className="text-red-400">{resp?.err}</p>}
      {!nils(resp?.msg) && <p className="text-acc0">{resp?.msg}</p>}

      <hr class="my-2" />

      <p>
        This isn't just any bike; it's your ticket to 50 thrilling races against
        other speed demons. Hone your skills, compete for real cash prizes, and
        discover the exhilaration of DNA Racing!
        <br />
        No strings attached, just pure racing fun.
      </p>

      <p className="font-digi resp-text-0">
        Ready to put your pedal to the metal? Claim your FREE Trainer Bike now!
      </p>
    </div>
  );
};

const BidMsg = ({ idoc }) => {
  const marketid = getv(idoc, "dets.marketid");
  const [q] = useQueries([q_dnamarket_activity_marketid({ marketid })]);
  const d = useMemo(() => getv(q, "data.result"), [q.dataUpdatedAt]);
  const status = getv(d, "m.status");
  const i = getv(d, "info");
  const asty = getv(d, "m.asset_type");
  const token_id = getv(d, "m.token_id");

  const market_link = useMemo(() => {
    let tok_sender = getv(d, "m.tok_sender");
    if (["core", "skin", "godtoken"].includes(asty))
      return `https://market.dnaracing.run/asset/${asty}/${token_id}`;
    else
      return `https://market.dnaracing.run/asset/${asty}/${token_id}?a=${tok_sender}`;
  }, [jstr(d)]);
  return (
    <div className="resp-text--1">
      {q.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(q) ? (
        <p class="text-center text-red-400">{qiserr(q)}</p>
      ) : qissuccesss(q) ? (
        <div className="max-w-[90vw] w-[40rem] mx-auto">
          <div class="fr-sc my-2">
            <div class="fc-ss">
              <span>BID by {getv(d, `vmap.${getv(d, "m.tok_reciever")}`)}</span>
              <span>Created on {iso_format(getv(d, "m.created_at"))}</span>
            </div>
            <div class="flex-1"></div>
            <div class="fc-ss">
              <span>
                Status:
                <span
                  className={twMerge(
                    "resp-text-0",
                    status == "open" ? "text-acc0" : "text-red-400",
                  )}
                >
                  {_.upperCase(status)}
                </span>
              </span>
              {status == "open" && (
                <span className="">
                  Expires in {from_time_mini(getv(d, "m.expires_at"))}
                </span>
              )}
            </div>
          </div>
          <div class="fr-sc my-2">
            <div class="flex-1">
              {asty == "core" ? (
                <>
                  <div class="fc-ss">
                    <span>Core #{token_id}</span>
                    <span className="text-acc0 italic resp-text-1 font-digi">
                      {getv(i, "name")}
                    </span>
                    <div class="fr-sc resp-gap-1 resp-text--2">
                      <span>{_.capitalize(getv(i, "type"))}</span>
                      <span>F{getv(i, "fno")}</span>
                      <MiniElementTag
                        className={"resp-text-1"}
                        element={getv(i, "element")}
                      />
                      <MiniGenderTag
                        className={"resp-text-1"}
                        gender={getv(i, "gender")}
                      />
                    </div>
                  </div>
                </>
              ) : asty == "skin" ? (
                <div class="fc-ss">
                  <span>Skin #{token_id}</span>
                  <span className="text-acc0 italic resp-text-1 font-digi">
                    {getv(i, "skin")}
                  </span>
                  <div class="fr-sc resp-gap-1 resp-text--2">
                    <span>{_.capitalize(getv(i, "rarity"))}</span>
                  </div>
                </div>
              ) : asty == "godtoken" ? (
                <div class="fc-ss resp-text--1 text-acc0">
                  <span>GOD Token #{token_id}</span>
                </div>
              ) : asty.startsWith("coreloot") ? (
                <span class="text-acc0 resp-text--1 font-digi">
                  Core Lootbox
                </span>
              ) : asty.startsWith("skinloot") ? (
                <span class="text-acc0 resp-text--1 font-digi">
                  Skin Lootbox
                </span>
              ) : asty.startsWith("skingameloot") ? (
                <span class="text-acc0 resp-text--1 font-digi">
                  Skin Rarirty Type Lootbox
                </span>
              ) : asty.startsWith("prizebox") ? (
                <span class="text-acc0 resp-text--1 font-digi"> Prize Box</span>
              ) : (
                `${asty}:${token_id}`
              )}
            </div>
            <div class="fc-cc resp-gap-2">
              {!["core", "skin", "godtoken"].includes(asty) && (
                <span className="resp-text-1 text-acc0">
                  QTY: {getv(d, "m.qty")}{" "}
                </span>
              )}
              <span className="resp-text--1 text-acc0">bid for </span>
              <span className="resp-text-1 text-acc0">
                {dec(getv(d, "m.amt"), 4)} {getv(d, "m.token")}
              </span>
              <Link target="_blank" to={market_link}>
                <Tag className="bg-acc0/40 -skew-x-12 font-digi">
                  View on Market
                </Tag>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const get_inner_info_inbox_msg = (idoc) => {
  if (nils(idoc)) return ["", ""];
  let { date, title, msg, type } = idoc;
  if (type == "prizebox_win") {
    return [
      "You won a PrizeBox!",
      <div className="fc-cc resp-gap-2 resp-text--1">
        <div className="xs:w-[50vw] md:w-[10rem] lg:w-[16rem]">
          <Img img={prizebox_img} />
        </div>
        <p>Congratulations!! You won a prizebox</p>
        <p>Open quick to find out what you got.</p>

        <div className="fr-sc">
          <Tag
            onClick={() => {
              window.open(`https://www.dnaracing.run/prizebox`);
            }}
            className="bg-acc0/40 -skew-x-12 font-digi"
          >
            <span className="resp-text--1">Open Now</span>
          </Tag>
        </div>
      </div>,
    ];
  } else if (type == "welcome_dna") {
    return [
      "Welcome to DNA Racing",
      <div className="fc-cc resp-gap-2">
        <p>Thank you for joining DNA Racing</p>
      </div>,
    ];
  } else if (type == "free_trainer_bike") {
    return ["Claim your FREE Trainer Bike Now!!", <ClaimTrainerBike />];
  } else if (type == "vault_bid") {
    return [title, <BidMsg {...{ idoc }} />];
  } else if (type == "jackpotwin") {
    const jtype = getv(idoc, "ext.jtype");
    const jackpotid = getv(idoc, "ext.jackpotid");
    return [
      `You won a ${_.capitalize(jtype)}`,
      <div className="fc-cc resp-gap-2 resp-text--1">
        <div className="xs:w-[50vw] md:w-[10rem] lg:w-[16rem]">
          <Img
            img={
              "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/jacpot-gold.png"
            }
          />
        </div>
        <p>Congratulations!! You won a {jtype} jackpot</p>

        <div className="fr-sc">
          <Link to={`/jackpot?jid=${jackpotid}&jtype=${jtype}`}>
            <Tag className="bg-acc0/40 -skew-x-12 font-digi">
              <span className="resp-text--1">Check Now</span>
            </Tag>
          </Link>
        </div>
      </div>,
    ];
  } else if (type == "msg") {
    return [title, <p className="resp-text--1 whitespace-pre-wrap">{msg}</p>];
  } else {
    return ["", ""];
  }
};

export const InboxMsgCard = ({ idoc }) => {
  /* let { read = false, date, title, msg, type } = idoc; */

  const [head, msg] = useMemo(() => {
    return get_inner_info_inbox_msg(idoc);
  }, [jstr(idoc)]);
  return (
    <div className="max-h-full">
      <p className="text-acc0 resp-text-1 font-digi italic">{head}</p>
      <div className="fr-sc w-full ">
        <div className="flex-1"></div>
        <span className="resp-text--2 text-yellow-300">
          {iso_format(idoc?.date)}
        </span>
      </div>
      <div className="xs:max-h-[27vh] lg:max-h-[34vh] overflow-auto p-2">
        {msg}
      </div>
      <div class="h-[1rem]"></div>
    </div>
  );
};
export const InboxMsgMiniCard = ({ idoc }) => {
  const incon = useInboxContext();
  const { mark_inbox_read } = incon;

  const [head, msg] = useMemo(
    () => get_inner_info_inbox_msg(idoc),
    [jstr(idoc)],
  );

  const [expanded, set_expanded] = useState(false);
  useEffect(() => {
    if (expanded == true) mark_inbox_read(idoc.id, true);
  }, [expanded]);

  return (
    <div
      className={twMerge(
        expanded
          ? "card-basic-bg rounded-md"
          : idoc.read !== true
            ? "bg-acc0/20"
            : "bg-tranparent rounded-0",
        " transition duration-400 border-b border-acc0/30",
      )}
    >
      <div
        onClick={() => set_expanded(!expanded)}
        className={twMerge(
          "fr-sc p-2 resp-gap-2  hover:bg-lig/40 resp-text--1",
          expanded ? "text-transparent" : "",
        )}
      >
        {idoc.is_acc == true && (
          <span className="text-acc0 ">
            <FontAwesomeIcon icon={faStar} />
          </span>
        )}
        <span className="font-digi">{head}</span>
        <div class="flex-1"></div>
        <span>{iso_format(idoc.date)}</span>
        <FontAwesomeIcon
          className="text-white"
          icon={expanded ? faChevronUp : faChevronDown}
        />
      </div>
      {expanded == true && (
        <div class="mx-auto p-2">
          <InboxMsgCard idoc={idoc} />
        </div>
      )}
    </div>
  );
};

export const Inbox = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const aucon = useAuthContext();
  const { vault } = aucon;

  const [page, set_page] = useState(psearch.page ?? 0);
  const [limit, set_limit] = useState(50);

  const [idocs, set_idocs] = useState([]);

  const fetchpage = ({ page = 0 }) => {
    if (page == -1) return null;
    return fpost(`${btbk}/fbike/inbox/get`, { page, vault });
  };

  const q = useInfiniteQuery({
    queryKey: ["inboxmessages"],
    queryFn: (pars) => {
      // console.log("queryFn", pars.pageParam);
      return fetchpage(pars.pageParam || {});
    },
    getNextPageParam: (lastPage, allPages) => {
      let page = getv(lastPage, "result.next_page");
      return { page };
    },
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
  });

  const def_idocs = useMemo(() => {
    let rs = _.chain(q.data?.pages)
      .filter((e) => getv(e, "status") == "success")
      .map((e) => getv(e, "result.msgs"))
      .flatten()
      .uniqBy("id")
      .map((r) => {
        return r;
      })
      .value();
    // console.log("def_idocs", rs);
    return rs;
  }, [q.dataUpdatedAt]);
  useEffect(() => {
    set_idocs(def_idocs);
  }, [jstr(def_idocs)]);

  const mark_inbox_read = async (id, read = true) => {
    try {
      let ac = null;
      let nidocs = _.chain(idocs)
        .cloneDeep()
        .map((e) => {
          if (e.id == id) {
            e.read = read;
            ac = e;
          }
          return e;
        })
        .value();
      // console.log(nidocs, ac);
      if (nils(ac)) return;

      set_idocs(nidocs);

      let resp = await q_inbox_mark_read(
        ac.is_acc == true
          ? { id, is_acc: ac.is_acc, accid: ac.accid, read: true, vault }
          : { id, read: true },
      ).queryFn();
      // console.log(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const incon = {
    page,
    set_page,
    limit,
    set_limit,
    mark_inbox_read,
  };

  return (
    <InboxContext.Provider value={incon}>
      <div className="h-page">
        <div className="mx-auto max-w-[98vw] w-[60rem]">
          <div class="h-[4rem]"></div>
          <p className="text-center resp-text-1 textacc0 font-digi my-2">
            Inbox
          </p>
          <div class="backdrop-blur-md">
            {!_.isEmpty(idocs) &&
              idocs.map((e) => <InboxMsgMiniCard idoc={e} id={e.id} />)}
          </div>

          <div className="fr-cc ">
            {q.isFetchingNextPage && <Loader01c size="s" />}
            <IfInView runViewFn={!q.isFetching} onView={q.fetchNextPage} />
          </div>

          <div class="h-[4rem]"></div>
        </div>
      </div>
    </InboxContext.Provider>
  );
};
