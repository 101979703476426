const abi = require("./abi.json");
const ethers = require("ethers");
const _ = require("lodash");
const { contractAddress_list } = require("../constants.js");
const { nils } = require("../../utils/utils.js");

const getgasinfo = () => ({});

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async mintToken(recipient, _name, _rarity) {
    const gasinfo = await getgasinfo();
    let tx = await this.contract.mintToken(recipient, _name, _rarity, gasinfo);
    tx = await tx.wait();
    return tx;
  }

  async mintToken_w_box(lootboxTxHash, recipient, _name, _rarity) {
    const gasinfo = await getgasinfo();
    let tx = await this.contract.mintToken_w_box(
      lootboxTxHash,
      recipient,
      _name,
      _rarity,
      gasinfo,
    );
    tx = await tx.wait();
    return tx;
  }

  async applySkin(skinid, hid) {
    const gasinfo = await getgasinfo();
    let tx = await this.contract.applySkin(skinid, hid, gasinfo);
    // tx = await tx.wait();
    return tx;
  }

  async releaseSkin(skinid) {
    const gasinfo = await getgasinfo();
    let tx = await this.contract.releaseSkin(skinid, gasinfo);
    // tx = await tx.wait();
    return tx;
  }

  async getTokenInfo(tokenId) {
    try {
      let raw = await this.contract.getTokenInfo(tokenId);
      console.log(raw);
      let [skin, owner] = raw;
      if (_.isString(owner)) owner = _.lowerCase(owner);
      const skinObject = {
        skinid: parseInt(skin[0]),
        skin: skin[1],
        name: skin[1],
        rarity: skin[2],
        appliedTo: parseInt(skin[3]),
        vault: owner,
      };

      return skinObject;
    } catch (err) {
      console.log(
        `Error fetching token info for token ID ${tokenId}:`,
        err.message,
      );
      return null;
    }
  }

  async getTokenOwner(tokenId) {
    return await this.contract.getTokenOwner(tokenId);
  }

  async getTokenIdsOfAccount(addr) {
    let tokenIds = await this.contract.getTokenIdsOfAccount(addr);
    return tokenIds.map((id) => parseInt(id));
  }

  async burn(tokenId) {
    const gasinfo = await getgasinfo();
    let tx = this.contract.burn(tokenId, gasinfo);
    return tx;
  }

  async burnTokens(tokenIds) {
    const gasinfo = await getgasinfo();
    let tx = this.contract.burnTokens(tokenIds, gasinfo);
    return tx;
  }
}

const name = "fbikeskin";
const get_contract_address = () => {
  const contractAddress = contractAddress_list[name];
  return contractAddress;
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

const FBikeSkin = {
  class: Contract,
  get_contract: get_contract,
};

module.exports = FBikeSkin;
