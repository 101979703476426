import { nils } from "./utils.js";

export const metamaskimg =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png";

export const backdropimg =
  "https://img.freepik.com/premium-photo/abstract-futuristic-with-glowing-neon-light-grid-line-pattern-background-technology-style_79161-851.jpg";

export const polychainimg =
  "https://polygonscan.com/assets/generic/html/favicon-light.ico";
export const wethimg =
  "https://www.marketbeat.com/logos/cryptocurrencies/weth-WETH.png?v=2023-08-07";

export const gets_skinurl = (skin) => {
  if (nils(skin)) return null;
  const baseURL =
    "https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/";
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}/${formattedSkin}.jpg`;
  return newImageURL;
};

export const gets_skinbaseurl = (skin) => {
  if (nils(skin)) return null;
  const baseURL =
    "https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/";
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}`;
  return newImageURL;
};

export const haloweenbackdrop = `https://static.vecteezy.com/system/resources/previews/027/807/583/non_2x/spooky-halloween-wallpaper-with-pumpkin-and-old-house-free-photo.jpg`;

export const prizebox_img = "https://i.imgur.com/6M8DdOR.png";

export const core_market_link = (hid) =>
  `https://market.dnaracing.run/asset/core/${hid}`;
export const core_os_link = (hid) =>
  `https://opensea.io/assets/matic/0xce8090de88bba13d3cea5d73f8baf1f1c1a61b16/${hid}`;

export const faction_img = (f) =>
  `https://dna-run-public.s3.us-east-2.amazonaws.com/factions-logos/${f}.png`;
