import { getv, iso, jparse, jstr, nano, nils, tofeth } from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abi.json";

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  // function add_filler( string memory rver, uint n, uint fee, string memory token, string memory args) public {
  async add_filler(rver, n, fee, token, args) {
    let resp = await this.contract.add_filler(rver, n, fee, token, args);
    return resp;
  }
}

const k = "raceautofiller";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({
    contractAddress: get_contract_address(),
    provider,
    signer,
    ...ext,
  });
  return runner;
};

export const RaceAutoFiller = {
  k,
  get_contract,
  get_contract_address,
};
