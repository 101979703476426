import _ from "lodash";
import moment from "moment";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Card, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { dec, getv, jstr, nils } from "../utils/utils.js";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
} from "../utils/filt.js";
import { InpAutoWrap } from "../components/input.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { elementmap, gendermap, tablecn } from "../utils/cn_map.js";
import { q_os_search, qiserr, qissuccesss } from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { Link } from "react-router-dom";
import { ElementTag, GenderTag } from "../components/ShortComps.js";

const MarkContext = createContext();
const useMarkContext = () => useContext(MarkContext);

const CoreFiltSection = () => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  const basefilt = {};
  const [filt, set_filt] = useState({
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      label: "Element",
      show_label: true,
      options: _.keys(elementmap),
      filter_exception: [],
      vals: _.compact(basefilt?.element),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark ${elementmap[o]?.text}`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white ${elementmap[op]?.bg} -skew-x-12`;
      },
    },
    gender: {
      type: "options",
      path: "gender",
      cfilter: true,
      label: "Gender",
      show_label: true,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark ${gendermap[o]?.text}`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white ${gendermap[op]?.bg} -skew-x-12`;
      },
    },
    type: {
      type: "options",
      path: "type",
      cfilter: true,
      label: "Type",
      show_label: true,
      options: ["genesis", "morphed"],
      filter_exception: [],
      vals: _.compact(basefilt?.type),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
    fno: {
      type: "range",
      path: "fno",
      cfilter: true,
      label: "#F.No",
      show_label: true,
      options: ["genesis", "morphed"],
      filter_exception: [],
      vals: basefilt?.fno ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      options: ["genesis", "morphed"],
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "core") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-core", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "fno" }} />
      </div>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};
const CoreTableHeads = () => {
  return (
    <>
      <td>
        <span className="resp-text--1">Name</span>
      </td>
      <td>
        <span className="resp-text--1">Type</span>
      </td>
      <td>
        <span className="resp-text--1">Element</span>
      </td>
      <td>
        <span className="resp-text--1">Gender</span>
      </td>
      <td>
        <span className="resp-text--1">F.No</span>
      </td>
    </>
  );
};
const CoreTableRow = ({ h }) => {
  return (
    <>
      <td>
        <p className="resp-text--1 text-acc0 font-digi min-w-[10rem] ">
          {h.name}
        </p>
      </td>
      <td>
        <span className="resp-text--1">{_.capitalize(h.type)}</span>
      </td>
      <td>
        <ElementTag className="resp-text--2" element={h.element} />
      </td>
      <td>
        <GenderTag className="resp-text--2" gender={h.gender} />
      </td>
      <td>
        <span className="resp-text--1 text-acc0 font-digi italic">{`F${h.fno}`}</span>
      </td>
    </>
  );
};

const SkinTableHeads = () => {
  return (
    <>
      <td>
        <span className="resp-text--1">Name</span>
      </td>
      <td>
        <span className="resp-text--1">Rarity</span>
      </td>
    </>
  );
};
const SkinTableRow = ({ h }) => {
  return (
    <>
      <td>
        <p className="resp-text--1 text-acc0 font-digi min-w-[10rem] ">
          {h.name}
        </p>
      </td>
      <td>
        <span className="resp-text--1">{_.capitalize(h.rarity)}</span>
      </td>
    </>
  );
};
const SkinFiltSection = () => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  const basefilt = {};
  const [filt, set_filt] = useState({
    rarity: {
      type: "options",
      path: "rarity",
      cfilter: true,
      label: "rarity",
      show_label: true,
      options: [
        "common",
        "uncommon",
        "rare",
        "legendary",
        "ultimate",
        "exclusive",
      ],
      filter_exception: [],
      vals: _.compact(basefilt?.rarity),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white bg-green-400/30 -skew-x-12`;
      },
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      options: ["genesis", "morphed"],
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-core", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "rarity" }} />
      </div>

      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const GodFiltSection = () => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  const basefilt = {};
  const [filt, set_filt] = useState({});
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "god") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  return <></>;
};

const asset_types = _.chain([
  [
    "core",
    721,
    "Core",
    CoreFiltSection,
    "fbike-dnaracing",
    "0xce8090de88bba13d3cea5d73f8baf1f1c1a61b16",
    CoreTableHeads,
    CoreTableRow,
  ],
  [
    "skin",
    721,
    "Skin",
    SkinFiltSection,
    "fbikeskin-dnaracing",
    "0xcd0783c0e2b0a68a64ba7c5e0f99097945397cf3",
    SkinTableHeads,
    SkinTableRow,
  ],
  /*  [
    "god",
    721,
    "GOD",
    GodFiltSection,
    "godtoken-dnaracing",
    "0x28aabb51a634d186f79ffbf84fb70d119dbb05",
  ], */
  // ["corelootboxv2", 1155, "CoreLootV2"],
  // ["skinlootboxv1", 1155, "SkinLootV1"],
  // ["skinlootboxv101", 1155, "HallowBox"],
  // ["skingamelootbox", 1155, "RarityBox"],
])
  .map((e) => {
    let i = 0;
    return {
      asset_type: e[i++],
      erc: e[i++],
      txt: e[i++],
      AsFilt: e[i++],
      oscoll: e[i++],
      address: e[i++],
      TableHeads: e[i++],
      TableRow: e[i++],
    };
  })
  .keyBy("asset_type")
  .value();

export const MarketListingsPage = () => {
  const [asset_type, set_asset_type] = useState("core");
  const [valfilt, set_valfilt] = useState({});
  const [reqbody, set_reqbody] = useState({});
  const [c, set_c] = useState(0);

  const AsTy = asset_types[asset_type];

  useEffect(() => {
    const r = {
      asset_type,
      filt: valfilt,
      c,
    };
    console.log(r);
    set_reqbody(r);
  }, [c, asset_type]);

  const [qohs] = useQueries([q_os_search(reqbody, { enabled: c > 0 })]);
  const hs = useMemo(() => {
    if (!qissuccesss(qohs)) return [];
    let hs = getv(qohs, "data.result", []);
    return hs;
  }, [qohs.dataUpdatedAt]);

  useEffect(() => {
    // console.log("megavalfilt", valfilt);
  }, [jstr(valfilt)]);

  const mcon = {
    valfilt,
    set_valfilt,
    asset_type,
    set_asset_type,
    AsTy,
  };
  return (
    <MarkContext.Provider value={mcon}>
      <div className="page-wrapper">
        <div className="h-page">
          <div className="max-w-[98vw] w-[70rem] mx-auto">
            <div className="h-[5rem]"></div>
            {/* <p className="text-center text-acc0 resp-text-1">Market</p> */}

            <div className="fr-cc resp-gap-2 my-2 font-digi">
              {_.keys(asset_types).map((_asset_type) => {
                const active = asset_type == _asset_type;
                const asty = asset_types[_asset_type];
                return (
                  <Tag
                    onClick={() => set_asset_type(_asset_type)}
                    className={twMerge(
                      "resp-text-1 transition duration-300",
                      active ? "bg-acc0/70 text-white -skew-x-12" : "text-acc0"
                    )}
                  >
                    {asty.txt}
                  </Tag>
                );
              })}
            </div>

            <Card className={"w-reg w-[98%] resp-px-4"}>
              {!nils(AsTy) && AsTy.AsFilt && <AsTy.AsFilt />}
              <div className="fr-sc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => {
                    setTimeout(() => {
                      set_c(c + 1);
                    }, 500);
                  }}
                  className="bg-acc0/40 -skew-x-12 text-white"
                >
                  {"Search"}
                </Tag>
              </div>
            </Card>
            <div className="h-[1rem]">
              {qohs.isLoading ? (
                <div className="fr-cc resp-gap-2 text-acc0">
                  <Loader01c size="s" />
                  <span>Loading...</span>
                </div>
              ) : qiserr(qohs) ? (
                <div className="fr-cc resp-gap-2 text-red-400">
                  <span>Error!! {qiserr(qohs)}</span>
                </div>
              ) : qissuccesss(qohs) && _.isEmpty(hs) ? (
                <div className="fr-cc resp-gap-2 text-red-400">
                  <span>No Active Listings [try different filters]</span>
                </div>
              ) : qissuccesss(qohs) && !_.isEmpty(hs) ? (
                <Card className={"w-full"}>
                  <table className={tablecn.table}>
                    <thead>
                      <tr className="thintdrow text-acc0">
                        <td>
                          <span className="resp-text--1">Asset</span>
                        </td>
                        <td>
                          <span className="resp-text--1">TokeID</span>
                        </td>
                        {AsTy?.TableHeads && <AsTy.TableHeads />}
                        <td>
                          <span className="resp-text--1">Price</span>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {hs.map((h) => {
                        const asty = asset_types[h.asset_type];
                        const link = `https://opensea.io/assets/matic/${asty.address}/${h.token_id}`;
                        if (h.asset_type !== AsTy.asset_type) return <></>;
                        return (
                          <tr
                            className="thintdrow"
                            key={`${h.asset_type}-${h.token_id}`}
                          >
                            <td>
                              <Tag>
                                <span className="resp-text--1">{asty.txt}</span>
                              </Tag>
                            </td>
                            <td>
                              <Tag>
                                <span className="resp-text--1">
                                  #{h.token_id}
                                </span>
                              </Tag>
                            </td>
                            {AsTy?.TableRow && <AsTy.TableRow h={h} />}
                            <td>
                              <Link to={link} target="_blank">
                                <span className="resp-text--1 text-acc0 p-2 border border-acc0 bold rounded-md">
                                  {`${
                                    h.token == "MATIC"
                                      ? dec(h.price, 2)
                                      : dec(h.price, 4)
                                  } ${h.token}`}
                                </span>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              ) : (
                <></>
              )}
            </div>
            <div className="h-[5rem]"></div>
          </div>
        </div>
      </div>
    </MarkContext.Provider>
  );
};
