import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../../App";
import { Loader01c } from "../../components/anims";
import { Tag } from "../../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_fqual_actives,
  q_fqual_entrants,
  q_fqual_factionactives,
  q_fqual_info,
  q_fqual_leader,
} from "../../queries/queries";
import { ErrorBoundary } from "../../utils/errbou";
import { dec, getv, iso, iso_format, jstr, nils } from "../../utils/utils";
import {
  Leaderboard as FQualLeaderboard,
  InfoCard as FQualInfoCard,
} from "../FQual.js";

const FactionLeaderContext = createContext();
const useFactionLeaderContext = () => useContext(FactionLeaderContext);

const LeaderSelection = async () => {};

export const FactionsLeaderBoard = (props) => {
  const { psearch, upd_psearch } = useAppContext();
  const [idx, set_idx] = useState(undefined);
  const [qac] = useQueries([q_fqual_factionactives({}, {})]);
  const actives = useMemo(() => {
    let d = getv(qac, "data.result");
    if (!_.isEmpty(d)) {
      let selfid = getv(psearch, "selfaction");
      if (nils(selfid)) set_idx(0);
      else {
        let idx = _.findIndex(d, (e) => e.fid == selfid);
        if (idx != -1) set_idx(idx);
      }
    }
    return d;
  }, [qac.dataUpdatedAt, psearch?.selfaction]);

  const sel_f = getv(actives, idx);
  const fid = getv(sel_f, "fid");

  useMemo(() => {
    if (!nils(fid)) upd_psearch({ selfaction: fid });
  }, [jstr(actives), fid]);

  const [tab, set_tab] = useState("leaderboard");
  const enabled = !nils(fid);
  const [qi, ql, qe] = useQueries([
    q_fqual_info({ fid }, { enabled }),
    q_fqual_leader({ fid }, { enabled }),
    q_fqual_entrants(
      { fid },
      {
        enabled,
        staleTime: 20 * 1e3,
        refetchInterval: 20 * 1e3,
      },
    ),
  ]);

  const [my, set_my] = useState(false);

  const f = useMemo(() => {
    return qissuccesss(qi) ? getv(qi, "data.result") : null;
  }, [qi.dataUpdatedAt]);
  const fl = useMemo(() => {
    return qissuccesss(ql) ? getv(ql, "data.result") : {};
  }, [ql.dataUpdatedAt]);
  const fe = useMemo(() => {
    return qissuccesss(qe) ? getv(qe, "data.result") : {};
  }, [qe.dataUpdatedAt]);

  const tabs = useMemo(() => {
    if (!f) return ["leaderboard"];
    let is_signup = !nils(getv(f, "signup_rid"));
    let is_finale = !nils(getv(f, "finale_rid"));
    let tabs = [
      ...(is_signup ? ["entrants"] : []),
      "leaderboard",
      ...(is_finale ? ["finale"] : []),
    ];

    if (tab == undefined) {
      if (is_signup && f.status === "yettostart") set_tab("entrants");
      if (is_finale && f.status === "running") set_tab("finale");
      else set_tab("leaderboard");
    }

    return tabs;
  }, [jstr(f)]);

  const lcon = {
    fid,
    f,
    fl,
    fe,

    my,
    set_my,

    qi,
    ql,
  };

  const next = () => {
    let nidx = (idx + 1) % _.size(actives);
    set_idx(nidx);
  };

  const prev = () => {
    let nidx = idx - 1 < 0 ? _.size(actives) - 1 : idx - 1;
    set_idx(nidx);
  };

  return (
    <div>
      <FactionLeaderContext.Provider value={lcon}>
        {!_.isEmpty(sel_f) && (
          <div class="fr-sc resp-gap-2 max-w-full w-[50rem] mx-auto resp-text--1">
            <div onClick={prev} class="resp-p-1 resp-text-2 cursor-pointer">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>

            <ErrorBoundary>
              {qissuccesss(qi) ? (
                <FQualInfoCard useContext={useFactionLeaderContext} />
              ) : !_.isEmpty(sel_f) ? (
                <div class="flex-1 px-2 text-center font-digi resp-text-2 italic">
                  {getv(sel_f, "name")}
                </div>
              ) : null}
            </ErrorBoundary>

            <div onClick={next} class="resp-p-1 resp-text-2 cursor-pointer">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        )}

        {nils(fid) ? null : (
          <>
            <div className="fr-cc resp-gap-2 my-2">
              {tabs.map((_tab) => {
                let cn = "font-digi";
                let active = tab == _tab;
                return (
                  <Tag
                    onClick={() => {
                      set_tab(_tab);
                    }}
                    className={twMerge(
                      cn,
                      "transition duration-300",
                      active
                        ? "bg-r2lig/40 text-white -skew-x-12 "
                        : "bg-transparent text-white",
                    )}
                  >
                    <span className="resp-text-1">{_.capitalize(_tab)}</span>
                  </Tag>
                );
              })}
            </div>
            <hr className="mx-auto max-w-[95vw] w-[30rem] mb-[2rem] opacity-30" />
            <ErrorBoundary>
              {ql.isLoading ? (
                <Loader01c />
              ) : qissuccesss(ql) ? (
                <FQualLeaderboard useContext={useFactionLeaderContext} />
              ) : (
                <></>
              )}
            </ErrorBoundary>
          </>
        )}
      </FactionLeaderContext.Provider>
    </div>
  );
};
