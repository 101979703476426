export const colormap = {
  "alice-blue": "#F0F8FF",
  apricot: "#FDD5B1",
  aquamarine: "#7FFFD4",
  "arctic-blue": "#7DA5C4",
  "atomic-tangerine": "#FF9966",
  "baby-pink": "#F9E1FF",
  "banana-mania": "#FFFF99",
  "barbie-pink": "#FF99CC",
  beige: "#F5F5DC",
  bisque: "#FFE4C4",
  black: "#000000",
  "blizzard-blue": "#F8F8FF",
  "blue-bell": "#A2A2D0",
  "blue-gray": "#8B89D8",
  "blue-green": "#008080",
  "blue-violet": "#8A2BE2",
  blush: "#DE5D83",
  "brick-red": "#CB4154",
  "bright-green": "#00FF00",
  brown: "#A52A2A",
  "burnt-orange": "#CC5500",
  "cadet-blue": "#5F9EA0",
  "carnation-pink": "#F9429E",
  "carrot-orange": "#ED9121",
  cerulean: "#00BFFF",
  champagne: "#F7F5D0",
  chartreuse: "#7FFF00",
  "cherry-red": "#FF0030",
  coral: "#FF7F50",
  crimson: "#DC143C",
  dandelion: "#FFFF3C",
  "dark-cherry": "#461b27",
  "dark-sky": "#6663f2",
  "dazzle-violet": "#EE82EE",
  "denim-blue": "#1560BD",
  "desert-sand": "#EDC9AF",
  "emerald-green": "#50C878",
  "fire-fuchsia": "#FF00FF",
  "flamingo-pink": "#FC0FC0",
  "forest-green": "#228B22",
  gold: "#FFD700",
  goldenrod: "#DAA520",
  "granny-smith": "#A8E4A0",
  "grape-purple": "#800080",
  "grass-green": "#008000",
  "green-marble": "#438c68",
  "green-yellow": "#ADFF2F",
  honeydew: "#F0FFF0",
  "hot-pink": "#FF69B4",
  "indian-red": "#CD5C5C",
  ivory: "#FFFF99",
  jade: "#00A878",
  lavender: "#E6E6FA",
  "light-blue": "#ADD8E6",
  "light-coral": "#F08080",
  "light-cyan": "#E0FFFF",
  "light-fire": "#b56d21",
  "light-green": "#90EE90",
  "light-salmon": "#FFA07A",
  "lime-green": "#00FF00",
  maroon: "#800000",
  money: "#87A96B",
  "navy-blue": "#000080",
  "neon-blue": "#00E7FF",
  olive: "#808000",
  orchid: "#DA70D6",
  "pale-blue": "#AFEEEE",
  "pale-green": "#98FB98",
  "papaya-whip": "#FFEFD5",
  peach: "#FFDAB9",
  periwinkle: "#CCCCFF",
  plum: "#DDA0DD",
  "race-blue": "#9ACD32",
  "rage-red": "#FF0000",
  raspberry: "#FF007F",
  russet: "#80461B",
  "salmon-pink": "#FF91C0",
  scarlet: "#FF2400",
  "sea-green": "#2E8B57",
  "sim-metal": "#e3d8da",
  "sky-yellow": "#FFFF00",
  "slate-blue": "#6A5ACD",
  "soft-cyan": "#00FFFF",
  "soft-pink": "#FFC0CB",
  "speed-blue": "#6495ED",
  "spring-green": "#00FF7F",
  strawberry: "#FF4040",
  tan: "#D2B48C",
  turquoise: "#40E0D0",
  "very-orange": "#FF8C00",
  "vivid-tangerine": "#FFA07A",
  white: "#FFFFFF",
  "navy-blue": "#000080",
  "lime-green": "#00FF00",
  rose: "#FF007F",
  gold: "#FFD700",

  r2dark: "#1a223a",
  r2reg: "#37599a",
  r2lig: "#63aae7",
};
