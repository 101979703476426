// import React, { useEffect, useState } from "react";
// import { providers, ethers } from "ethers";
// import { SwapWidget } from "@uniswap/widgets";
// import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
// import detectEthereumProvider from "@metamask/detect-provider";
// import { contractAddress_list } from "../contracts/constants.js";
//
// const theme = {
//   primary: "#FFFFFF",
//   secondary: "#EEEEEE",
//   interactive: "#06DEC360",
//   container: "#21374A",
//   module: "#1C2025",
//   accent: "#06DEC360",
//   // outline: "#ABD6FE",
//   dialog: "#FFA500",
//   fontFamily: "Montserrat",
//   borderRadius: 5,
// };
//
// const SwapWidgetComp = () => {
//   const mmcon = useMetaMaskContext();
//   return (
//     <>
//       <div className="Uniswap">
//         {mmcon.mm && (
//           <>
//             <SwapWidget
//               defaultInputTokenAddress={contractAddress_list.weth}
//               defaultOutputTokenAddress={contractAddress_list.dez}
//               hideConnectionUI={true}
//               theme={theme}
//             />
//           </>
//         )}
//       </div>
//     </>
//   );
// };
//
function Swap_DEZ_WETH() {
  return (
    <div className="h-page">
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[4rem]"></div>
        <p className="text-center font-digi text-acc0 resp-text-1">
          Swap DEZ / WETH
        </p>
        <div className="w-max mx-auto">
          {/*
          <SwapWidgetComp />
          */}
        </div>
        <div className="fc-cc my-2">
          <p className="my-2">if widget errors please use this </p>
          <a
            className="text-acc0"
            href="https://app.uniswap.org/tokens/polygon/0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e"
            target="_blank"
            rel="noreferrer"
          >
            Uniswap Official Link
          </a>
        </div>
      </div>
    </div>
  );
}

export default Swap_DEZ_WETH;
