import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthWrapper.js";
import {
  q_balance,
  q_bikeinfo,
  q_mm_weth_balance,
  q_mm_dez_balance,
  q_token_prices,
  q_vaultbikes,
  q_vaultinfo,
  qissuccess,
  useStepQuery,
  q_vault_settings_get,
  q_vault_settings_set,
  qissuccesss,
  q_vaultbikes_cc,
  q_bikeinfo_cac,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { cdelay, getv, jparse, jstr, nils } from "../utils/utils.js";
import _ from "lodash";
import { set_state_ob } from "../components/input.js";
import { get_dez_balance } from "../contracts/contract_funcs.js";
import { useDebouncedEffect } from "../components/utilityComps.js";
import { useThirdWebLoginContext } from "../views/ThirdWebLogin.js";

export const AccountContext = createContext({});
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};

const to_sync_keys = ["leftsidebar", "vaulthconfs"];

export default function AccountWrapper(props) {
  const aucon = useAuthContext();
  const { aumode, auth, vault } = aucon;
  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const enabled = !nils(vault) && aucon.auth === true;

  const balanceenabled = useMemo(() => {
    let addr = active_account?.address;
    if (!nils(addr)) addr = addr.toLowerCase();
    if (aumode == "thirdweb" && addr == vault) {
      return true;
    } else {
      return enabled;
    }
  }, [aumode, auth, enabled]);

  const [qo_vaultinfo, qo_vaultbikes, qo_balance, qo_dezbalance] = useQueries([
    q_vaultinfo({ vault }, { enabled }),
    q_vaultbikes({ vault }, { enabled }),
    q_mm_weth_balance(vault, {
      staleTime: 30 * 1e3,
      refetchInterval: 30 * 1e3,
      enabled: balanceenabled,
    }),
    q_mm_dez_balance(vault, {
      staleTime: 30 * 1e3,
      refetchInterval: 30 * 1e3,
      enabled: balanceenabled,
    }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const hids = useMemo(() => {
    let vaultbikes = getv(qo_vaultbikes, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultbikes.dataUpdatedAt]);

  const qst_bdocs = useStepQuery({
    q_: q_bikeinfo_cac,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
  });
  const bikesob = useMemo(() => {
    let b = {};
    for (let q of qst_bdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.hid] = d;
    }
    return b;
  }, [jstr(_.map(qst_bdocs.qs, "dataUpdatedAt"))]);

  const acc_config_locpath = `acc_config-${vault}`;
  const [acc_config, set_acc_config] = useState(
    jparse(localStorage.getItem(acc_config_locpath), def_acc_config),
  );
  useEffect(() => {
    if (_.isEmpty(acc_config)) {
      let ob = jparse(localStorage.getItem(acc_config_locpath), def_acc_config);
      localStorage.setItem(acc_config_locpath, jstr(def_acc_config));
      set_acc_config(ob);
    } else {
      localStorage.setItem(acc_config_locpath, jstr(acc_config));
    }
  }, [acc_config]);

  const sync_config = useMemo(() => {
    let o = {};
    for (let k of to_sync_keys) {
      o[k] = acc_config[k];
    }
    return o;
  }, [jstr(acc_config)]);

  const [qo_sync_config] = useQueries([
    q_vault_settings_get(
      { vault },
      {
        staleTime: 2 * 60 * 1e3,
        refetchInterval: 2 * 60 * 1e3,
      },
    ),
  ]);
  useEffect(() => {
    let d = getv(qo_sync_config, "data.result");
    if (nils(d?.settings)) return;
    // console.log("sync config get", d);
    let o = {
      ...acc_config,
      ...d.settings,
    };
    set_acc_config(o);
    localStorage.setItem(acc_config_locpath, jstr(o));
  }, [qo_sync_config.dataUpdatedAt]);

  useDebouncedEffect(
    async () => {
      if (qo_sync_config.isLoading) return;
      // console.log("syncing config", sync_config);
      let resp = await q_vault_settings_set({
        vault,
        settings: sync_config,
      }).queryFn();
      // console.log("syncing config resp", resp);
    },
    [jstr(sync_config), qo_sync_config.dataUpdatedAt],
    8 * 1e3,
  );

  const s_acc_config = (p, v) => {
    set_state_ob(acc_config, set_acc_config, p, v);
  };
  const g_acc_config = (p, def) => {
    return getv(acc_config, p) ?? def;
  };

  const balance = useMemo(() => {
    if (!qissuccess(qo_balance)) return null;
    let bal = getv(qo_balance, "data.result.bal");
    return bal;
  }, [qo_balance.dataUpdatedAt]);

  const dezbalance = useMemo(() => {
    if (!qissuccess(qo_dezbalance)) return null;
    let bal = getv(qo_dezbalance, "data.result.bal");
    return bal;
  }, [qo_dezbalance.dataUpdatedAt]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tok_ethusd = useMemo(
    () => getv(qo_tokpri, "data.result.ethusd"),
    [qo_tokpri.dataUpdatedAt],
  );

  const bikeslist_hids_show_fn = ({ hids, mode, c, cb }) => {
    let hasccb = !nils(cb);
    let hs = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];
        if (nils(h)) return null;

        const hconf = getv(acc_config, `vaulthconfs.${hid}`) || {};

        let nothide = hconf?.hide !== true;
        let cbcmatch = [50, 60, 70, 80, 90].includes(c)
          ? true
          : hasccb
            ? true
            : true;

        let notcbchide = !hasccb
          ? true
          : _.isEmpty(hconf?.cbmap)
            ? true
            : hconf.cbmap.includes(cb);

        let show = 10;
        if (show == 10 && !nothide) show = 0;
        if (show == 10 && !notcbchide) show = 2;
        return [hid, show, cbcmatch, { nothide, cbcmatch, notcbchide }, hconf];
      })
      .compact()
      .filter((h) => {
        if (hasccb) return h[2];
        else return true;
      })
      .filter((h) => {
        if (mode == "filtered") return h[1] == 10;
        else return true;
      })
      .value();
    hs = _.map(hs, (ha) => {
      let hid = ha[0];
      let h = bikesob[hid];
      h.show = ha[1];
      return h;
    });
    return hs;
  };

  const refresh_bikeslist = async () => {
    try {
      let resp = await q_vaultbikes_cc({ vault }).queryFn();
      await cdelay(1.5 * 1e3);
      await qo_vaultbikes.refetch();
    } catch (err) {
      console.log("err:refresh_bikeslist", err);
    }
  };

  const accon = {
    vault,

    vdoc,
    hids,
    bikesob,

    qo_vaultinfo,
    qo_vaultbikes,
    qst_bdocs,

    acc_config,
    set_acc_config,
    s_acc_config,
    g_acc_config,

    qo_balance,
    balance,

    qo_dezbalance,
    dezbalance,

    tok_ethusd,

    bikeslist_hids_show_fn,
    refresh_bikeslist,
  };
  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}
