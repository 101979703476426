import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { twMerge } from "tailwind-merge";
import { fpost } from "../queries/fetch.js";
import { btbk } from "../queries/queries.js";
import { useAppContext, useNowContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { useInfiniteQuery } from "react-query";
import { useMask } from "@react-three/drei";
import { getv, jstr } from "../utils/utils.js";
import _ from "lodash";
import { Loader01c } from "../components/anims.js";
import { RaceCard, get_race_rtstatus } from "../utils/raceutils.js";
import { Tag } from "../components/utilityComps.js";
import { RaceCard_FullV } from "./RacePage.js";
import { RaceCardR2_full, RaceCardR2_mini } from "../utils/raceutils2.js";

export const FinContext = createContext();
export const useFinContext = () => useContext(FinContext);

export const FinishedRacesPage = () => {
  const appcon = useAppContext();
  const acccon = useAccountContext();
  const { now } = useNowContext();
  const { s_acc_config, g_acc_config } = acccon;

  const { vault, hids, auth } = acccon;
  const { psearch, set_psearch, tok_to_usd_val, use_to_tok_val } = appcon;

  const a = {};
  const [mybikes, set_mybikes] = useState(
    g_acc_config("finraces.mybikes", false),
  );
  useEffect(() => {
    s_acc_config("finraces.mybikes", mybikes);
  }, [mybikes]);

  const vhids = acccon.hids || null;

  const fetchpage = ({ page = 1 }) => {
    if (page == -1) return null;

    return fpost(`${btbk}/fbike/races/finished`, {
      page,
      ...(mybikes === true ? { vhids } : {}),
    });
  };

  const q = useInfiniteQuery({
    queryKey: ["finishedraces", mybikes ? jstr({ vhids }) : ""],
    queryFn: (pars) => {
      console.log("queryFn", pars.pageParam);
      return fetchpage(pars.pageParam || {});
    },
    getNextPageParam: (lastPage, allPages) => {
      let page = getv(lastPage, "result.next_page");
      return { page };
    },
    refetchInterval: 2 * 60 * 1e3,
  });
  // useEffect(() => {
  //   console.log(q);
  // }, [q.dataUpdatedAt]);
  const races = useMemo(() => {
    let rs = _.chain(q.data?.pages)
      .filter((e) => getv(e, "status") == "success")
      .map((e) => getv(e, "result.races"))
      .flatten()
      .uniqBy("rid")
      .map((r) => {
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
        return r;
      })
      .value();
    // console.log("rs", rs);
    return rs;
  }, [q.dataUpdatedAt]);

  // const q = useInfinit

  const [selrace, set_selrace] = useState(null);
  const fcon = {
    selrace,
    set_selrace,
  };

  // console.log({ selrace });

  return (
    <FinContext.Provider value={fcon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[3rem]"></div>
          <p className="text-center text-acc0 resp-text-1 font-digi">
            Finished Races
          </p>
          <div className="h-[1rem]"></div>
          <div className="fr-sc">
            <div
              onClick={() => set_mybikes(!mybikes)}
              className={twMerge(
                "fr-sc resp-gap-2 cursor-pointer resp-text-1 select-none",
                mybikes ? "text-acc0" : "",
              )}
            >
              <FontAwesomeIcon icon={mybikes ? faToggleOn : faToggleOff} />
              <span>MyBikes</span>
            </div>
            <div className="flex-1"></div>
            {q.isFetching && <Loader01c size="s" />}
            {q.isFetchingNextPage && <Loader01c size="s" />}
          </div>
          <div className="h-[1rem]"></div>
          {(races || []).map((race) => {
            let [rtstatus] = get_race_rtstatus(race, now);

            if (race.rid == selrace) return <RaceCardR2_full {...{ race }} />;
            else
              return (
                <div onClick={() => {}} class="w-full cursor-pointer">
                  <RaceCardR2_mini {...{ race, selrace, set_selrace }} />
                </div>
              );
          })}
          <div className="fr-cc">
            {q.isFetchingNextPage && <Loader01c size="s" />}
            <IfInView runViewFn={!q.isFetching} onView={q.fetchNextPage} />
          </div>
          <div className="h-[5rem]"></div>
        </div>
      </div>
    </FinContext.Provider>
  );
};

export const IfInView = ({ onView, runViewFn }) => {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log("vis", entry.isIntersecting);
        // When the observed element enters or exits the viewport
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // Margin around the root
        threshold: 0.5, // 0-1: percentage of the target element that must be visible to trigger the callback
      },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && runViewFn !== false) onView();
  }, [isVisible, runViewFn]);

  return (
    <div
      ref={targetRef}
      className={twMerge(
        "w-[1rem] h-[1rem]",
        // isVisible ? "bg-acc0" : "bg-red-300"
      )}
    ></div>
  );
};
