import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../constants.js";
import { get_gasinfo } from "../contract_funcs.js";

class RaceStakeV03_DEZ_Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async enterRace(raceId, hid, ext_info = {}) {
    let gasinfo = await get_gasinfo();
    let info = { ...gasinfo, ...ext_info };
    console.log("gas", info);
    const tx = await this.contract.userEnterRace(raceId, hid, info);
    // const resp = await tx.wait();
    return tx;
  }
}

const get_RaceStakeV03_DEZ_Contract = async () => {
  const contractAddress = contractAddress_list.racestakev03dez;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new RaceStakeV03_DEZ_Contract({
    contractAddress,
    provider,
    signer,
  });
  return runner;
};

const RaceStakeV03_DEZ = {
  class: RaceStakeV03_DEZ_Contract,
  get_contract: get_RaceStakeV03_DEZ_Contract,
};

export default RaceStakeV03_DEZ;
