import { getv, iso, jparse, jstr, nano, nils, tofeth } from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abi.json";

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  // function addAdmin(address _admin) public onlyContractOwner
  async addAdmin(address) {
    let resp = await this.contract.addAdmin(address);
    resp = await resp.wait();
    return resp;
  }

  // function removeAdmin(address _admin) public onlyContractOwner
  async removeAdmin(address) {
    let resp = await this.contract.removeAdmin(address);
    resp = await resp.wait();
    return resp;
  }

  // function set_funds_wallet(address _funds_wallet) public onlyContractOwner
  async set_funds_wallet(address) {
    let resp = await this.contract.set_funds_wallet(address);
    resp = await resp.wait();
    return resp;
  }

  // function set_payout_token (string memory _tok_sym, address _tok_address) public onlyContractOwner {
  async set_payout_token(tokenSymbol, tokenAddress) {
    let resp = await this.contract.set_payout_token(tokenSymbol, tokenAddress);
    resp = await resp.wait();
    return resp;
  }

  // function remove_payout_token(string memory _sym) public onlyContractOwner {
  async remove_payout_token(tokenSymbol) {
    let resp = await this.contract.remove_payout_token(tokenSymbol);
    resp = await resp.wait();
    return resp;
  }

  // function set_fbike_address (address _fbike_address) public onlyContractOwner {
  async set_fbike_address(address) {
    let resp = await this.contract.set_fbike_address(address);
    resp = await resp.wait();
    return resp;
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event Payout(address indexed _to, uint _amt, string _sym, string _txnkey, string _args);

      // event BikeRewardAdded(uint indexed _hid, uint _amt, string _sym, string _txnkey, string _args);
      // event BikeRewardClaimed(uint indexed _hid, uint _amt, string _sym, address _claimer);

      // event VaultRewardAdded(address indexed _vault, uint _amt, string _sym, string _txnkey, string _args);
      // event VaultRewardClaimed(address indexed _vault, uint _amt, string _sym);

      // event Staked(address indexed _staker, uint _amt, string _sym);
      // event Unstaked(address indexed _staker, uint _amt, string _sym);

      if (name == "Payout") {
        let a = par.args;
        o.type = "payout";
        o.args = {
          vault: a[0].toLowerCase(),
          amt: tofeth(a[1]),
          token: a[2],
          txnkey: a[3],
          args: jparse(a[4]),
        };
      } else if (name == "BikeRewardAdded") {
        let a = par.args;
        o.type = "bike_reward_added";
        o.args = {
          hid: Number(a[0]),
          amt: tofeth(a[1]),
          token: a[2],
          txnkey: a[3],
          args: jparse(a[4]),
        };
      } else if (name == "BikeRewardClaimed") {
        let a = par.args;
        o.type = "bike_reward_claimed";
        o.args = {
          hid: Number(a[0]),
          amt: tofeth(a[1]),
          token: a[2],
          vault: a[3].toLowerCase(),
        };
      } else if (name == "VaultRewardAdded") {
        let a = par.args;
        o.type = "vault_reward_added";
        o.args = {
          vault: a[0].toLowerCase(),
          amt: tofeth(a[1]),
          token: a[2],
          txnkey: a[3],
          args: jparse(a[4]),
        };
      } else if (name == "VaultRewardClaimed") {
        let a = par.args;
        o.type = "vault_reward_claimed";
        o.args = {
          vault: a[0].toLowerCase(),
          amt: tofeth(a[1]),
          token: a[2],
        };
      } else if (name == "Staked") {
        let a = par.args;
        o.type = "staked";
        o.args = {
          vault: a[0].toLowerCase(),
          amt: tofeth(a[1]),
          token: a[2],
        };
      } else if (name == "Unstaked") {
        let a = par.args;
        o.type = "unstaked";
        o.args = {
          vault: a[0].toLowerCase(),
          amt: tofeth(a[1]),
          token: a[2],
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  // function txnkey_exists(string memory _txnkey) public view returns (bool)
  async txnkey_exists(txnkey) {
    return this.contract.txnkey_exists(txnkey);
  }

  // function a_transfer_funds(address _to, uint _amt, string memory _sym) public onlyAdmin
  async a_transfer_funds(to, amt, sym) {
    let resp = await this.contract.a_transfer_funds(to, amt, sym);

    resp = await resp.wait();
    return resp;
  }

  // function send_payout(address _to, uint _amt, string memory _sym, string memory _txnkey, string memory _args) public onlyAdmin()
  async send_payout(to, amt, sym, txnkey, args) {
    let resp = await this.contract.send_payout(to, amt, sym, txnkey, args);

    resp = await resp.wait();
    return resp;
  }

  // function bulk_send_payout(address[] memory _to, uint[] memory _amt, string[] memory _sym, string[] memory _txnkey, string[] memory _args) public onlyAdmin
  async bulk_send_payout(to, amt, sym, txnkey, args) {
    let resp = await this.contract.bulk_send_payout(to, amt, sym, txnkey, args);

    resp = await resp.wait();
    return resp;
  }

  // function stake(uint _amt, string memory _sym) public
  async stake(amt, sym) {
    let resp = await this.contract.stake(amt, sym);

    resp = await resp.wait();
    return resp;
  }

  // function prt_unstake (address _wallet, uint _amt, string memory _sym) private
  // Note: This function is private and should not have a direct JavaScript counterpart for external calls.

  // function unstake (uint _amt, string memory _sym) public
  async unstake(amt, sym) {
    let resp = await this.contract.unstake(amt, sym);

    resp = await resp.wait();
    return resp;
  }

  // function a_unstake_dez (address _wallet, uint _amt, string memory _sym) public onlyAdmin
  async a_unstake_dez(wallet, amt, sym) {
    let resp = await this.contract.a_unstake_dez(wallet, amt, sym);

    resp = await resp.wait();
    return resp;
  }

  // mapping(string=>uint) public total_staked;
  async get_total_staked(sym) {
    return this.contract.total_staked(sym);
  }

  // function get_staked(address _addr, string memory _sym) public view returns (uint)
  async get_staked(addr, sym) {
    return this.contract.get_staked(addr, sym);
  }

  // function bulk_get_staked(address[] memory _addr, string memory _sym) public view returns (uint[] memory)
  async bulk_get_staked(addr, sym) {
    return this.contract.bulk_get_staked(addr, sym);
  }

  // function get_fbike_owner (uint _hid) public view returns (address)
  async get_fbike_owner(hid) {
    return this.contract.get_fbike_owner(hid);
  }

  // mapping(string=>uint) public total_bike_reward;
  async get_total_bike_reward(sym) {
    return this.contract.total_bike_reward(sym);
  }

  // function claim_bike_reward(uint _hid, uint _amt, string memory _sym) public
  async claim_bike_reward(hid, amt, sym) {
    let resp = await this.contract.claim_bike_reward(hid, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function a_claim_bike_reward(uint _hid, uint _amt, string memory _sym) public onlyAdmin {
  async a_claim_bike_reward(hid, amt, sym) {
    let resp = await this.contract.a_claim_bike_reward(hid, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function set_bike_reward(uint _hid, uint _amt, string memory _sym) public onlyAdmin {
  async set_bike_reward(hid, amt, sym) {
    let resp = await this.contract.set_bike_reward(hid, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function bulk_set_bike_rewards(uint[] memory _hid, uint[] memory _amt, string[] memory _sym) public onlyAdmin {
  async bulk_set_bike_rewards(hid, amt, sym) {
    let resp = await this.contract.bulk_set_bike_rewards(hid, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function get_bike_reward(uint _hid, string memory _sym) public view returns (uint) {
  async get_bike_reward(hid, sym) {
    return this.contract.get_bike_reward(hid, sym);
  }

  // function bulk_get_rewards(uint[] memory _hid, string memory _sym) public view returns (uint[] memory) {
  async bulk_get_rewards(hid, sym) {
    return this.contract.bulk_get_rewards(hid, sym);
  }

  // function set_vault_reward(address _vault, uint _amt, string memory _sym) public onlyAdmin {
  async set_vault_reward(vault, amt, sym) {
    let resp = await this.contract.set_vault_reward(vault, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function bulk_set_vault_rewards(address[] memory _vault, uint[] memory _amt, string[] memory _sym) public onlyAdmin {
  async bulk_set_vault_rewards(vault, amt, sym) {
    let resp = await this.contract.bulk_set_vault_rewards(vault, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function get_vault_reward(address _vault, string memory _sym) public view returns (uint) {
  async get_vault_reward(vault, sym) {
    return this.contract.get_vault_reward(vault, sym);
  }

  // function bulk_get_vault_rewards(address[] memory _vault, string memory _sym) public view returns (uint[] memory) {
  async bulk_get_vault_rewards(vault, sym) {
    return this.contract.bulk_get_vault_rewards(vault, sym);
  }

  // function claim_vault_reward(uint _amt, string memory _sym) public
  async claim_vault_reward(amt, sym) {
    let resp = await this.contract.claim_vault_reward(amt, sym);
    resp = await resp.wait();
    return resp;
  }
  // function a_claim_vault_reward(address _wallet, uint _amt, string memory _sym) public onlyAdmin {
  async a_claim_vault_reward(wallet, amt, sym) {
    let resp = await this.contract.a_claim_vault_reward(wallet, amt, sym);
    resp = await resp.wait();
    return resp;
  }

  // function destroy() public onlyContractOwner
  async destroy() {
    let resp = await this.contract.destroy();
    resp = await resp.wait();
    return resp;
  }
}

const k = "stakingv1";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({
    contractAddress: get_contract_address(),
    provider,
    signer,
    ...ext,
  });
  return runner;
};

export const Staking_v1 = {
  k,
  get_contract,
  get_contract_address,
};
