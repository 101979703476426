import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useQueries } from "react-query";
import { twMerge } from "tailwind-merge";
import { Card } from "../components/utilityComps";
import { qissuccesss, q_sheet_pages_season1 } from "../queries/queries";
import { getv } from "../utils/utils";
import _ from "lodash";

export const Season1Page = () => {
  const [qo_s0] = useQueries([q_sheet_pages_season1()]);
  const s = useMemo(() => getv(qo_s0, "data.result"), [qo_s0.dataUpdatedAt]);

  return (
    <div className="h-page">
      <div class="w-[80rem] mx-auto max-w-[95vw]">
        <div class="h-[3rem]"></div>
        <p class="text-center resp-text-2 font-digi text-acc0">Season-1</p>
        {qissuccesss(qo_s0) ? (
          <div className="fc-cc resp-gap-2">
            <Card className={twMerge("w-full overflow-auto")}>
              <table className={twMerge("w-full resp-text--2")}>
                <tbody>
                  {!_.isEmpty(getv(s[0])) &&
                    s[0].map((row, ri) => {
                      return (
                        <tr
                          className={twMerge(
                            "thintdrowp4",
                            ri == 0 ? "font-digi text-acc0 italic" : "",
                          )}
                        >
                          {row.map((cell) => {
                            return <td className={twMerge("")}>{cell}</td>;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div class="my-2"></div>
            </Card>
            <div class="grid xs:grid-cols-1 md:grid-cols-2 resp-gap-2">
              <Card className={twMerge("w-full overflow-auto")}>
                <table className={twMerge("w-max resp-text--2")}>
                  <tbody>
                    {!_.isEmpty(getv(s[1])) &&
                      s[1].map((row, ri) => {
                        return (
                          <tr
                            className={twMerge(
                              "thintdrowp4",
                              ri == 0 ? "font-digi text-acc0 italic" : "",
                            )}
                          >
                            {row.map((cell) => {
                              return <td className={twMerge("")}>{cell}</td>;
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Card>

              <Card className={twMerge("w-full overflow-auto")}>
                {!_.isEmpty(getv(s[2])) && (
                  <div className="w-max mx-auto ">
                    <p class="text-center font-digi text-acc0 resp-text--1">
                      {getv(s, "2.0")}
                    </p>
                    <ul>
                      {getv(s, "2")
                        .slice(1)
                        .map((li) => {
                          return (
                            <li class="resp-text--1 list-disc">
                              <p>{li}</p>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </Card>
            </div>

            <hr class="w-[40rem] mx-auto my-4" />
            <p class="text-acc0 font-digi resp-text-1">{getv(s, "3.0")}</p>
            {!_.isEmpty(getv(s, "3")) &&
              getv(s, "3")
                .slice(1)
                .map((li) => (
                  <p class="text-white font-mon resp-text--2">{li}</p>
                ))}

            <Card className={twMerge("w-full overflow-auto")}>
              <table className={twMerge("w-full resp-text--2")}>
                <tbody>
                  {!_.isEmpty(getv(s[4])) &&
                    s[4].map((row, ri) => {
                      return (
                        <tr
                          className={twMerge(
                            "thintdrowp4",
                            ri == 0
                              ? "font-digi text-acc0 italic"
                              : ri == 1
                                ? " text-acc0 italic"
                                : "",
                          )}
                        >
                          {row.map((cell) => {
                            return <td className={twMerge("")}>{cell}</td>;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Card>
            <Card className={twMerge("w-full overflow-auto")}>
              <table className={twMerge("w-full resp-text--2")}>
                <tbody>
                  {!_.isEmpty(getv(s[5])) &&
                    s[5].map((row, ri) => {
                      return (
                        <tr
                          className={twMerge(
                            "thintdrowp4",
                            ri == 0
                              ? "font-digi text-acc0 italic"
                              : ri == 1
                                ? " text-acc0 italic"
                                : "",
                          )}
                        >
                          {row.map((cell) => {
                            return <td className={twMerge("")}>{cell}</td>;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Card>
          </div>
        ) : null}

        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
