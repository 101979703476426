import _ from "lodash";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useQueries, useQuery } from "react-query";
import { useParams } from "react-router";
import { useAppContext } from "../../App";
import { Card } from "../../components/utilityComps";
import {
  qissuccesss,
  q_factions_agents_info,
  q_factions_agents_owns,
  q_factions_missions_open_agent,
  useStepQuery,
} from "../../queries/queries";
import { GlowBtnGroup, svg_map } from "../../utils/raceutils2";
import { getv, jstr, nils } from "../../utils/utils";
import { useAuthContext } from "../../wrappers/AuthWrapper";
import { FactionMissions } from "./FactionMissions";
import { FactionAgents } from "./FactionsAgents";
import { FactionsBingo } from "./FactionsBingo";
import { FactionsLeaderBoard } from "./FactionsLeaderBoard";
import { FactionsBlitz } from "./FationsBlitz";

const FactionContext = createContext({});
export const useFactionContext = () => useContext(FactionContext);

export const FactionsWrapper = (props) => {
  // const { vault, auth } = useAuthContext();
  const { auth } = useAuthContext();
  const params = useParams();
  const vault = useMemo(() => {
    // console.log("useMemo:vault", props.vault, params.vault);
    if (!nils(props.vault)) return props.vault.toLowerCase();
    else return params.vault.toLowerCase();
  }, [params, props.vault]);

  const [qo_ags] = useQueries([
    q_factions_agents_owns(
      { vault },
      { enabled: !nils(vault), staleTime: 30 * 1e3, refetchInterval: 30 * 1e3 },
    ),
  ]);
  const agids = useMemo(() => {
    let ar = getv(qo_ags, "data.result") || [];
    return _.map(ar, "agid");
  }, [qo_ags.dataUpdatedAt]);

  const qsoags = useStepQuery({
    q_: q_factions_agents_info,
    par_ar: agids.map((agid) => [{ agid }]),
    lim: 3,
  });
  const agentsob = useMemo(() => {
    // console.log("agentsob", "init");
    let b = {};
    for (let q of qsoags.qs) {
      let d = getv(q, "data.result") || {};
      // console.log("ag", q.data);
      if (nils(d)) continue;
      d.q = q;
      b[d.agid] = d;
    }
    return b;
    // console.log("agentsob", b);
  }, [jstr(_.map(qsoags.qs, "dataUpdatedAt"))]);

  const qsagmis = useStepQuery({
    q_: q_factions_missions_open_agent,
    par_ar: agids.map((agid) => [
      { agid },
      {
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ]),
    lim: 3,
  });
  const missionsob = useMemo(() => {
    let b = {};
    let i = -1;
    for (let q of qsagmis.qs) {
      ++i;
      let d = getv(q, "data.result") || {};
      if (nils(d)) continue;
      let agid = agids[i];
      b[agid] = {
        agid,
        q,
        missions: d,
      };
    }
    // console.log("missionsob", "init", b);
    return b;
  }, [jstr(_.map(qsagmis.qs, "dataUpdatedAt"))]);

  const fcon = {
    vault,
    auth,
    agids,
    agentsob,
    qsagmis,
    missionsob,
  };

  // useEffect(() => {
  //   console.log("useEffect:faction_wrapper", fcon);
  // }, [jstr(fcon)]);

  return (
    <FactionContext.Provider value={fcon}>
      {props.children}
    </FactionContext.Provider>
  );
};

const Factions = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [tab, set_tab] = useState(psearch?.tab ?? "missions");
  useEffect(() => {
    upd_psearch({ tab });
  }, [tab]);

  return (
    <FactionsWrapper>
      <div class="h-page">
        <div class="mx-auto w-[80rem] max-w-[95vw]">
          <div class="">
            <Card className="w-full card-grad-border card-dark-bg">
              <p class="resp-text-2 text-center font-digi my-2">Factions</p>

              <GlowBtnGroup
                {...{
                  options: [
                    "missions",
                    "agents",
                    "leaderboard",
                    "bingo",
                    "blitz",
                  ],
                  selected: tab,
                  opmap: _.chain([
                    ["agents", "Agents", "bike"],
                    ["missions", "Missions", "stats"],
                    ["leaderboard", "Leaderboards", "trophies"],
                    ["bingo", "Bingo", "trophies"],
                    ["blitz", "Blitz", "trophies"],
                  ])
                    .map(([c, text, svg_k]) => {
                      return [c, { text, svg: svg_map[svg_k] }];
                    })
                    .fromPairs()
                    .value(),
                  setter: set_tab,
                }}
              />
              <div class="h-[1rem]"></div>
              {tab == "agents" && <FactionAgents />}
              {tab == "missions" && <FactionMissions />}
              {tab == "bingo" && <FactionsBingo />}
              {tab == "blitz" && <FactionsBlitz />}
              {tab == "leaderboard" && <FactionsLeaderBoard />}
            </Card>
          </div>
        </div>
      </div>
    </FactionsWrapper>
  );
};
export default Factions;
