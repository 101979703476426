import { getv, iso, jparse, jstr, nano, nils, tofeth } from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abi.json";

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  // function setTaxPercent(uint percent) external onlyOwner {
  async setTaxPercent(percent) {
    let resp = await this.contract.setTaxPercent(percent);
    resp = await resp.wait();
    return resp;
  }

  // function getBuyInfo(uint numTokens) public view returns (bool errorExceedMaxSupply, uint avgPrice, uint totalCost) {
  async getBuyInfo(numTokens) {
    return this.contract.getBuyInfo(numTokens);
  }

  // function getBuyByEthInfo(uint eth) public view returns (bool errorExceedMaxSupply, uint avgPrice, uint totalTokens) {
  async getBuyByEthInfo(eth) {
    return this.contract.getBuyByEthInfo(eth);
  }

  // function getSellInfo(uint numTokens) public view returns (bool errorExceedSupply, uint avgPrice, uint totalEth) {
  async getSellInfo(numTokens) {
    return this.contract.getSellInfo(numTokens);
  }

  // function getCurrentPrice() external view returns (uint) {
  async getCurrentPrice() {
    return this.contract.getCurrentPrice();
  }

  // function buy(uint weth, uint minTokenReceive) external {
  async buy(weth, minTokenReceive, info = {}) {
    info.gasLimit = 1000000;
    let resp = await this.contract.buy(weth, minTokenReceive);
    // resp = await resp.wait();
    return resp;
  }

  // function buyFor(address to, uint weth, uint minTokenReceive) external returns (uint) {
  async buyFor(to, weth, minTokenReceive, info = {}) {
    info.gasLimit = 1000000;
    let resp = await this.contract.buyFor(to, weth, minTokenReceive);
    // resp = await resp.wait();
    return resp;
  }

  // function sell(uint amount, uint minWethReceiveAfterTax) external nonReentrant {
  async sell(amount, minWethReceiveAfterTax, info = {}) {
    info.gasLimit = 1000000;
    let resp = await this.contract.sell(amount, minWethReceiveAfterTax);
    // resp = await resp.wait();
    return resp;
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event Buy(address indexed user, uint amountEth, uint totalTokens);
      // event Sell(address indexed user, uint amountEth, uint burnedAmount);
      //

      if (name == "Buy") {
        let a = par.args;
        o.type = "buy";
        o.args = {
          user: a[0].toLowerCase(),
          amountEth: tofeth(a[1]),
          totalTokens: tofeth(a[2]),
        };
      } else if (name == "Sell") {
        let a = par.args;
        o.type = "sell";
        o.args = {
          user: a[0].toLowerCase(),
          amountEth: tofeth(a[1]),
          burnedAmount: tofeth(a[2]),
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  // function destroy() public onlyContractOwner
  async destroy() {
    let resp = await this.contract.destroy();
    resp = await resp.wait();
    return resp;
  }
}

const k = "lc";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({
    contractAddress: get_contract_address(),
    provider,
    signer,
    ...ext,
  });
  return runner;
};

export const LCToken = {
  k,
  get_contract,
  get_contract_address,
};
