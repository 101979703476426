import React, { createContext, useContext, useEffect, useMemo } from "react";
import {
  q_satellite,
  q_satellites,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { Card, Tag } from "../components/utilityComps.js";
import { copy_clip, getv, jstr, nils } from "../utils/utils.js";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { ElementTag, FNoTag, TypeTag } from "../components/ShortComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const SatelliteListRowWrapper = ({ mega_rid }) => {
  const [qo_satellite] = useQueries([q_satellite({ mega_rid })]);
  const satellite = useMemo(
    () => getv(qo_satellite, "data.result"),
    [qo_satellite.dataUpdatedAt]
  );
  if (qissuccesss(qo_satellite) && !_.isEmpty(satellite))
    return <SatelliteListRow satellite={satellite} expanded={false} />;

  return (
    <Card className="w-full my-1 rounded-md bg-reg h-[3rem]">
      {qo_satellite.isLoading ? (
        <Loader01c size="s" />
      ) : qiserr(qo_satellite) ? (
        <p className="text-center text-red-300">
          Error[mega_rid={mega_rid}] {qiserr(qo_satellite)}
        </p>
      ) : (
        <></>
      )}
    </Card>
  );
};

const SatelliteListRow = ({ satellite: s, expanded = false }) => {
  // useEffect(() => {
  //   console.log(s);
  // }, []);

  const rows = useMemo(() => {
    if (!expanded) return null;
    return s.rids.map((rid) => {
      let r = s.racesmap[rid];
      let hid = s.winners[rid];
      let h = s.bikesmap[hid];
      return {
        rid,
        race: r,
        bike: h,
      };
    });
  }, [jstr(s), expanded]);

  const copy_bikeids = () => {
    let ar = _.chain(s.winners).values().uniq().value();
    let str = ar.join("\n");
    copy_clip(str);
  };

  return (
    <Link to={`/satellite/${s.mega_rid}`}>
      <Card className="w-full my-1 rounded-md bg-reg">
        <div className="fr-sc resp-gap-2 resp-text--1">
          <Tag className="border border-acc0 text-mon">{s.mega_rid}</Tag>
          <span className="resp-text-1 font-digi">Satellite #{s.presetid}</span>
          <div className="flex-1"></div>
          <div className="fc-ss">
            <div className="fr-sc resp-gap-1 text-acc0">
              <span>{s.rids.length}</span>
              <span>/</span>
              <span>{s.max_rids}</span>
              <span>{"Races"}</span>
            </div>
            {s.ended && <span className="text-red-300">ENDED</span>}
          </div>
        </div>
      </Card>
      {expanded && (
        <div>
          <div className="fr-sc">
            <div className="flex-1"></div>
            <Tag
              onClick={() => {
                copy_bikeids();
              }}
              className="border border-acc0 text-acc0"
            >
              Copy BikeIds
            </Tag>
          </div>
          {rows.map((row) => {
            const { rid, race: r, bike: h } = row;
            return (
              <Card className="resp-my-1 bg-transparent w-full" key={rid}>
                <Link to={`/race/${rid}`}>
                  <div className="fr-sc resp-gap-2">
                    <span>Race#{r.rid}</span>
                    <span className="font-digi resp-text-1">{r.race_name}</span>
                    <div className="flex-1"></div>
                    <span className="text-yellow-300">
                      {_.upperCase(r.status)}
                    </span>
                  </div>
                </Link>
                {nils(h) ? (
                  <div className="fr-sc resp-gap-2">
                    <span className="text-yellow-300">Awaiting Winner</span>
                  </div>
                ) : (
                  <Link to={`/bike/${h.hid}`}>
                    <div className="fr-sc resp-gap-2 text-green-300">
                      <span className="font-digi">{`#1 ==>>`}</span>
                      <span className="font-digi resp-text-0">{h.name}</span>
                      <span className="font-digi resp-text--1">#{h.hid}</span>
                      <div className="flex-1"></div>

                      <FNoTag className="resp-text--3 text-white" fno={h.fno} />
                      <TypeTag
                        className="resp-text--3 text-white"
                        type={h.type}
                      />
                      <ElementTag
                        className="resp-text--3 text-white"
                        element={h.element}
                      />
                    </div>
                  </Link>
                )}
              </Card>
            );
          })}
        </div>
      )}
    </Link>
  );
};

export const Satellites = () => {
  const [qo_satellites] = useQueries([q_satellites()]);
  const satellites = useMemo(
    () => getv(qo_satellites, "data.result"),
    [qo_satellites.dataUpdatedAt]
  );

  return (
    <>
      <Helmet>
        <title>{"Satellites | FBike DNA"}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          <p className="text-acc0 resp-text-2 text-center my-2">Satellites</p>
          <div className="h-[2rem]"></div>
          {qo_satellites.isLoading ? (
            <Loader01c />
          ) : qiserr(qo_satellites) ? (
            <p className="text-center text-red-300">
              Error {qiserr(qo_satellites)}
            </p>
          ) : qissuccesss(qo_satellites) && _.isEmpty(satellites) ? (
            <p className="text-center text-yellow-300">No Recent Satellites</p>
          ) : qissuccesss(qo_satellites) && !_.isEmpty(satellites) ? (
            <div>
              {satellites.map((s) => {
                return <SatelliteListRowWrapper key={s} mega_rid={s} />;
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const SatelliteContext = createContext({});
export const useSatelliteContext = () => useContext(SatelliteContext);
export const SatellitePage = () => {
  const { mega_rid } = useParams();
  const [qo_satellite] = useQueries([q_satellite({ mega_rid })]);
  const satellite = useMemo(
    () => getv(qo_satellite, "data.result"),
    [qo_satellite.dataUpdatedAt]
  );

  const pagetitle = useMemo(() => {
    if (satellite?.presetid)
      return `Satellite #${satellite.presetid} | FBike DNA`;
    else return `Satellite #${"..."} | FBike DNA`;
  }, [mega_rid, satellite?.presetid]);

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          {qo_satellite.isLoading ? (
            <Loader01c />
          ) : qiserr(qo_satellite) ? (
            <p className="text-center text-red-300">
              Error {qiserr(qo_satellite)}
            </p>
          ) : qissuccesss(qo_satellite) && _.isEmpty(satellite) ? (
            <p className="text-center text-yellow-300">Not Found</p>
          ) : qissuccesss(qo_satellite) && !_.isEmpty(satellite) ? (
            <div>
              <div className="fr-sc">
                <Link to="/satellites">
                  <Tag className="border border-acc0 text-acc0 text-mon fr-sc resp-gap-2">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>All Satellites</span>
                  </Tag>
                </Link>
              </div>
              <div className="h-[1rem]"></div>
              <SatelliteListRow satellite={satellite} expanded={true} />
              <div className="h-[5rem]"></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
