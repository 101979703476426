import React, { useEffect, useMemo, useState } from "react";
import {
  RaceCard,
  get_race_from_mini,
  get_race_rtstatus,
} from "../utils/raceutils.js";
import {
  q_nexttorun,
  q_token_prices,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { filt_ar_using_filters } from "../utils/filt.js";
import { Loader01c } from "../components/anims.js";
import { getv, iso, jstr, nano } from "../utils/utils.js";
import _ from "lodash";
import { useQueries } from "react-query";
import { useAppContext, useNowContext } from "../App.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { twMerge } from "tailwind-merge";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RaceCardR2_full, RaceCardR2_mini } from "../utils/raceutils2.js";

const RacesNextContext = React.createContext();
export const useRacesNextContext = () => React.useContext(RacesNextContext);

const RacesList = () => {
  const { filtraces, selrace, set_selrace } = useRacesNextContext();
  const { now } = useNowContext();
  return (
    <>
      {(filtraces || []).map((race) => {
        if (race.rid == selrace) return <RaceCardR2_full {...{ race }} />;
        else
          return (
            <div
              onClick={() => {
                // set_selrace(race.rid);
              }}
              class="w-full cursor-pointer"
            >
              <RaceCardR2_mini {...{ race, selrace, set_selrace }} />
            </div>
          );
      })}
    </>
  );
};

const RacesSection = () => {
  const rcon = useRacesNextContext();
  const { qo_nextraces, filtraces_n } = rcon;

  return (
    <>
      {
        <>
          {qo_nextraces.isLoading ? (
            <>
              <Loader01c />
            </>
          ) : (
            <>
              {qiserr(qo_nextraces) && (
                <p className="text-center text-red-300 resp-text-1">
                  {qiserr(qo_nextraces)}
                </p>
              )}
              {qissuccesss(qo_nextraces) && (
                <>
                  {filtraces_n == 0 ? (
                    <p className="text-center text-acc0 font-digi resp-text-3">
                      No Races
                    </p>
                  ) : (
                    <RacesList />
                  )}
                </>
              )}
            </>
          )}
        </>
      }
      <div className="h-[5rem]"></div>
    </>
  );
};

export const RacesNext = ({ def_my_bikes = false }) => {
  const appcon = useAppContext();
  const accon = useAccountContext();
  const { usd_to_tok_val, tok_to_usd_val, pserach, upd_psearch } = appcon;
  const { now } = useNowContext();

  const vhids = accon?.hids || [];
  const [selrace, set_selrace] = useState(null);
  const [mybikes, set_mybikes] = useState(def_my_bikes ?? false);

  const [qo_tokpri, qo_nextraces] = useQueries([
    q_token_prices(),
    q_nexttorun({}, { enabled: true }),
  ]);
  const [races, races_n] = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    let rs = getv(qo_nextraces.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    rs = _.chain(rs)
      .map((r) => {
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
        return r;
      })
      .compact()
      .value();
    return [rs, rs.length];
  }, [qo_nextraces.dataUpdatedAt, qo_tokpri.dataUpdatedAt]);

  const [filtraces, filtraces_n] = useMemo(() => {
    let rs = races;
    if (mybikes !== true) return [rs, rs.length];
    rs = _.filter(rs, (r) => {
      let inters = _.intersection(r.hids, vhids);
      let hasbikes = inters.length > 0;
      // console.log(r.rid, inters, hasbikes);
      return hasbikes;
    });
    // console.log("filtraces", rs);
    return [rs, rs.length];
  }, [jstr(races), mybikes, jstr(vhids)]);

  useEffect(() => {
    let rem = { selrace };
    rem.mybikes = mybikes === true ? "true" : "false";
  }, [jstr({ selrace, mybikes })]);

  const rcon = {
    qo_nextraces,
    races,
    races_n,
    filtraces,
    filtraces_n,
    selrace,
    set_selrace,
  };

  return (
    <RacesNextContext.Provider value={rcon}>
      <div className="max-w-[98vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <div className="fr-sc">
          <div
            onClick={() => set_mybikes(!mybikes)}
            className={twMerge(
              "fr-sc resp-gap-2 cursor-pointer resp-text-1",
              mybikes ? "text-acc0" : "",
            )}
          >
            <FontAwesomeIcon icon={mybikes ? faToggleOn : faToggleOff} />
            <span>MyBikes</span>
          </div>
          <div className="flex-1"></div>
          <div className="fr-cc resp-gap-1 text-acc0 resp-text-1">
            {mybikes && (
              <>
                <span className="">Racing in</span>
                <span>{filtraces.length}</span>
                <span>/</span>
              </>
            )}
            <>
              <span>{races.length}</span>
              <span>Races</span>
            </>
          </div>
        </div>
        <div className="h-[1rem]"></div>
        <RacesSection />
      </div>
    </RacesNextContext.Provider>
  );
};
