import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BImg,
  Card,
  Dropdown,
  HeadC2L,
  Hori_Divider,
  Img,
  Tag,
} from "../components/utilityComps.js";
import { useAppContext } from "../App.js";
import {
  filt_ar_using_filters,
  gen_filters_cleared,
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import {
  base64_to_json,
  dec,
  emp,
  getv,
  iso,
  json_to_base64,
  jstr,
  nano,
  nils,
} from "../utils/utils.js";
import { InpAutoWrap, set_state_ob } from "../components/input.js";
import {
  class_cn,
  class_text,
  get_payout_cn,
  get_payout_txt,
} from "../utils/cn_map.js";
import { useQueries } from "react-query";
import {
  q_open_minis,
  q_token_prices,
  q_vaultbikes,
  q_vaultraces,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  RaceCard,
  RaceRow_side,
  StableHRow,
  get_race_from_mini,
} from "../utils/raceutils.js";
import _ from "lodash";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faClose,
  faFilter,
  faFlagCheckered,
  faHelmetSafety,
  faHelmetUn,
} from "@fortawesome/free-solid-svg-icons";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

export const RacesContext = createContext();
export const useRacesContext = () => useContext(RacesContext);

const RacesList = () => {
  const { qo_open_minis, filtraces, races_n, q_open_minis_enabled } =
    useRacesContext();
  return (
    <>
      {(filtraces || []).map((race) => {
        return (
          <RaceCard {...{ race, key: race.rid, useContext: useRacesContext }} />
        );
      })}
    </>
  );
};

const RacesSection = () => {
  const rcon = useRacesContext();
  const { q_open_minis_enabled, qo_open_minis, filtraces_n } = rcon;
  return (
    <>
      {q_open_minis_enabled == false ? (
        <>
          <p className="text-center text-yellow-300 font-digi resp-text-1">
            Please Select Some Filters to view Races
          </p>
        </>
      ) : (
        <>
          {qo_open_minis.isLoading ? (
            <>
              <Loader01c />
            </>
          ) : (
            <>
              {qiserr(qo_open_minis) && (
                <p className="text-center text-red-300 resp-text-1">
                  {qiserr(qo_open_minis)}
                </p>
              )}
              {qissuccesss(qo_open_minis) && (
                <>
                  {filtraces_n == 0 ? (
                    <p className="text-center text-acc0 font-digi resp-text-3">
                      No Races on this Filter
                    </p>
                  ) : (
                    <RacesList />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <div className="h-[5rem]"></div>
    </>
  );
};

const Banner = () => {
  return (
    <div className="resp-p-2">
      <div className={"h-[25rem] px-0 py-0 mx-auto"}>
        <Img
          img="https://i.imgur.com/4VV0cfq.png"
          className={"h-full w-auto"}
        />
      </div>
    </div>
  );
};

function Races_Special() {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  // Filters
  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("open_races.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);

  const [filt, set_filt] = useState({});

  const [peektour, set_peek_tour] = useState(false);
  const valfilt = useMemo(() => {
    let valfilt = { class: [72] };
    return valfilt;
  }, [filt]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const q_open_minis_enabled =
    peektour || !_.isEmpty(valfilt.cb) || !_.isEmpty(valfilt.class);
  const [qo_tokpri, qo_open_minis] = useQueries([
    q_token_prices(),
    q_open_minis(
      peektour
        ? {
            class: [70],
            cb: [],
          }
        : {
            cb: valfilt?.cb,
            class: valfilt?.class,
          },
      { enabled: q_open_minis_enabled }
    ),
  ]);

  const [races, races_n] = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    let rs = getv(qo_open_minis.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = get_race_from_mini(r);
      if (race && ethusd) {
        race.fee_usd = race.fee * ethusd;
        race.prize_usd = race.prize * ethusd;
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    return [races, races.length];
  }, [qo_open_minis.dataUpdatedAt, jstr(valfilt), qo_tokpri.dataUpdatedAt]);

  const [filtraces, filtraces_n] = useMemo(() => {
    if (peektour == true) return [races, races_n];
    let rs = filt_ar_using_filters({ ar:races, filters: filt });
    return [rs, rs.length];
  }, [jstr(races), jstr(valfilt), peektour]);

  const [selrace, set_selrace] = useState(undefined);
  useEffect(() => {
    if (
      !nils(psearch.rid) &&
      (psearch.rid !== selrace || selrace === undefined)
    )
      set_selrace(psearch.rid);
  }, [psearch.rid]);

  useEffect(() => {
    console.log({ selrace });
  }, [selrace]);

  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    rem.rid = selrace ?? psearch.rid;
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), selrace]);

  const selrace_dets = useMemo(() => {
    return _.find(filtraces, (r) => r.rid == selrace);
  }, [selrace, jstr(filtraces)]);
  useEffect(() => {
    if (!selrace) return;
    let r = _.find(filtraces, (r) => r.rid == selrace);
    // if (!r && selrace !== undefined) set_selrace(null);
  }, [selrace]);

  const rcon = {
    filt,
    set_filt,
    clear_filt,

    peektour,
    set_peek_tour,

    valfilt,

    q_open_minis_enabled,
    qo_open_minis,

    races,
    races_n,
    filtraces,
    filtraces_n,

    selrace,
    set_selrace,
    selrace_dets,
  };

  const pagetitle = useMemo(() => {
    return `Special Class Races | FBike DNA`;
  }, []);

  return (
    <RacesContext.Provider value={rcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>

      <div className="xs:block lg:hidden">
        <div className="h-page relative">
          <RacesSection />
        </div>
      </div>

      <div className="xs:hidden lg:block h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <Banner />
          <RacesSection />
        </div>
      </div>
    </RacesContext.Provider>
  );
}

export default Races_Special;
