import React, { useMemo } from "react";
import { useQueries } from "react-query";
import { q_creators, qissuccess, qissuccesss } from "../queries/queries.js";
import { getv } from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import { Card, Img } from "../components/utilityComps.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const CreatorCard = ({ creator: c }) => {
  return (
    <div className="mx-auto w-[40rem] max-w-[95vw] my-2">
      <Link to={c.link} target="_blank">
        <Card className="w-full bg-gradient-to-bl from-r2dark to-r2lig/30 card-grad-border xs:rounded-[2rem] :rounded-[4rem]">
          <div className="fr-sc ">
            <div className="xs:w-[4rem] lg:w-[h-4rem] aspect-square resp-my-2 resp-mx-4">
              <Img img={c.logo} />
            </div>
            <div className="h-full">
              <div className="font-digi resp-text-2">{c.name}</div>
              <p className="resp-text--1 whitespace-pre-wrap">{c.txt}</p>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

export const CreatorsPage = () => {
  const [qocrs] = useQueries([q_creators()]);
  const crs = useMemo(() => {
    if (!qissuccesss(qocrs)) return [];
    return getv(qocrs, "data.result") ?? [];
  }, [qocrs.dataUpdatedAt]);

  return (
    <>
      <Helmet>
        <title>{"Creators || DNA Racing"}</title>
      </Helmet>
      <div className="w-full h-page">
        <div className="mx-auto w-[60rem] max-w-[98vw]">
          <div className="h-[3rem]"></div>
          <p className="text-center resp-text-2 font-digi text-white">
            Creators
          </p>
          <div className="h-[1rem]"></div>

          {qocrs.isLoading ? (
            <Loader01c size="xs" />
          ) : qissuccesss(qocrs) ? (
            <div className="">
              {crs.map((c) => {
                return <CreatorCard creator={c} />;
              })}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
