import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Loader01c } from "../../components/anims";
import { extract_inp } from "../../components/input";
import { PopUp, PopupCloseBtn } from "../../components/popup";
import { MiniElementTag, MiniGenderTag } from "../../components/ShortComps";
import { Card, InpText, Tag } from "../../components/utilityComps";
import { q_factions_agents_assign } from "../../queries/queries";
import { useAccountContext } from "../../wrappers/AccountWrapper";
import { useFactionContext } from "./Factions";

const _ = require("lodash");
const {
  nils,
  getv,
  iso,
  nano,
  jstr,
  cdelay,
  iso_format,
} = require("../../utils/utils");

export const AgentCard_Assign = ({ a, q }) => {
  const { agid } = a;

  const accon = useAccountContext();
  const { hids, bikesob } = accon;
  const [selhid, set_selhid] = useState(null);

  const [pop, set_pop] = useState(false);

  const searchid = `agent${a.agid}-bike-search`;
  const selhids_searchtxt = extract_inp(searchid);

  const filthids = useMemo(() => {
    let searchtxt = selhids_searchtxt;
    console.log();
    if (nils(searchtxt)) return hids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(hids), selhids_searchtxt]);

  const [popdata, set_popdata] = useState({});
  const closepopup = () => {
    set_popdata({});
    set_pop(false);
  };
  const assign_bike_to_agent = async ({ hid }) => {
    try {
      set_popdata({
        loading: true,
        hid,
        msgtype: "info",
        msg: hid == null ? "Unassigning Bike" : "Assigning bike to agent...",
      });

      let resp = await q_factions_agents_assign({ agid, hid }).queryFn();
      if (resp.status == "error") throw new Error(resp.err);
      await cdelay(2 * 1e3);

      set_popdata({
        loading: false,
        success: true,
        msgtype: "info",
        msg: hid == null ? "Bike Unassigned" : "Bike Assigned",
      });
      if (q) q.refetch();
      setTimeout(closepopup, 1 * 1e3);
    } catch (err) {
      set_popdata({ loading: false, msgtype: "error", msg: err.message });
    }
  };

  return (
    <div class="fr-sc resp-gap-1">
      <div class="flex-1"></div>

      <div
        className="p-1 resp-text--2 rounded-md bg-acc4/40 -skew-x-12"
        onClick={() => set_pop(true)}
      >
        update
      </div>

      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        overlayclose={false}
        openstate={pop}
      >
        <Card className="xs:w-[98vw] lg:w-[40rem] card-basic-bg card-grad-border">
          <div class="fr-sc">
            <div class="flex-1"></div>
            <PopupCloseBtn closepopup={closepopup} />
          </div>

          <div class="my-1"></div>

          <InpText
            {...{
              id: searchid,
              setter: () => {},
              contprops: {
                className: "w-full bg-r2dark",
              },
              inpprops: {
                className: "w-full",
              },
              placeholder: "Search by bikeid or Name",
              autoComplete: "off",
            }}
          />

          <div class="my-1"></div>
          {_.isEmpty(popdata) ? (
            <>
              {!nils(a?.ashid) ? (
                <div class="fr-sc resp-gap-2 font-digi my-2">
                  <span>Assigned:</span>
                  <span className={"text-acc4"}>
                    {`#${a.ashid}- ${getv(bikesob, `${a.ashid}.name`)}`}
                  </span>
                  <div class="flex-1"></div>
                  {popdata.loading && popdata.hid == null ? (
                    <Loader01c size="s" />
                  ) : (
                    <Tag
                      onClick={() => {
                        assign_bike_to_agent({ hid: null });
                      }}
                      className="bg-red-500"
                    >
                      <span className="resp-text--2">Unassign</span>
                    </Tag>
                  )}
                </div>
              ) : null}
            </>
          ) : (
            <div
              class={twMerge(
                "fr-sc resp-p-2 rounded-lg my-2",
                popdata.msgtype == "error"
                  ? "text-red-400 border border-red-400"
                  : popdata.msgtype == "info"
                    ? "text-acc4 border border-acc4"
                    : "text-white border border-slate-600",
              )}
            >
              {popdata.loading && <Loader01c size="s" />}
              {popdata.success && (
                <FontAwesomeIcon
                  className="text-green-400 resp-text-1"
                  icon={faCheck}
                />
              )}
              <span className="resp-text--1">{popdata.msg}</span>
            </div>
          )}
          <div class="my-1"></div>

          <Card className="card-dark-bg w-full h-[50vh] overflow-auto">
            {filthids.map((hid) => {
              let h = getv(bikesob, hid) || {};
              return (
                <div
                  onClick={() => {}}
                  className={twMerge(
                    "p-2 fr-sc w-full resp-gap-4 cursor-pointer select-none border-b-2 border-r2lig/40",
                  )}
                >
                  <div class="flex-1 fr-sc">
                    <div class="fc-ss flex-1 resp-gap-1 resp-text--2">
                      <div class="fr-sc resp-gap-1">
                        <span className="resp-text--1 font-digi text-r2lig">
                          {hid}
                        </span>
                        <span>-</span>
                        <span className="resp-text--1 font-digi text-white">
                          {h.name}
                        </span>
                      </div>
                      <div class="fr-sc resp-gap-1">
                        <span>{_.capitalize(h.type)}</span>
                        <span>/</span>
                        <span>F{h.fno}</span>
                        <span>/</span>
                        <MiniElementTag element={h.element} />
                        <span>/</span>
                        <MiniGenderTag gender={h.gender} />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      assign_bike_to_agent({ hid });
                    }}
                    className={twMerge(
                      "resp-text--1 fr-sc resp-p-1 rounded-md font-digi resp-gap-1 ",
                      popdata.loading ? "bg-r2dark" : "bg-acc4/40",
                    )}
                  >
                    {!popdata?.loading ? (
                      <span class="resp-text--1">Assign</span>
                    ) : popdata?.loading === true && popdata.hid == hid ? (
                      <>
                        <Loader01c size="s" />
                        <span class="resp-text--1">Assigning..</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </Card>
        </Card>
      </PopUp>
    </div>
  );
};

export const ScoreCard = ({ a, k, txt, show_date = false }) => {
  let sc = getv(a, `scoresmap.${k}.score`);
  let mx = getv(a, `scoresmap.${k}.mx`);
  let p = nils(mx) ? 0 : Math.min(sc / mx, 1);
  return (
    <div class="w-full">
      <div class="fr-sc mt-2 resp-px-4 resp-text--1 font-digi resp-gap-1">
        <div class="fc-ss">
          <p>{txt}</p>
          {show_date && (
            <div className={twMerge("resp-text--3 text-acc4")}>
              {!nils(getv(a, `scoresmap.${k}.st`)) && (
                <>
                  <span>
                    {iso_format(
                      getv(a, `scoresmap.${k}.st`),
                      getv(a, `scoresmap.${k}.st`)[6] ==
                        getv(a, `scoresmap.${k}.ed`)[6]
                        ? "DD"
                        : "DD MMM",
                    )}
                  </span>
                </>
              )}
              <span>{" - "}</span>
              <span>{iso_format(getv(a, `scoresmap.${k}.ed`), "DD MMM")}</span>
              <span> </span>
              <span>
                [ {iso_format(getv(a, `scoresmap.${k}.st`), "hh:mmA z")} ]
              </span>
            </div>
          )}
        </div>
        <div class="flex-1"></div>
        <span className="resp-text-0">{getv(a, `scoresmap.${k}.score`)}</span>
        <span>/</span>
        <span>{getv(a, `scoresmap.${k}.mx`)}</span>
      </div>
      <div class="px-4">
        <div class="relative">
          <div class="rounded-full xs:h-[0.5rem] lg:h-[1rem] bg-black"></div>
          <div
            style={{
              width: `${p * 98}%`,
            }}
            class={twMerge(
              "absolute rounded-full  bg-acc4 ",
              "xs:h-[0.3rem] xs:top-[0.1rem] xs:left-[0.05rem]",
              "lg:h-[0.6rem] lg:top-[0.2rem] lg:left-[0.1rem]",
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export const AgentCard = ({ a, q }) => {
  return (
    <div class="p-2 resp-text--1 rounded-md card-basic-bg card-grad-border">
      <div class="grid grid-cols-5 resp-gap-2">
        <div class="col-span-2 aspect-[1200/2400]">
          <img className="" src={getv(a, "img")} />
        </div>
        <div class="col-span-3 h-full">
          <div class="resp-text-1 w-full text-center font-digi my-2">
            {a.name}
          </div>
          <div class="p-2 rounded-md card-dark-bg my-2 card-grad-border">
            <div class="fr-sc resp-gap-1 my-2">
              <span>ID:</span>
              <span>{getv(a.agid)}</span>
            </div>

            <div class="fr-sc resp-gap-1 my-2">
              <span>Faction:</span>
              <span className="text-acc4 uppercase">{getv(a, "faction")}</span>
            </div>
          </div>

          <div class="p-2 rounded-md card-dark-bg my-2 card-grad-border">
            <span>Assigned Core</span>
            <div class="fr-sc resp-gap-1">
              {nils(a.ashid) ? (
                <span className="text-red-400">--</span>
              ) : (
                <span className="text-acc4">
                  #{a.ashid} - {getv(a, "assigned.name")}
                </span>
              )}
            </div>
            <AgentCard_Assign
              {...{
                a,
                q,
              }}
            />
          </div>
        </div>

        <div class="col-span-5">
          <ScoreCard a={a} k="daily" txt="Daily" />
          <ScoreCard a={a} k="weekly" txt="Weekly" />
          <ScoreCard a={a} k="season" txt="Season" />
        </div>
      </div>
    </div>
  );
};

export const FactionAgents = () => {
  const fcon = useFactionContext();
  const { vault, agids, agentsob } = fcon;

  return (
    <div class="resp-p-2">
      <div class="grid xs:grid-cols-1 lg:grid-cols-3 resp-gap-2">
        {agids.map((agid) => {
          let ad = getv(agentsob, agid);
          if (nils(ad))
            return (
              <div class="resp-p-2 card-grad-border card-basic-bg">
                <Loader01c size="s" />
              </div>
            );
          return (
            <AgentCard
              {...{
                agid,
                a: ad,
                q: ad.q,
                key: ad.agid,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
