import { useEffect, useRef, useState } from "react";
import { iso, nils } from "../utils/utils.js";
import _ from "lodash";
import { gets_skinurl } from "../utils/links.js";
import { Card } from "./utilityComps.js";
import { twMerge } from "tailwind-merge";
import skin_thumbnail from "../data/skin_thumbnail.jpg";

const trailsData = {};
const nulltrailimg = "";

export const SkinCard = ({
  skin,
  rarity: cat,
  activeskin,
  idx,
  viewdets = false,
  className = "",
  skinname_cn = "",
  rarityname_cn = "",
}) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const url = gets_skinurl(skin);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            setLoaded(true);
          };
          observer.disconnect();
        }
      });
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [url]);

  const active = activeskin == skin;
  const blur = !nils(activeskin) && activeskin !== false && !active;

  const getTransformOriginClass = (idx) => {
    let i = idx % 3;
    switch (i) {
      case 0:
        return "origin-left"; // If you're using Tailwind v2.2.0 and above
      case 2:
        return "origin-right"; // If you're using Tailwind v2.2.0 and above
      default:
        return "origin-center";
    }
  };
  const transformorigin = getTransformOriginClass(idx);

  return (
    <Card
      className={twMerge(
        "xs:p-0 xs:m-0 md:p-0 md:m-0 xs:w-[8rem] md:w-full mx-auto relative overflow-visible",
        className,
      )}
    >
      <div
        style={{
          background: `url(${skin_thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={twMerge(
          "img-obey-cont h-auto w-full ",
          "aspect-[2200/1600] w-full trasition duration-300",
          transformorigin,
          blur ? "" : "",
          active
            ? "absolute xs:scale-[1] md:scale-[1.1] 2xl:scale-[1.1] z-[20]"
            : "",
        )}
      >
        {
          <div
            className={twMerge(
              "absolute w-full h-full fc-cc transition duration-500",
              active || viewdets ? "opacity-100" : "opacity-0",
            )}
          >
            <div className="w-full h-[70%]"></div>
            <div className="w-full h-[30%] bg-gradient-to-b from-transparent to-dark/40">
              <div className="flex flex-row justify-start items-end w-full h-full text-black p-4 gap-4">
                <div
                  className={twMerge(
                    "flex-1 font-digi text-white italic",
                    skin && skin.length > 10
                      ? "xs:text-[0.35rem] md:text-[0.6rem] 2xl:text-[0.7rem]"
                      : "xs:text-[0.5rem] md:text-[1rem] 2xl:text-[1.1rem]",
                    skinname_cn,
                  )}
                >
                  {skin}
                </div>
                <div
                  className={twMerge(
                    "font-digi xs:text-[0.4rem] md:text-[0.7rem] 2xl:text-[0.8rem]",
                    rarityname_cn,
                  )}
                >
                  {_.upperCase(cat)}
                </div>
              </div>
            </div>
          </div>
        }
        <img
          ref={imgRef}
          src={loaded ? url : ""}
          alt={skin} // Recommended to provide an alt attribute for accessibility
          loading="lazy"
          thumbnailSrc={skin_thumbnail}
          style={loaded ? {} : { visibility: "hidden" }}
        />
      </div>
    </Card>
  );
};

export const TrailCard = ({
  trail,
  idx,
  viewdets = false,
  active = false,
  className = "",
  trailname_cn = "",
}) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const t = trailsData[trail] ?? {};

  const url = t.preview;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            setLoaded(true);
          };
          observer.disconnect();
        }
      });
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [url]);

  const blur = !active;

  const getTransformOriginClass = (idx) => {
    let i = idx % 3;
    switch (i) {
      case 0:
        return "origin-left"; // If you're using Tailwind v2.2.0 and above
      case 2:
        return "origin-right"; // If you're using Tailwind v2.2.0 and above
      default:
        return "origin-center";
    }
  };
  const transformorigin = getTransformOriginClass(idx);

  return (
    <Card
      className={twMerge(
        "xs:p-0 xs:m-0 md:p-0 md:m-0 xs:w-[8rem] md:w-full mx-auto relative overflow-visible",
        className,
      )}
    >
      <div
        style={{
          background: `url(${skin_thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={twMerge(
          "img-obey-cont h-auto w-full ",
          "aspect-[2200/1600] w-full trasition duration-300",
          transformorigin,
          blur ? "" : "",
          active
            ? "absolute xs:scale-[1] md:scale-[1.1] 2xl:scale-[1.1] z-[20]"
            : "",
        )}
      >
        {
          <div
            className={twMerge(
              "absolute w-full h-full fc-cc transition duration-500",
              active || viewdets ? "opacity-100" : "opacity-0",
            )}
          >
            <div className="w-full h-[70%]"></div>
            <div className="w-full h-[30%] bg-gradient-to-b from-transparent to-dark/40">
              <div className="flex flex-row justify-start items-end w-full h-full text-black p-4 gap-4">
                <div
                  className={twMerge(
                    "flex-1 font-digi text-white italic",
                    trail && trail.length > 10
                      ? "xs:text-[0.35rem] md:text-[0.6rem] 2xl:text-[0.7rem]"
                      : "xs:text-[0.5rem] md:text-[1rem] 2xl:text-[1.1rem]",
                    trailname_cn,
                  )}
                >
                  {_.capitalize(trail)}
                </div>
              </div>
            </div>
          </div>
        }
        <img
          ref={imgRef}
          src={loaded ? url : ""}
          alt={""} // Recommended to provide an alt attribute for accessibility
          loading="lazy"
          thumbnailSrc={nulltrailimg}
          style={loaded ? {} : { visibility: "hidden" }}
        />
      </div>
    </Card>
  );
};
