import {
  faArrowsUpDown,
  faCheckCircle,
  faCircle,
  faTimesCircle,
  faTrash,
  faTriangleCircleSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { twMerge } from "tailwind-merge";
import { tokdecn, tokdecn2 } from "../App";
import { Loader01c } from "../components/anims";
import { extract_inp, set_val_inp } from "../components/input";
import { Card, Img, InpText, Tag, TokenIcon } from "../components/utilityComps";
import { mm_asset_signer } from "../contracts/contract_funcs";
import { LCToken } from "../contracts/LCToken/LCToken";
import {
  cdelay,
  dec,
  getv,
  jstr,
  nils,
  setv,
  toeth,
  tofeth,
} from "../utils/utils";
import { useAuthContext } from "../wrappers/AuthWrapper";
import _ from "lodash";
import { Link } from "react-router-dom";
import { polychainimg } from "../utils/links";
import {
  polytxnidlink,
  qissuccesss,
  q_lctoken_counts,
} from "../queries/queries";
import { useQueries } from "react-query";

const LCTokenSwapContext = createContext({});
const useLCTokenSwap = () => useContext(LCTokenSwapContext);

const BalanceView = ({ token, balance }) => {
  const { vault, auth } = useAuthContext();
  return (
    <div className="fc-cc">
      <span>{token}</span>
      <Tag className="bg-gray-600 text-white fr-sc resp-gap-1 w-max">
        <TokenIcon token={token} />
        {nils(balance) ? (
          <span>--</span>
        ) : (
          <span>{dec(balance, tokdecn2(token))}</span>
        )}
      </Tag>
    </div>
  );
};

const basetoken0 = "weth";
const basetoken1 = "lc";

const sellslippage = 0.08;

const LCBurnedMsg = () => {
  const [qoc] = useQueries([q_lctoken_counts()]);
  const cs = getv(qoc, "data.result", 0);

  if (!qissuccesss(qoc)) return null;

  return (
    <>
      <div class=" w-[30rem] mx-auto resp-gap-1 p-2  rounded-md text-acc0">
        <div class="fr-sc resp-gap-1 ">
          <div class="flex-1"></div>
          <span>Total LC Burned</span>
          <TokenIcon token={basetoken1} />
          <span>{dec(getv(cs, "burn.value"), tokdecn2(basetoken1))}</span>
        </div>
      </div>
      <hr className="w-[20rem] max-w-[95vw] mx-auto mb-2" />
    </>
  );
};

export const LCTokenSwapWidget = () => {
  const { vault, auth } = useAuthContext();

  const lcon = {};
  const [toks, set_toks] = useState([basetoken0, basetoken1]);
  const [t0, t1] = toks;
  const [resp, set_resp] = useState({});
  const [balmap, set_balmap] = useState({});

  const upd_bal = async (token) => {
    try {
      const pcon = await mm_asset_signer(token);
      let bal = await pcon.balanceOf(vault);
      bal = tofeth(bal);
      console.log("got bal", token, bal);
      set_balmap((prev) => ({ ...prev, [token]: bal }));
    } catch (err) {
      console.log(err);
    }
  };
  const update_balances = async () => {
    setTimeout(() => upd_bal(t0), 1 * 1e3);
    setTimeout(() => upd_bal(t1), 2 * 1e3);
  };
  useEffect(() => {
    if (!auth) return;
    update_balances();
  }, [auth, vault, jstr([t0, t1])]);

  const actionmap = {
    update_balances,
    get_current_price: async () => {
      let con = await LCToken.get_contract();
      let resp = await con.getCurrentPrice();
      let cost = tofeth(resp);
      return { cost };
    },
    get_buy_info: async (input = {}) => {
      let con = await LCToken.get_contract();
      let resp = await con.getBuyInfo(toeth(input.no_of_tokens));
      let [err_sup, avg_price, tot_cost] = resp;
      let output = {
        err_sup,
        avg_price: tofeth(avg_price),
        tot_cost: tofeth(tot_cost),
      };
      return output;
    },
    get_buy_by_eth_info: async (input = {}) => {
      let con = await LCToken.get_contract();
      let resp = await con.getBuyByEthInfo(toeth(input.for_weth));
      let [err_sup, avg_price, tot_tokens] = resp;
      let output = {
        err_sup,
        avg_price: tofeth(avg_price),
        tot_tokens: tofeth(tot_tokens),
      };
      return output;
    },
    get_sell_info: async (input = {}) => {
      let con = await LCToken.get_contract();
      let resp = await con.getSellInfo(toeth(input.no_of_tokens));
      let [err_sup, avg_price, tot_cost] = resp;

      let output = {
        err_sup,
        avg_price: tofeth(avg_price),
        tot_credits: tofeth(tot_cost),
      };
      output.tot_credits = parseFloat(output.tot_credits) * (1 - sellslippage);
      return output;
    },
  };

  actionmap.buy = async (input = {}, set_progress = null) => {
    if (set_progress) {
      set_progress((p) => {
        return {
          loading: true,
          ps: _.chain([
            ["WETH Balance", "stale", ""],
            ["Allowance Check", "stale", ""],
            ["Buy LC Tokens", "stale", ""],
          ])
            .map((e, i) => {
              return { head: e[0], msgtype: e[1], msg: e[2] };
            })
            .value(),
        };
      });
    }
    await cdelay(1 * 1e3);
    const psetter = (stage, head, msgtype, msg) => {
      if (!set_progress) return;
      set_progress((p) => {
        let np = _.cloneDeep(p);
        if (nils(np.ps)) np.ps = [];
        setv(np, `ps.${stage}`, {
          head,
          msgtype,
          msg,
        });
        return np;
      });
    };

    let con = await LCToken.get_contract();
    console.log("conaddr", con.contractAddress);

    let pcon = await mm_asset_signer(basetoken0);
    let wethbal = await pcon.balanceOf(vault);
    if (set_progress) {
      psetter(0, "WETH Balance", "loading", "Checking WETH balance in vault");
    }

    wethbal = tofeth(wethbal);
    let inptoks = input.for_weth;
    if (!nils(inptoks) && inptoks.startsWith(".")) inptoks = `0${inptoks}`;
    inptoks = parseFloat(inptoks);
    if (nils(inptoks)) throw new Error("Invalid input amount");

    console.log("wethbal", wethbal);
    if (wethbal < inptoks) {
      let errmsg = "Insufficient WETH balance in vault";
      if (set_progress) {
        psetter(0, "WETH Balance", "error", errmsg);
      }
      throw new Error(errmsg);
    } else {
      if (set_progress) {
        psetter(0, "WETH Balance", "success", "WETH balance is sufficient");
      }
    }
    await cdelay(1 * 1e3);

    if (set_progress) {
      psetter(
        1,
        "Allowance Check",
        "loading",
        "Checking allowance of WETH to contract",
      );
    }

    let alw = await pcon.allowance(vault, con.contractAddress);
    alw = tofeth(alw);
    console.log("alw", alw);
    if (alw < inptoks) {
      if (set_progress) {
        psetter(1, "Allowance Check", "action", "Confirm Metamask");
      }
      let resp = await pcon.approve(con.contractAddress, toeth(inptoks * 1.01));
      if (set_progress) {
        psetter(1, "Allowance Check", "loading", "Approving WETH allowance");
      }
      resp = await resp.wait();
      console.log("approved", resp);
      if (set_progress) {
        psetter(1, "Allowance Check", "success", "Allowance approved");
      }

      await cdelay(1 * 1e3);
    } else {
      if (set_progress) {
        psetter(1, "Allowance Check", "success", "Allowance is sufficient");
      }
    }
    await cdelay(1 * 1e3);

    if (set_progress) {
      psetter(2, "Buy LC Tokens", "loading", "Querying Latest Prices");
    }
    let mintokrec = await actionmap.get_buy_by_eth_info({
      for_weth: inptoks,
    });
    console.log("mintokrec", mintokrec);
    mintokrec = getv(mintokrec, "tot_tokens", 0);

    if (set_progress) {
      psetter(2, "Buy LC Tokens", "action", "Confirm Metamask to buy");
    }
    let resp = await con.buy(toeth(inptoks), toeth(mintokrec));
    if (set_progress) {
      psetter(2, "Buy LC Tokens", "loading", "waiting for txn to process");
    }

    resp = await resp.wait();
    resp.parsedLogs = await con.getParsedLogs(resp.logs);
    let buylog = _.find(resp.parsedLogs, { name: "Buy" });
    let msg = nils(buylog)
      ? "done"
      : `Received ${dec(getv(buylog, "args.totalTokens"), tokdecn(basetoken1))} LC Tokens`;
    console.log("resp", resp);
    let output = resp;
    if (set_progress) {
      psetter(2, "Buy LC Tokens", "success", msg);
    }
    setTimeout(() => {
      update_balances();
    }, 2 * 1e3);

    if (set_progress) {
      set_progress((p) => {
        return {
          ...p,
          loading: false,
          done: true,
          success: true,
          result: output,
        };
      });
    }
    return output;
  };

  actionmap.sell = async (input = {}, set_progress = null) => {
    if (set_progress) {
      set_progress((p) => {
        return {
          loading: true,
          ps: _.chain([
            ["LC Balance", "stale", ""],
            ["Allowance Check", "stale", ""],
            ["Sell LC Tokens", "stale", ""],
          ])
            .map((e, i) => {
              return { head: e[0], msgtype: e[1], msg: e[2] };
            })
            .value(),
        };
      });
    }
    await cdelay(1 * 1e3);
    const psetter = (stage, head, msgtype, msg) => {
      if (!set_progress) return;
      set_progress((p) => {
        let np = _.cloneDeep(p);
        if (nils(np.ps)) np.ps = [];
        setv(np, `ps.${stage}`, {
          head,
          msgtype,
          msg,
        });
        return np;
      });
    };

    let inptoks = input.no_of_tokens;
    if (!nils(inptoks) && inptoks.startsWith(".")) inptoks = `0${inptoks}`;
    inptoks = parseFloat(inptoks);
    if (nils(inptoks)) throw new Error("Invalid input amount");

    let con = await LCToken.get_contract();
    console.log("conaddr", con.contractAddress);
    let pcon = await mm_asset_signer(basetoken1);
    let lcbal = await pcon.balanceOf(vault);
    if (set_progress) {
      psetter(0, "LC Balance", "loading", "Checking LC balance in vault");
    }

    lcbal = tofeth(lcbal);
    console.log("lcbal", lcbal);
    if (lcbal < inptoks) {
      let errmsg = "Insufficient LC balance in vault";
      if (set_progress) {
        psetter(0, "LC Balance", "error", errmsg);
      }
      throw new Error(errmsg);
    } else {
      if (set_progress) {
        psetter(0, "LC Balance", "success", "LC balance is sufficient");
      }
    }
    await cdelay(1 * 1e3);

    if (set_progress) {
      psetter(
        1,
        "Allowance Check",
        "loading",
        "Checking allowance of WETH to contract",
      );
    }

    let alw = await pcon.allowance(vault, con.contractAddress);
    alw = tofeth(alw);
    console.log("alw", alw);
    if (alw < inptoks) {
      if (set_progress) {
        psetter(1, "Allowance Check", "action", "Confirm Metamask");
      }
      let resp = await pcon.approve(
        con.contractAddress,
        toeth(Math.max(inptoks * 1.1, lcbal * 0.9)),
      );
      if (set_progress) {
        psetter(1, "Allowance Check", "loading", "Approving LC allowance");
      }
      resp = await resp.wait();
      console.log("approved", resp);
      if (set_progress) {
        psetter(1, "Allowance Check", "success", "Allowance approved");
      }

      await cdelay(1 * 1e3);
    } else {
      if (set_progress) {
        psetter(1, "Allowance Check", "success", "Allowance is sufficient");
      }
    }
    await cdelay(1 * 1e3);

    if (set_progress) {
      psetter(2, "Sell LC Tokens", "loading", "Querying Latest Prices");
    }
    let minwethrec = await actionmap.get_sell_info({
      no_of_tokens: inptoks,
    });
    console.log("minwethrec", minwethrec);
    minwethrec = getv(minwethrec, "tot_credits", 0);
    minwethrec *= 0.95;
    minwethrec = dec(minwethrec, 8);
    console.log("minwethrec", minwethrec);

    if (set_progress) {
      psetter(2, "Sell LC Tokens", "action", "Confirm Metamask to sell");
    }
    let resp = await con.sell(toeth(inptoks), toeth(minwethrec));
    resp = await resp.wait();
    resp.parsedLogs = await con.getParsedLogs(resp.logs);

    let selllog = _.find(resp.parsedLogs, { name: "Sell" });
    let msg = nils(selllog)
      ? "done"
      : `Received ${dec(getv(selllog, "args.amountEth"), tokdecn(basetoken0))} WETH`;
    if (set_progress) {
      psetter(2, "Sell LC Tokens", "success", msg);
    }

    let output = resp;
    setTimeout(() => {
      update_balances();
    }, 2 * 1e3);

    if (set_progress) {
      set_progress((p) => {
        return {
          ...p,
          loading: false,
          done: true,
          success: true,
          result: output,
        };
      });
    }
    return output;
  };

  const run_action = async (action, input = {}) => {
    try {
      set_resp({});
      let output = await actionmap[action](input);
      let o = {
        action,
        input,
        output,
      };
      set_resp(o);
    } catch (err) {
      console.log(action, err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      if (errmsg.length > 100) errmsg = errmsg.slice(0, 100) + " ...";
      let o = {
        action,
        input,
        errmsg,
      };
      set_resp(o);
    }
  };

  const resp_run_action = async (action, input = {}, set_progress = null) => {
    try {
      let output = await actionmap[action](input, set_progress);
      let o = {
        action,
        input,
        output,
      };
      return o;
    } catch (err) {
      console.log(action, err);

      let errmsg = !nils(err.reason) ? err.reason : err.message;
      if (errmsg.length > 100) errmsg = errmsg.slice(0, 100) + " ...";
      let o = {
        action,
        input,
        errmsg,
      };
      if (set_progress) {
        set_progress((p) => {
          let np = _.cloneDeep(p);
          let i = 0;
          for (let j = 0; j < np.ps.length; j++) {
            if (np.ps[j]?.msgtype !== "stale") {
              i = j;
            }
          }

          setv(np, `ps.${i}.msgtype`, "error");
          setv(np, `ps.${i}.msg`, errmsg);

          return {
            ...np,
            loading: false,
            done: true,
          };
        });
      }
      return o;
    }
  };

  const get_ordered_action = (t0, t1, changed) => {
    if (t0 == basetoken0 && t1 == basetoken1)
      return ["get_buy_by_eth_info", "buy"];
    if (t0 == basetoken1 && t1 == basetoken0) return ["get_sell_info", "sell"];
    return [null, null];
  };

  const [finresp, set_finresp] = useState({});
  const [inresp, set_inresp] = useState({});
  const [outresp, set_outresp] = useState({});

  return (
    <>
      <LCTokenSwapContext.Provider value={lcon}>
        {/*
        <Card className="bg-dark w-full ">
          <div class="grid grid-cols-3">
            <div class="fr-cc">
              {!nils(t0) && <BalanceView token={t0} balance={balmap[t0]} />}
            </div>
            <div class="fc-cc">
              <Tag
                onClick={() => {
                  update_balances();
                }}
                className="bg-gray-600 text-white"
              >
                Refresh{" "}
              </Tag>
            </div>
            <div class="fr-cc">
              {!nils(t1) && <BalanceView token={t1} balance={balmap[t1]} />}
            </div>
          </div>
          <div class="my-4"></div>
          <div class="fc-ss resp-gap-2">
            {[
              ["get_current_price", {}],
              ["get_buy_info", { no_of_tokens: 1 }],
              ["get_buy_by_eth_info", { for_weth: 0.01 }],
              ["get_sell_info", { no_of_tokens: 1 }],
              ["buy", { for_weth: 0.01 }],
              ["sell", { no_of_tokens: 1 }],
            ].map(([action, args]) => {
              let btntxt =
                action == "get_current_price"
                  ? "Get Current Price"
                  : action == "get_buy_info"
                    ? `Get Buy Info for ${args.no_of_tokens} LC token`
                    : action == "get_buy_by_eth_info"
                      ? `Get Buy By ETH Info with ${args.for_weth} WETH`
                      : action == "get_sell_info"
                        ? `Get Sell Info for ${args.no_of_tokens} LC token`
                        : action == "buy"
                          ? `Buy for ${args.for_weth} WETH`
                          : action == "sell"
                            ? `Sell ${args.no_of_tokens} LC token`
                            : null;
              let fn = () => {
                run_action(action, args);
              };
              return (
                <Tag onClick={fn} className="bg-gray-600 text-white">
                  {btntxt}
                </Tag>
              );
            })}
          </div>
          <pre class="p-2 m-2 rounded-md bg-slate-800 resp-text--1">
            Response: {JSON.stringify(resp, 4, " ")}
          </pre>
        </Card>
        */}

        <div class="fc-ss w-[30rem] mx-auto resp-gap-1 p-2 border border-acc0/10 rounded-md">
          <div class="w-full bg-reg p-2 rounded-md">
            <div class="fr-sc">
              <span className="p-1 my-2 resp-gap-1">You Pay</span>
              <div class="flex-1"></div>
              <TokenIcon token={t0} />
              <span class="resp-text--1 font-digi text-acc0 uppercase ">
                {t0}
              </span>
            </div>
            <div class="m-1 p-2 rounded-sm bg-dark">
              <div class="fr-sc w-full">
                <InpText
                  {...{
                    id: "inp-in-amt",
                    placeholder: "0",
                    def_val: "" ?? "",
                    className: "font-digi",
                    autoComplete: "off",
                    contprops: {
                      className: "w-full border border-transparent",
                    },
                    inpprops: {
                      className: twMerge("font-digi"),
                      // style: txtcn(doc.inamt),
                    },
                    setter: (v) => {
                      if (v.startsWith(".")) v = `0${v}`;
                      // set_doc({ ...doc, inamt: v });
                    },
                    onChange: async () => {
                      if (t0 == basetoken0 && t1 == basetoken1) {
                        const fn = async () => {
                          set_inresp({});
                          let val = extract_inp("inp-in-amt");
                          let resp = await resp_run_action(
                            "get_buy_by_eth_info",
                            { for_weth: val },
                          );
                          if (!nils(resp.errmsg)) {
                            set_inresp({
                              type: "error",
                              msg: resp.errmsg,
                            });
                            set_val_inp("inp-out-amt", "");
                            return;
                          } else {
                            let tottokrec = getv(resp, "output.tot_tokens", 0);
                            tottokrec = dec(tottokrec, tokdecn(basetoken1));
                            set_val_inp("inp-out-amt", tottokrec);
                          }
                        };
                        setTimeout(fn, 500);
                      } else if (t0 == basetoken1 && t1 == basetoken0) {
                        const fn = async () => {
                          set_inresp({});
                          let val = extract_inp("inp-in-amt");
                          let resp = await resp_run_action("get_sell_info", {
                            no_of_tokens: val,
                          });

                          if (!nils(resp.errmsg)) {
                            set_inresp({
                              type: "error",
                              msg: resp.errmsg,
                            });
                            set_val_inp("inp-out-amt", "");
                            return;
                          } else {
                            let totwethrec = getv(
                              resp,
                              "output.tot_credits",
                              0,
                            );
                            totwethrec = dec(totwethrec, tokdecn(basetoken0));
                            set_val_inp("inp-out-amt", totwethrec);
                          }
                        };
                        setTimeout(fn, 500);
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div class="fr-sc resp-gap-2 cursor-pointer">
              {!nils(inresp?.msg) && (
                <p
                  className={twMerge(
                    "resp-text--2",
                    inresp.type == "error" ? "text-red-500" : "text-green-500",
                  )}
                >
                  {inresp.msg}
                </p>
              )}
              <div onClick={() => {}} class="flex-1"></div>
              <span>Balance</span>
              <span className="text-acc0">{dec(balmap[t0], tokdecn2(t0))}</span>
            </div>
          </div>
          <div class="h-0 relative w-full">
            <div
              onClick={() => {
                set_toks([t1, t0]);
                let v1 = extract_inp("inp-in-amt");
                let v2 = extract_inp("inp-out-amt");
                set_val_inp("inp-in-amt", v2);
                set_val_inp("inp-out-amt", v1);
              }}
              class={twMerge(
                "cursor-pointer border-2 border-transparent hover:border-acc0 transition duration-300",
                "w-max border-[0.3rem] border-dark mx-auto bg-reg p-2 rounded-md ",
                "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]",
              )}
            >
              {true ? (
                <FontAwesomeIcon
                  icon={faArrowsUpDown}
                  className="resp-text-1"
                />
              ) : (
                <Loader01c size="s" />
              )}
            </div>
          </div>
          <div class="w-full bg-reg p-2 rounded-md">
            <div class="fr-sc resp-gap-2">
              <span className="p-1 my-2">You Get</span>
              <div class="flex-1"></div>
              <TokenIcon token={t1} />
              <span class="resp-text--1 font-digi text-acc0 uppercase">
                {t1}
              </span>
            </div>

            <div class="m-1 p-2 rounded-sm bg-dark">
              <div class="fr-sc w-full">
                <InpText
                  {...{
                    id: "inp-out-amt",
                    placeholder: "0",
                    def_val: "" ?? "",
                    className: "font-digi",
                    contprops: {
                      className: "w-full border border-transparent",
                    },
                    inpprops: { className: "font-digi" },
                    autoComplete: "off",
                    onChange: () => {
                      if (t0 == basetoken0 && t1 == basetoken1) {
                        const fn = async () => {
                          set_outresp({});
                          let val = extract_inp("inp-out-amt");
                          let resp = await resp_run_action("get_buy_info", {
                            no_of_tokens: val,
                          });
                          if (!nils(resp.errmsg)) {
                            set_outresp({
                              type: "error",
                              msg: resp.errmsg,
                            });
                            set_val_inp("inp-in-amt", "");
                            return;
                          } else {
                            let totwethrec = getv(resp, "output.tot_cost", 0);
                            totwethrec = dec(totwethrec, tokdecn2(basetoken0));
                            set_val_inp("inp-in-amt", totwethrec);
                          }
                        };
                        setTimeout(fn, 500);
                      } else if (t0 == basetoken1 && t1 == basetoken0) {
                        const fn = async () => {
                          set_outresp({});
                          let val = extract_inp("inp-out-amt");
                          let resp = await resp_run_action(
                            "get_buy_by_eth_info",
                            {
                              for_weth: val,
                            },
                          );
                          if (!nils(resp.errmsg)) {
                            set_outresp({
                              type: "error",
                              msg: resp.errmsg,
                            });
                            set_val_inp("inp-in-amt", "");
                            return;
                          } else {
                            let tottokrec = getv(resp, "output.tot_tokens", 0);
                            tottokrec = dec(tottokrec, tokdecn2(basetoken1));
                            set_val_inp("inp-in-amt", tottokrec);
                          }
                        };
                        setTimeout(fn, 500);
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div onClick={() => {}} class="fr-sc resp-gap-2 cursor-pointer">
              {!nils(outresp?.msg) && (
                <p
                  className={twMerge(
                    "resp-text--2",
                    outresp.type == "error" ? "text-red-500" : "text-green-500",
                  )}
                >
                  {outresp.msg}
                </p>
              )}
              <div class="flex-1"></div>
              <span>Balance</span>
              <span className="text-acc0">{dec(balmap[t1], tokdecn2(t1))}</span>
            </div>
          </div>
          <div class="w-full">
            {_.isEmpty(finresp) && (
              <Tag
                onClick={() => {
                  if (finresp?.loading) return;
                  set_finresp({});
                  if (nils(t0) || nils(t1)) return;
                  if (t0 == basetoken0 && t1 == basetoken1) {
                    let val = extract_inp("inp-in-amt");
                    resp_run_action("buy", { for_weth: val }, set_finresp);
                  } else if (t0 == basetoken1 && t1 == basetoken0) {
                    let val = extract_inp("inp-in-amt");
                    resp_run_action("sell", { no_of_tokens: val }, set_finresp);
                  }
                }}
                className={twMerge(
                  "font-digi resp-text-2 w-full text-center",
                  finresp?.loading
                    ? "bg-gray-700 text-white cursor-wait"
                    : "bg-acc0/40 text-white cursor-pointer",
                )}
              >
                Swap
              </Tag>
            )}
            {!_.isEmpty(finresp) && getv(finresp, "done") == true && (
              <Tag
                onClick={() => {
                  if (finresp?.loading) return;
                  set_finresp({});
                  set_val_inp("inp-in-amt", "");
                  set_val_inp("inp-out-amt", "");
                }}
                className={twMerge(
                  "font-digi resp-text-2 w-full text-center",
                  "bg-red-500/40",
                )}
              >
                Reset
              </Tag>
            )}
            {/*
            <pre class="p-2 m-2 rounded-md bg-slate-800 resp-text--1">
              Response: {JSON.stringify(finresp, 4, " ")}
            </pre>

            */}

            {!_.isEmpty(finresp?.ps) && (
              <div class="fc-ss bg-slate-800 relative w-full border border-acc0/30 rounded-md my-2  p-2">
                {/*
                <div class="h-0 w-full my-2 fr-sc ">
                  <div class="flex-1"></div>
                  <span
                    onClick={() => {
                      set_finresp({});
                    }}
                    class="cursor-pointer text-red-400 bg-slate-700 resp-text--2 resp-px-2 resp-py-1 rounded-full font-digi"
                  >
                    close
                  </span>
                </div>
                */}
                {finresp.ps.map((p, i) => {
                  return (
                    <div class="fr-ss resp-gap-4">
                      {p.msgtype == "stale" ? (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-slate-400"
                        />
                      ) : p.msgtype == "loading" ? (
                        <Loader01c size="s" />
                      ) : p.msgtype == "error" ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="text-red-500"
                        />
                      ) : p.msgtype == "action" ? (
                        <FontAwesomeIcon
                          icon={faTriangleCircleSquare}
                          className="text-yellow-500"
                        />
                      ) : p.msgtype == "success" ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500"
                        />
                      ) : null}
                      <div class="fc-ss resp-gap-1">
                        <p class="text-white font-digi resp-text--1">
                          {p.head}
                        </p>
                        <p
                          class={twMerge(
                            "text-white font-digi resp-text--2 min-h-1",
                            p.msgtype == "error"
                              ? "text-red-400"
                              : p.msgtype == "success"
                                ? "text-green-400"
                                : p.msgtype == "info"
                                  ? "text-gray-400"
                                  : p.msgtype == "loading"
                                    ? "text-blue-400"
                                    : p.msgtype == "action"
                                      ? "text-yellow-400"
                                      : null,
                          )}
                        >
                          {p.msg}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {finresp.done == true && finresp.success == true && (
                  <Link
                    to={polytxnidlink(getv(finresp, "result.hash", ""))}
                    target="_blank"
                    className="w-full"
                  >
                    <div class="fr-cc resp-gap-4 resp-p-1 border border-purple-400 w-full bg-slate-900 rounded-md my-2">
                      <div class="xs:w-[1.5rem] lg:w-[2rem]">
                        <Img img={polychainimg} />
                      </div>
                      <div class="fc-ss resp-gap-2">
                        <p class="text-purple-400 font-digi">Txn Successful</p>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </LCTokenSwapContext.Provider>
    </>
  );
};

export const LCTokenSwapPage = () => {
  return (
    <div className="h-page">
      <div class="w-[60rem] mx-auto max-w-[95vw]">
        <div class="h-[3rem]"></div>
        <LCBurnedMsg />
        <div class="h-[1rem]"></div>
        <p class="text-center font-digi my-2">Swap LC / WETH </p>
        <LCTokenSwapWidget />
        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
