import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { useRaceContext } from "./RacePage.js";
import {
  dec,
  from_time_mini,
  getv,
  nils,
  to_time_mini,
} from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faLink,
  faLock,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { q_race } from "../queries/queries.js";
import { useQueries } from "react-query";
import _ from "lodash";
import { get_race_rtstatus, RoundsInfo } from "../utils/raceutils.js";
import { useNowContext } from "../App.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { class_text } from "../utils/cn_map.js";
import { useAppContext, tokdecn } from "../App.js";
export const RoundRacesContext = createContext();
export const useRoundRacesContext = () => useContext(RoundRacesContext);

export const RoundRaces = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;

  const rcon = useRaceContext();
  const { race: r0, hsob, ethusd } = rcon;
  const [qorace] = useQueries([
    q_race(
      { rid: r0.rid },
      {
        staleTime: 1e3 * 60,
        refetchInterval: 1e3 * 60,
      },
    ),
  ]);
  const race = useMemo(() => {
    let r = getv(qorace, "data.result");
    if (nils(r)) return r0;
    r.fee_usd = r.fee * ethusd;
    r.prize_usd = r.prize * ethusd;
    return r;
  }, [qorace.dataUpdatedAt, ethusd]);
  const r = race;

  const rounds_n = getv(r, "format_inf.rounds_n");
  const round_curr = getv(r, "format_inf.round_curr");

  const roucon = {
    race,
    qorace,
    hsob,
    round_curr,
    rounds_n,
  };
  return (
    <RoundRacesContext.Provider value={roucon}>
      <div className="max-w-[98vw] w-[50rem] mx-auto">
        <Card className={twMerge("bg-r2lig/20 card-grad-border w-full")}>
          <div className="font-digi fr-cc resp-gap-2 text-white resp-text-1">
            <span>{race.race_name}</span>
            <span className="resp-text--2 text-white">
              {` ${_.upperCase(race.format)} Races`}
            </span>
          </div>
          <div className="fr-sc resp-gap-2">
            <Tag className="font-digi resp-text-0 text-white">
              CB{race.cb}00
            </Tag>
            <Tag
              className={twMerge(
                "font-digi resp-text-0 transform -skew-x-12",
                "bg-acc1",
              )}
            >
              {class_text(race.class, "T")}
            </Tag>
            <div className="flex-1"></div>

            <div className="fc-cc">
              {race?.format == "rounds" ? (
                <span className="text-r2lig">Finals PrizePool</span>
              ) : race.format == "roundsp" ? (
                <span className="text-r2lig">Rounds+ PrizePool</span>
              ) : race.format == "bounty" ? (
                <span className="text-yellow-300">Bounty PrizePool</span>
              ) : null}
              <div className="text-white font-digi resp-text-1 fr-sc resp-gap-1">
                <FontAwesomeIcon icon={faUsd} />
                <span>{dec(tok_to_usd_val(race.prize, race.paytoken), 2)}</span>
              </div>
            </div>
          </div>
          <div className="fr-sc relative">
            <div className="flex-1"></div>
            <div className="my-2">
              <RoundsInfo
                race={r}
                popupclassName="xs:top-[0rem] xs:right-[0%] md:top-[0rem] md:right-[5%] card-grad-border"
                tagcn="bg-r2dark/80 card-grad-border4 rounded-lg text-white"
              />
            </div>
          </div>
        </Card>

        {[...Array(rounds_n)].map((e, i) => {
          let round_i = i + 1;
          let round = getv(r, `format_inf.rounds.r${round_i}`);
          return <Round {...{ key: round_i, round_i, round }} />;
        })}
      </div>
    </RoundRacesContext.Provider>
  );
};

const Round = ({ round_i, round }) => {
  const roucon = useRoundRacesContext();
  const { rounds_n, round_curr } = roucon;
  const subrounds_n = _.keys(round).length;

  const [expand, set_expand] = useState(round_curr == round_i);

  const round_name = useMemo(() => {
    return (
      (rounds_n - round_i == 0 && "FINALE") ||
      (rounds_n - round_i == 1 && "Semi-Finals") ||
      `Round-${round_i}`
    );
  }, [rounds_n, round_i]);

  if (_.isEmpty(round)) {
    return (
      <>
        <Card className={twMerge("bg-r2lig/20 card-grad-border w-full")}>
          <div className="fr-sc text-slate-500">
            <Tag className={twMerge("")}>
              <FontAwesomeIcon icon={faLock} />
            </Tag>

            <p className="font-digi resp-text--1">{round_name}</p>
            <div className="flex-1"></div>
          </div>
        </Card>
      </>
    );
  }

  return (
    <Card className={twMerge("bg-r2lig/20 card-grad-border w-full")}>
      <div
        onClick={() => {
          set_expand(!expand);
        }}
        className="fr-sc cursor-pointer"
      >
        <p className="font-digi resp-text-0">{round_name}</p>
        <div className="flex-1"></div>
        <Tag
          onClick={() => {}}
          className={twMerge(
            "transition duration-300",
            expand ? " transform rotate-180" : "",
          )}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Tag>
      </div>

      <motion.div
        initial={expand ? "show" : "hide"}
        animate={expand ? "show" : "hide"}
        variants={MoVariants.show_hide}
      >
        {expand && (
          <>
            {_.entries(round).map(([ri_alp, sr]) => {
              return <SubRound {...{ round_i, key: ri_alp, ri_alp, sr }} />;
            })}
          </>
        )}
      </motion.div>
    </Card>
  );
};

const SubRound = ({ round_i, ri_alp, sr }) => {
  const roucon = useRoundRacesContext();
  const { now } = useNowContext();
  const { rounds_n, hsob, race } = roucon;
  useEffect(() => {
    // console.log(round_i, ri_alp, sr);
  }, [sr]);

  const [rtstatus, rem_st, rem_ed] = useMemo(() => {
    return get_race_rtstatus(sr, now);
  }, [sr, now]);

  // useMemo(() => { console.log(round_i, ri_alp, rtstatus); }, [rtstatus]);

  const tagcn = "text-[12px] m-0 text-center";

  const in_n = sr.hids_in?.length;
  const gen_won_txt = (hid) => {
    // console.log("gen_won_txt", hid);
    let hwon = rtstatus == "finished" && (sr.hids_out || []).includes(hid);
    if (!hwon) return null;
    let rconf = getv(race, `r_form.rounds_conf.${round_i}`);
    let adv_points = getv(rconf, "adv_points");
    // console.log("gen_won_txt", hid, hwon, adv_points);
    if (hwon) {
      if (adv_points > 0) return `WON!! +${dec(adv_points, 2)}pts`;
      else return `WON!!`;
    }
    return null;
  };

  return (
    <Card className={"bg-r2dark/80 resp-my-2 mx-0 w-full resp-px-3 resp-py-2"}>
      <div className="fr-cc resp-my-1">
        <Link to={`/race/${sr.rid}`} target="_self">
          <div className="font-digi fr-sc resp-text-0 resp-gap-2">
            <span>{round_i !== rounds_n ? `Set-${ri_alp}` : ""}</span>
            <FontAwesomeIcon icon={faLink} className="text-acc0" />
          </div>
        </Link>
        <div className="flex-1"></div>
        <div className="fc-cc resp-gap-1">
          {rtstatus == "scheduled" && (
            <>
              <span>{"Starting in "}</span>
              <span>{to_time_mini(sr.start_time, true)}</span>
            </>
          )}
          {rtstatus == "live" && (
            <Tag className={twMerge(tagcn, "bg-yellow-400 text-black")}>
              LIVE
            </Tag>
          )}
          {rtstatus == "ending" && (
            <Tag className={twMerge(tagcn, "text-purple-400")}>Ending...</Tag>
          )}
          {rtstatus == "finished" && (
            <>
              <span className={twMerge("resp-text-0 text-red-400")}>
                Finished
              </span>
            </>
          )}
        </div>
      </div>
      <div
        className={twMerge(
          in_n == 2
            ? "flex flex-row justify-center items-stretch resp-my-1"
            : "grid grid-cols-2 resp-gap-2",
        )}
      >
        {sr.hids_in.map((hid, i) => {
          let h = hsob[hid] || {};
          let hwon = gen_won_txt(hid);
          if (in_n == 2) {
            return (
              <>
                <div
                  key={hid}
                  className={twMerge(
                    "flex flex-col justify-end",
                    "resp-gap-2 resp-px-4 resp-py-2 rounded-full",
                    `w-[15rem] text-center`,
                    ``,
                  )}
                >
                  <div className="fc-cc resp-gap-1 resp-text-0">
                    <span twMerge={hwon ? "text-white" : ""}>{h.name}</span>
                    <span className="text-green-300 font-digi resp-text--2">
                      {hwon}
                    </span>
                  </div>
                  <span
                    className={`w-full h-1 rounded-sm bg-${h.color}`}
                  ></span>
                </div>
                {i + 1 !== sr.hids_in.length && (
                  <>
                    <div className="font-mono text-yellow-300 italic fr-cc w-[3rem]">
                      VS
                    </div>
                  </>
                )}
              </>
            );
          } else {
            return (
              <>
                <div
                  className={twMerge(
                    "fr-sc resp-gap-2 w-full resp-p-2",
                    hwon ? "bg-acc1/40 shadow-sm shadow-acc1" : "",
                  )}
                >
                  <span
                    className={`h-3 w-[5px] rounded-sm bg-${h.color}`}
                  ></span>
                  <div className="flex-1 resp-text-0">
                    <span className="resp-text--1 text-acc4">{h.hid} </span>
                    <span></span>
                    <span>{h.name}</span>
                  </div>
                  {hwon && (
                    <span className="text-green-300 font-digi resp-text--2">
                      {hwon}
                    </span>
                  )}
                </div>
              </>
            );
          }

          {
            rtstatus == "finished" && <></>;
          }
        })}
      </div>
    </Card>
  );
};
