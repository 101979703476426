const o = {
  enterArena: "function enterArena(uint coreId, uint price_usd) public",
  applySkin: "function applySkin(uint256 skinid, uint256 hid) public",
  releaseSkin: "function releaseSkin(uint256 skinid) public",
  getTokenInfo:
    "function getTokenInfo(uint256 tokenId) public view returns (Skin memory skin, address _owner)",
  getTokenOwner:
    "function getTokenOwner(uint256 tokenId) public view returns (address)",
  burn: "function burn(uint256 tokenId) public",
};

export default o;
