import { getv, iso, jparse, jstr, nano, nils, tofeth } from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abi.json";

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  // address public in_token;
  async in_token() {
    return await this.contract.in_token();
  }
  // address public out_token;
  async out_token() {
    return await this.contract.out_token();
  }
  // uint public conv_rate;
  async conv_rate() {
    return await this.contract.conv_rate();
  }

  // function convert(uint _amount) public {
  async convert(amount) {
    let resp = await this.contract.convert(amount);
    // resp = await resp.wait();
    return resp;
  }

  // function destroy() public onlyContractOwner
  async destroy() {
    let resp = await this.contract.destroy();
    resp = await resp.wait();
    return resp;
  }
}

const chain_mode = "main";
const k = "dnatokenconverter";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({
    contractAddress: get_contract_address(),
    provider,
    signer,
    ...ext,
  });
  return runner;
};

export const DNA_TokenConverter = {
  k,
  get_contract,
  get_contract_address,
};
