const o = {
  enterArena: "function enterArena(uint coreId, uint price_usd) public",
  getSpliceInfo:
    "function getSpliceInfo(uint _docid) public view returns (Splice memory)",
  spliceSetPrice:
    "function spliceSetPrice( uint _docid, uint _father_fee, uint _mother_fee, uint _dna_fee, uint _tot_fee) public onlyOwner",
  splicePayUp: "function splicePayUp(uint _docid) public",
  spliceRequest:
    "function spliceRequest(uint _father_coreid, uint _mother_coreid) public",
};

export default o;
