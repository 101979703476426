import { useMemo, useState } from "react";
import { useAppContext } from "../App";
import { getv, iso, nils } from "../utils/utils";
import { useAuthContext } from "../wrappers/AuthWrapper";
import _ from "lodash";
import {
  get_race_from_mini,
  RaceRow_Rounds_side,
  RaceRow_side,
} from "../utils/raceutils";
import { Tag } from "../components/utilityComps";
import {
  faChevronDown,
  faChevronUp,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { Loader01c } from "../components/anims";

export const VaultRacesWindow = ({ qo_vaultraces, onRaceClick = () => {} }) => {
  const accon = useAccountContext();
  const aucon = useAuthContext();
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;
  const { s_acc_config, g_acc_config } = accon;

  const racesob = useMemo(() => {
    let now = iso();
    let rs = getv(qo_vaultraces, "data.result");
    rs = _.chain(rs)
      .map((r) => {
        // console.log(r.format, r.fee, r.paytoken);
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
        return r;
      })
      .value();

    if (nils(rs))
      return {
        open: [],
        scheduled: [],
        finished: [],
      };

    rs = (rs || []).map((r) => {
      let r2 = get_race_from_mini([
        r.rid,
        r.mini,
        r.format,
        r.format_inf,
        r.race_name,
      ]);
      // if (nils(r2?.cb)) console.log(r, r2);
      r = { ...r2, ...r };
      return r;
    });
    rs = _.groupBy(rs, "status");

    if (nils(rs.open)) rs.open = [];
    if (nils(rs.scheduled)) rs.scheduled = [];
    if (nils(rs.finished)) rs.finished = [];

    return rs;
  }, [qo_vaultraces.dataUpdatedAt]);

  const headcn = "resp-text-0 font-digi text-acc4";

  const [statusshow, set_statusshow] = useState(
    g_acc_config("leftsidebar.statusshow", {
      open: true,
      scheduled: true,
      finished: true,
    }),
  );
  useEffect(() => {
    s_acc_config("leftsidebar.statusshow", statusshow);
  }, [statusshow]);

  if (aucon.auth !== true) return <></>;
  return (
    <>
      {[
        "open",
        "scheduled",
        // "rounds",
        "finished",
      ].map((status) => {
        let rs = racesob[status];
        let act = statusshow[status];

        return (
          <>
            <div className="fr-sc w-full">
              <div className="flex-1">
                <Tag className={twMerge(headcn)}>
                  {_.capitalize(status)} Races
                </Tag>
              </div>
              {qo_vaultraces.isLoading ? <Loader01c size="s" /> : <></>}

              <Tag
                onClick={() => {
                  set_statusshow({ ...statusshow, [status]: !act });
                }}
                className={twMerge(
                  "resp-p-2 fr-cc transition duration-300 fr-sc xs:rounded-full lg:rounded-full",
                  act ? "" : "bg-acc4/30",
                )}
              >
                {!act && `show #${rs.length} races`}
                <FontAwesomeIcon icon={!act ? faChevronDown : faChevronUp} />
              </Tag>
            </div>
            <div className="h-[1rem]"></div>
            {}
            {qo_vaultraces.isSuccess && act && (
              <div>
                {_.isEmpty(rs) ? (
                  <p className="text-center">No Races</p>
                ) : (
                  rs.map((race) => {
                    return (
                      <div class="w-full" onClick={onRaceClick}>
                        {status == "rounds" ? (
                          <RaceRow_Rounds_side {...{ race, key: race.rid }} />
                        ) : (
                          <RaceRow_side {...{ race, key: race.rid }} />
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            )}
            {/* <motion.div
            variants={MoVariants.show_hide}
            animate={statusshow[status] ? "visible" : "hidden"}
            initial={statusshow[status] ? "visible" : "hidden"}
          > */}
            {/*
             */}
            {/* </motion.div> */}
          </>
        );
      })}
    </>
  );
};
