import { faArrowDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useMemo } from "react";
import { Img, InpText } from "../components/utilityComps";
import { cdelay, dec, jstr, nils, toeth, tofeth } from "../utils/utils";
import { TokenIcon } from "../components/utilityComps";
import { extract_inp, set_val_inp } from "../components/input";
import { twMerge } from "tailwind-merge";
import colors from "tailwindcss/colors";
import { Loader01c } from "../components/anims";
import { polychainimg } from "../utils/links";
import { Link } from "react-router-dom";
import { polytxnlink } from "../queries/queries";
import { mm_asset_signer } from "../contracts/contract_funcs";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { DNA_TokenConverter } from "../contracts/DNA_TokenConverter/DNA_TokenConverter";
import _ from "lodash";

export const TokenConverter = () => {
  const { vault } = useAuthContext();
  const [insymbolk, outsymbolk] = ["dez", "bgc"];
  const [insymbol, outsymbol] = ["DEZ", "BGC"];

  const [inbal, set_inbal] = useState(0);
  const [outbal, set_outbal] = useState(0);
  const [convrate, set_convrate] = useState(0);

  const get_bal = async (sym, set) => {
    try {
      let con = await mm_asset_signer(sym);
      let resp = await con.balanceOf(vault);
      resp = tofeth(resp);
      set(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const get_convrate = async () => {
    try {
      let con = await DNA_TokenConverter.get_contract();
      let resp = await con.conv_rate();
      console.log("conv", resp);
      resp = Number(resp) / 1e9;
      set_convrate(resp);
    } catch (err) {}
  };

  useEffect(() => {
    setTimeout(() => {
      get_convrate();
    }, 3000);
    setTimeout(() => {
      get_bal(insymbolk, set_inbal);
    }, 2000);
    setTimeout(() => {
      get_bal(outsymbolk, set_outbal);
    }, 3000);
  }, []);

  const [doc, set_doc] = useState({});

  const convert_to = () => {
    let inamt = extract_inp("inp-in-amt");
    inamt = parseFloat(inamt);
    let outamt = inamt * convrate;
    if (nils(parseFloat(outamt))) outamt = "";
    else outamt = dec(outamt, 4);
    set_doc({ ...doc, outamt });
  };
  const convert_from = () => {
    let outamt = extract_inp("inp-out-amt");
    outamt = parseFloat(outamt);
    let inamt = outamt / convrate;
    if (nils(parseFloat(inamt))) inamt = "";
    else inamt = dec(inamt, 0);
    set_doc({ ...doc, inamt });
  };
  const txtcn = (v) => {
    v = parseFloat(v);
    if (nils(v) || v == 0) return {};
    // console.log(v, inbal, v > inbal);
    if (v > inbal) return { color: colors.red[400] };
    else return {};
  };
  const isvalid = () => {
    if (nils(parseFloat(doc.inamt))) return [false, ""];
    if (parseFloat(doc.inamt) > inbal)
      return [false, "exceeds existing balance"];
    if (parseFloat(doc.inamt) < 10)
      return [false, "please convert more than 10 DEZ"];
    return [true, null];
  };
  const [valid, errmsg] = useMemo(() => isvalid(), [jstr(doc)]);

  const [resp, set_resp] = useState(null);
  const start_swap = async () => {
    try {
      set_resp({ loading: true, msg: "fetching balance" });
      await cdelay(3000);
      let concon = await DNA_TokenConverter.get_contract();
      let incon = await mm_asset_signer(insymbolk);
      // let outcon = await mm_asset_signer(outsymbolk);

      let bal = await incon.balanceOf(vault);
      bal = tofeth(bal);

      let inamt = parseFloat(doc.inamt);
      if (inamt > bal) throw new Error("Insufficient Balance");

      let alw = await incon.allowance(vault, concon.contractAddress);
      alw = tofeth(alw);
      if (alw < inamt) {
        set_resp({
          loading: true,
          msg: "Please confirm Metamask for allowance limit",
        });
        let resp = await incon.approve(concon.contractAddress, toeth(inamt));

        set_resp({
          loading: true,
          msg: "updating allowance...",
        });

        await resp.wait();

        set_resp({
          loading: true,
          msg: "allowance updated",
        });
        await cdelay(2000);
      }

      set_resp({ loading: true, msg: "Confirm Metamask to get BGC" });
      let resp = await concon.convert(toeth(inamt));
      set_resp({ loading: true, msg: "converting to BGC..." });
      resp = await resp.wait();
      console.log(resp);

      set_val_inp("inp-in-amt", "");
      set_val_inp("inp-out-amt", "");
      set_doc({});

      setTimeout(() => {
        get_bal(insymbolk, set_inbal);
        get_bal(outsymbolk, set_outbal);
      }, 3000);

      set_resp({
        loading: false,
        msg: "Transaction Successful",
        polyhash: resp.hash,
      });
    } catch (err) {
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      if (errmsg.length > 100) errmsg = errmsg.slice(0, 50) + "...";
      set_resp({ loading: false, err: errmsg });
    }
  };

  return (
    <div className="resp-text--1">
      <p class="text-center resp-text-1 text-acc0 font-digi my-2">
        Buy BGC with DEZ
      </p>

      <div class="fc-ss w-[30rem] mx-auto resp-gap-1 p-2 border border-acc0/10 rounded-md">
        <div class="w-full bg-reg p-2 rounded-md">
          <span className="p-1 my-2">You Pay</span>
          <div class="m-1 p-2 rounded-sm bg-dark">
            <div class="fr-sc w-full">
              <InpText
                {...{
                  id: "inp-in-amt",
                  placeholder: "0",
                  def_val: doc.inamt ?? "",
                  className: "font-digi",
                  autoComplete: "off",
                  contprops: { className: "w-full border border-transparent" },
                  inpprops: {
                    className: twMerge("font-digi"),
                    style: txtcn(doc.inamt),
                  },
                  setter: (v) => {
                    if (v.startsWith(".")) v = `0${v}`;
                    set_doc({ ...doc, inamt: v });
                  },
                  onChange: () => {
                    setTimeout(convert_to, 200);
                  },
                }}
              />
              <TokenIcon token={insymbol} />
              <span class="resp-text-1 font-digi text-acc0">{insymbol}</span>
            </div>
          </div>
          <div class="fr-sc resp-gap-2 cursor-pointer">
            <div
              onClick={() => {
                get_bal(insymbolk, set_inbal);
              }}
              class="flex-1"
            ></div>
            <span>Current Balance</span>
            <span className="text-acc0">{dec(inbal, 1)}</span>
          </div>
        </div>
        <div class="h-0 relative w-full">
          <div class="absolute w-max border-[0.3rem] border-dark mx-auto bg-reg p-2 rounded-md top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            {convrate > 0 ? (
              <FontAwesomeIcon icon={faArrowDown} className="resp-text-1" />
            ) : (
              <Loader01c size="s" />
            )}
          </div>
        </div>
        <div class="w-full bg-reg p-2 rounded-md">
          <span className="p-1 my-2">You Get</span>
          <div class="m-1 p-2 rounded-sm bg-dark">
            <div class="fr-sc w-full">
              <InpText
                {...{
                  id: "inp-out-amt",
                  placeholder: "0",
                  def_val: doc.outamt ?? "",
                  className: "font-digi",
                  contprops: { className: "w-full border border-transparent" },
                  inpprops: { className: "font-digi" },
                  autoComplete: "off",
                  onChange: () => {
                    setTimeout(convert_from, 200);
                  },
                }}
              />
              <TokenIcon token={outsymbol} />
              <span class="resp-text-1 font-digi text-acc0">{outsymbol}</span>
            </div>
          </div>
          <div
            onClick={() => {
              get_bal(outsymbolk, set_outbal);
            }}
            class="fr-sc resp-gap-2 cursor-pointer"
          >
            <div class="flex-1"></div>
            <span>Current Balance</span>
            <span className="text-acc0">{dec(outbal, 2)}</span>
          </div>
        </div>
        {resp?.loading ? null : valid ? (
          <div
            onClick={() => {
              start_swap();
            }}
            className="w-full cursor-pointer resp-text--1 bg-acc0/40 rounded-md resp-p-2 text-center"
          >
            Buy Now
          </div>
        ) : (
          <p className="text-red-400 resp-text--2 w-full font-digi text-center">
            {errmsg}
          </p>
        )}
        {!_.isEmpty(resp) && (
          <div className="fc-cc resp-gap-1 w-full relative">
            <div class="fr-sc w-full h-0 absolute">
              <div class="flex-1"></div>
              <div
                onClick={() => {
                  set_resp(null);
                }}
                className="resp-text-1 text-red-500 p-1 cursor-pointer"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>
            <div className="resp-text--1 fr-cc w-full resp-gap-2 ">
              {resp?.loading && <Loader01c size="s" />}
              {!nils(resp.msg) && (
                <p className="text-acc0 font-digi">{resp.msg}</p>
              )}
              {!nils(resp.err) && (
                <p className="text-red-400 font-digi">{resp.err}</p>
              )}
            </div>
            {resp.polyhash && (
              <Link
                target="_blank"
                to={polytxnlink(resp.polyhash)}
                className="w-full"
              >
                <div class="fr-cc resp-gap-1 w-full text-purple-400">
                  <div class="xs:w-[1rem] lg:w-[1.5rem]">
                    <Img img={polychainimg} />
                  </div>
                  <p>See Transaction on PolygonScan</p>
                </div>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const TokenConverterPage = () => {
  return (
    <div class="h-page">
      <div class="w-[50rem] max-w-[95vw] mx-auto">
        <div class="h-[3rem]"></div>
        <TokenConverter />
        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
