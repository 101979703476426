import { useThirdWebLoginContext } from "./ThirdWebLogin";
import {
  getContract,
  getAccount,
  prepareTransaction,
  sendTransaction,
  prepareContractCall,
  waitForReceipt,
} from "thirdweb";
import { polygon } from "thirdweb/chains";
import { asset_types_map, t3_contract_call } from "../contracts/contract_funcs";
import { Tag } from "../components/utilityComps";
import { toeth, tofeth } from "../utils/utils";
import { ethers } from "ethers";
import { useState } from "react";

export const TestPage = () => {
  let uris = [
    "run.dnaracing.factionaction://dnaracing.us.auth0.com/android/run.dnaracing.factionaction/callback",
    "run.dnaracing.factionaction://callback",
  ];

  const tcon = useThirdWebLoginContext();
  const { thirdweb_client, thirdweb_clientid, active_wallet, active_account } =
    tcon;

  const [respdata, set_respdata] = useState({
    test: "data",
  });

  const run_fn1 = async () => {
    console.log("run_fn");
    let c = asset_types_map["dez"];
    console.log("c", c);
    let contract = await getContract({
      address: c.address,
      chain: polygon,
      client: thirdweb_client,
    });
    console.log("contract", contract);

    // method:
    //   "function balanceOf(address account) external view returns (uint256)",
    // params: [active_account.address],
    // let bal = await contract.function.balanceOf(active_account.address);
    // console.log("bal", bal);
    //
    const txn = await prepareContractCall({
      contract,
      method:
        "function transfer(address to, uint256 amount) external returns (bool)",
      params: ["0xaf1320faa9a484a4702ec16ffec18260cc42c3c2", toeth(1)],
    });
    console.log("txn", txn);

    const response = await sendTransaction({
      account: active_account,
      transaction: txn,
    });
    console.log("response", response);
    let recipt = await waitForReceipt({
      client: thirdweb_client,
      transactionHash: response.transactionHash,
      chain: polygon,
    });
    console.log("recipt", recipt);
  };

  const run_fn2 = async () => {
    console.log("run_fn2");
    let c = asset_types_map["dez"];
    let provider = new ethers.JsonRpcProvider(polygon.rpc);
    let con2 = new ethers.Contract(
      c.address,
      [
        "function balanceOf(address account) external view returns (uint256)",
        "function transfer(address to, uint256 amount) external returns (bool)",
      ],
      provider,
    );
    console.log("con2", con2);
    // let resp = await con2.balanceOf(active_account.address);
    let resp = await con2.transfer(active_account.address, toeth(1));
    console.log("resp", resp);
    return;
  };

  const run_fn3 = async () => {
    console.log("run_fn3");
    let resp = await t3_contract_call(
      "dez",
      "balanceOf",
      [active_account.address],
      "data",
      true,
      {},
    );
    resp = { balance: tofeth(resp) };
    console.log("run_fn3", resp);
    return resp;
  };

  const run_fn4 = async () => {
    console.log("run_fn4");
    let resp = await t3_contract_call(
      "dez",
      "transfer",
      ["0xaf1320faa9a484a4702ec16ffec18260cc42c3c2", toeth(1)],
      "txn",
      true,
      { active_account },
    );
    console.log("run_fn4", resp);
    return {
      hash: resp.hash,
    };
  };

  const run = async (k, fn, params = []) => {
    try {
      set_respdata({
        loading: true,
        k,
        params,
      });
      let resp = await fn(...params);
      set_respdata({
        k,
        status: "done",
        result: resp,
      });
    } catch (err) {
      console.log(err);
      set_respdata({
        k,
        status: "error",
        error: err,
      });
    }
  };

  return (
    <div class="">
      <div class="h-[3rem]"></div>
      <div class="fc-cc resp-gap-2 resp-text-1">
        <p class="text-center">Test Page</p>
        <div class="fc-cc">
          <Tag
            className="bg-acc4/30 -skew-x-12 px-2 py-1 rounded-full"
            onClick={() => {
              // run_fn1();
            }}
          >
            RUN Test Fn 1
          </Tag>

          <Tag
            className="bg-acc4/30 -skew-x-12 px-2 py-1 rounded-full"
            onClick={() => {
              // run_fn2();
            }}
          >
            RUN Test Fn 2
          </Tag>

          <Tag
            className="bg-acc4/30 -skew-x-12 px-2 py-1 rounded-full"
            onClick={() => {
              // run_fn3();
              run("get_dez_balance", run_fn3, []);
            }}
          >
            RUN Test Fn 3: wrapper: get dez balance
          </Tag>

          <Tag
            className="bg-acc4/30 -skew-x-12 px-2 py-1 rounded-full"
            onClick={() => {
              // run_fn4();
              run("transfer_1_dez", run_fn4, []);
            }}
          >
            RUN Test Fn 4: wrapper: transfer_1_dez
          </Tag>
        </div>
        <div class="w-[50rem] mx-auto p-4 overflow-auto resp-text--1 rounded-md border border-r2lig bg-r2dark/60">
          <p class="whitespace-pre-wrap font-monorounded-md">
            {JSON.stringify(respdata, null, 2)}
          </p>
        </div>
      </div>
    </div>
  );
};
