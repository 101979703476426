import _ from "lodash";

const factions_helper = {};

export const gen_facimg = (f) =>
  `https://dna-run-public.s3.us-east-2.amazonaws.com/factions-logos/${f}.png`;
factions_helper.gen_facimg = gen_facimg;

export const facmap = _.chain([
  "ordinem",
  "project-paragon",
  "root-prime",
  "the-divine-wind",
])
  .map((k) => {
    let v = _.chain(k).split("-").map(_.capitalize).join(" ").value();
    let img = gen_facimg(k);
    return [k, { name: v, img }];
  })
  .fromPairs()
  .value();
factions_helper.facmap = facmap;

export default factions_helper;
