import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Center, Html, Text, useAnimations, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";
import { forwardRef } from "react";
import _ from "lodash";
import { degToRad } from "three/src/math/MathUtils.js";
import { getv, iso, jstr, nils } from "../utils/utils.js";
import { useModelsStore } from "./ModelsStore.js";
import { useFrame } from "@react-three/fiber";
import useGLTF_cloned from "./useGLTF_cloned";
import { Trail } from "./Trails.js";

const textypes = {
  b: "BaseColor",
  d: "Displacement",
  e: "Emission",
  m: "Metallic",
  n: "Normal",
  r: "Roughness",
};
const textypes_to_matprop = {
  b: "map",
  d: "displacementMap",
  e: "emissiveMap",
  m: "metalnessMap",
  n: "normalMap",
  r: "roughnessMap",
};
const useTextureForSkinMaterials = (skin, texmats, basetexpath) => {
  const textureLoader = new THREE.TextureLoader();
  const resp = useMemo(() => {
    if (skin == "base")
      return {
        loaded: `${skin}-${iso()}`,
        textures: {},
      };
    let textures = {};
    for (let [matkey, matlink, textypekeys] of texmats) {
      textures[matkey] = {};
      textypekeys = textypekeys.split(",");
      for (let k of textypekeys) {
        let texkey = textypes[k];
        let texpath = `${basetexpath}/${matkey}_${texkey}.png`;
        let tex = textureLoader.load(texpath);
        // console.log(">", matkey, texkey, k);
        tex.flipY = false;
        // tex.encoding = THREE.sRGBEncoding;
        tex.colorSpace = THREE.SRGBColorSpace;
        textures[matkey][texkey] = tex;
      }
    }
    return {
      loaded: `${skin}-${iso()}`,
      textures,
    };
  }, [skin, jstr(texmats)]);

  useEffect(() => {
    // Cleanup function to dispose of textures
    /*
    return () => {
      for (const matkey in resp.textures) {
        for (const texkey in resp.textures[matkey]) {
          let tex = resp.textures[matkey][texkey];
          if (tex) tex.dispose();
        }
      }
    };
    */
  }, [resp]);

  return resp;
};

const gets_skin_textures_baseurl = (skin) => {
  const baseURL =
    "https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/";
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}/textures`;
  return newImageURL;
};

const useSkinMaterials = ({ minit, skin, neon, skinct }) => {
  const basetexpath = useMemo(() => gets_skin_textures_baseurl(skin), [skin]);

  // const basetexpath = useMemo(() => `/models/skinned/${skin}`, [skin]);

  const texmats = [
    ["main_body_black", "main_body_black", "b,m,n,r,e", {}],
    ["galss_uper", "galss_uper", "b,m,n,r,e", { emissiveIntensity: 0 }],
    [
      "tyre_MT",
      "tyre_MT",
      "b,m,n,r,e",
      { emissiveIntensity: 0, transparent: true, opacity: 0 },
    ],
    [
      "glow_blue",
      "glow_blue",
      "b,e",
      {
        emissive: new THREE.Color("#ffffff"),
        emissivIntesity: 60,
      },
    ],
  ];
  const texob = useTextureForSkinMaterials(skin, texmats, basetexpath);

  const neon_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m.color = new THREE.Color(neon);
    m.emissive = new THREE.Color(neon);
    m.emissiveIntensity = 0.5;
    return m;
  }, [neon, skin]);

  const white_glow_mat = useMemo(() => {
    let m = new MeshStandardMaterial();
    m.color = new THREE.Color("#ffffff");
    m.emissive = new THREE.Color("#ffffff");
    m.emissiveIntensity = 10;
    return m;
  }, [neon, skin]);

  const m2 = useMemo(() => {
    if (_.isEmpty(minit)) return {};

    const textures = texob.textures;
    // console.log("> textures", textures);
    const mskin = {};

    matcycle: for (let [matkey, matmodkey, textypekeys, extrapars] of texmats) {
      let m = new THREE.MeshStandardMaterial();

      if (skin !== "base") {
        if (_.isEmpty(textures[matkey]) || _.isEmpty(minit[matkey])) {
          mskin[matmodkey] = m;
          // m.needsUpdate = true;
          continue;
        }

        textypekeys = textypekeys.split(",") || [];
        teximagescycle: for (let k of textypekeys) {
          let texkey = textypes[k];
          let tex = textures[matkey][texkey];
          let matprop = textypes_to_matprop[k];
          if (tex) m[matprop] = tex;
          if (skinct[matprop] === false) m[matprop] = null;
        }
      } else {
        if (!_.isEmpty(minit[matkey])) m = m.copy(minit[matkey]);
      }

      if (!_.isEmpty(extrapars) && m) {
        for (let [mk, mv] of _.entries(extrapars)) {
          m[mk] = _.cloneDeep(mv);
        }
      }
      m.needsUpdate = true;
      mskin[matkey] = m;
    }
    // console.log("skin", skin);
    if (skin == "base" || nils(skin)) {
      mskin["glow_blue"] = neon_mat;
      mskin["glow_blue.001"] = neon_mat;
    } else {
      mskin["glow_blue"] = white_glow_mat;
      mskin["glow_blue.001"] = white_glow_mat;
      let usek = "main_body_black";
      let m_skin = new THREE.MeshStandardMaterial({});
      m_skin = m_skin.copy(minit["main_body_black.001"]);
      m_skin.map = textures[usek].BaseColor;
      m_skin.normalMap = textures[usek].Normal;
      m_skin.metalnessMap = textures[usek].Metallic;
      m_skin.roughnessMap = textures[usek].Roughness;
      mskin["main_body_black.001"] = m_skin;

      // new THREE.MeshStandardMaterial({
      //   map: textures[usek].BaseColor,
      //   metalnessMap: textures[usek].Metallic,
      //   normalMap: textures[usek].Normal,
      //   roughnessMap: textures[usek].Roughness,
      // });
    }
    let m2 = { ...minit, ...mskin };
    // console.log(">", m2);
    return m2;
  }, [texob.loaded, neon, jstr(skinct), _.keys(minit).join(",")]);

  return { ...m2, neon_mat, white_glow_mat };
};

const BaseBike = ({ nodes, materials }) => {
  return (
    <group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalBody002.geometry}
        material={materials["main_body_black.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalBody002_1.geometry}
        material={materials.glow_blue}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalBody003.geometry}
        material={materials["main_body_black.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalBody003_1.geometry}
        material={materials.glow_blue}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_tyre_1.geometry}
        material={materials["tyre_MT.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.led1.geometry}
        material={materials["glow_blue.001"]}
        position={[0, 0.02, 0.034]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.led2.geometry}
        material={materials["glow_blue.001"]}
        position={[0, 0.02, -0.044]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.polySurface4_1.geometry}
        material={materials.glow_blue}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalBody_1.geometry}
        material={materials["main_body_black.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalFrontTyre_1.geometry}
        material={materials["tyre_MT.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TronbikeFinalglass_1.geometry}
        material={materials["galss_uper.001"]}
      />
    </group>
  );
};

export default forwardRef(function TronBike(
  {
    bike,
    curranim,
    modelcentered = false,
    overrideskin,
    overridetrail,
    ...props
  },
  ref,
) {
  const group = useRef();
  const models_store = useModelsStore();

  const model = models_store.get_model("tronbike");
  const clonedmodel = useGLTF_cloned(model);
  const { nodes, materials: materials_init, animations = [] } = clonedmodel;

  const { hex_code, hid, name } = bike;
  const neon = useMemo(() => {
    return `#${hex_code}`;
  }, [hex_code]);

  const prevanim = useRef(null);

  const anims = useAnimations(animations, group);
  const actions = anims.actions;
  const update_anim = (
    curr,
    crossfadetime = 0.5,
    crossfade = true,
    fromtime = 0,
  ) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      actions[curr].time = fromtime;
      if (crossfade && prev && actions[prev]) {
        actions[prev].crossFadeTo(actions[curr], crossfadetime);
        actions[curr].play();
      } else {
        action.play();
      }
      prevanim.current = curr;
    }
  };
  const update_anim2 = (curr, fromtime = 0) => {
    let prev = prevanim.current;
    let action = actions[curr];
    if (action) {
      action.time = fromtime;
      action.play();
      prevanim.current = curr;
    }
  };
  useEffect(() => {
    if (group.current) {
      group.current.update_anim = update_anim;
      group.current.update_anim2 = update_anim2;
      group.current.prevanim = prevanim;
    }
  }, [group]);

  React.useImperativeHandle(ref, () => group.current);

  const neon_material = useRef();
  const blackneon_material = useRef();

  const skinob = overrideskin || getv(bike, `skin`) || null;
  const skin = getv(skinob, "name");

  const trail = overridetrail || getv(bike, `trail`) || null;
  const trailColor = nils(skinob) ? neon : getv(skinob, "acc");

  const materials = useSkinMaterials({
    minit: materials_init,
    skin: skin || "base",
    neon,
    skinct: {},
  });

  const showname = useRef(false);
  const lightRef1 = useRef();

  if (_.isEmpty(model))
    return (
      <>
        <mesh>
          <boxGeometry args={[0.2, 0.2, 0.2]} />
        </mesh>
      </>
    );

  return (
    <group
      // ref={ref}
      ref={group}
      {...props}
      dispose={null}
    >
      <mesh
        castShadow={true}
        receiveShadow={true}
        rotation={[degToRad(90), 0, degToRad(90)]}
        position={[-0.8, 0.05, 0]}
      >
        <planeGeometry args={[4, 4]} />
        <meshStandardMaterial
          transparent={true}
          opacity={0}
          color={"#000000"}
          side={THREE.DoubleSide}
        />
      </mesh>

      <pointLight
        ref={lightRef1}
        color={neon}
        position={[-0.7, 0.1, 0]}
        intensity={15}
        distance={0.5}
        decay={0.9}
      />
      {showname.current == true ? (
        <Text rotation-y={degToRad(90)} position-y={0.8} fontSize={0.18}>
          {bike.name}
        </Text>
      ) : (
        <></>
      )}

      <group
        scale={10}
        rotation-y={Math.PI / 2}
        {...(modelcentered ? {} : { "position-x": -0.8 })}
      >
        <BaseBike nodes={nodes} materials={materials} />
      </group>
      <group rotation-y={degToRad(180)} position={[-0.8, -0.15, 0]}>
        <Trail
          {...{
            trail,
            show: true,
          }}
        />
      </group>
    </group>
  );
});
