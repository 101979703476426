import _ from "lodash";
import {
  cb_cn,
  class_cn,
  class_text,
  elementmap,
  gendermap,
} from "../utils/cn_map.js";
import { Tag } from "./utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";
import { useMemo } from "react";
import { nils } from "../utils/utils.js";
import { Helmet } from "react-helmet-async";

export const MiniElementTag = ({ element, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12 resp-text--4",
        elementmap[element]?.text,
        className,
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
    </Tag>
  );
};

export const ElementTag = ({ element, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12 resp-text--4",
        elementmap[element]?.bg,
        className,
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
      <span className="font-digi">{_.upperCase(element)}</span>
    </Tag>
  );
};

export const GenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        gendermap[gender]?.bg,
        "resp-text--2",
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
      <span className="font-digi">{_.upperCase(gender)}</span>
    </Tag>
  );
};

export const MiniGenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1",
        gendermap[gender]?.text,
        "resp-text-1",
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
    </Tag>
  );
};

export const TypeTag = ({ type, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-acc0/40",
        "resp-text--2",
        className,
      )}
    >
      <span className="font-digi">{_.upperCase(type)}</span>
    </Tag>
  );
};

export const FNoTag = ({ fno, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-transparent",
        className,
      )}
    >
      <span className="font-digi resp-text-1 text-acc0">
        F{_.upperCase(fno)}
      </span>
    </Tag>
  );
};

export const ClassTag = ({ c, className, format = "t" }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        class_cn(c),
        className,
      )}
    >
      <span className="font-digi">{class_text(c, format)}</span>
    </Tag>
  );
};

export const CBTag = ({ cb, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        cb_cn(cb),
        className,
      )}
    >
      <span className="font-digi">{`CB${cb}00M`}</span>
    </Tag>
  );
};

export const PageWrapper = ({
  page_title_fn = null,
  children = null,
  cont_cn,
}) => {
  const pagetitle = useMemo(() => {
    if (nils(page_title_fn)) return `FBike | DNA Racing`;
    else return `${page_title_fn()} | FBike | DNA Racing`;
  }, [page_title_fn]);

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div class="h-page">
        <div class="xs:h-[2rem] lg:h-[4rem]"></div>
        <div className={twMerge("max-w-[98vw] w-[60rem] mx-auto", cont_cn)}>
          {children}
        </div>
        <div class="h-[4rem]"></div>
      </div>
    </>
  );
};
