import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../constants.js";
import { get_gasinfo } from "../contract_funcs.js";

class RaceStakeContract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async enterRace(raceId, hid, extinfo) {
    try {
      let gasinfo = await get_gasinfo();
      const tx = await this.contract.enterRace(raceId, hid, gasinfo);
      // console.log("racestake:v01: enter race", tx);
      return tx;
    } catch (err) {
      // console.log("err ar racestake:v01", err);
      return null;
    }
  }
}

const get_RaceStakeContract = async () => {
  const contractAddress = contractAddress_list.racestake;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new RaceStakeContract({ contractAddress, provider, signer });
  return runner;
};

const RaceStake = {
  class: RaceStakeContract,
  get_contract: get_RaceStakeContract,
};

export default RaceStake;
