import _ from "lodash";
import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from "react";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { useParams } from "react-router";
import { twJoin, twMerge } from "tailwind-merge";
import { tokdecn, useAppContext } from "../App";
import { Card, Img, InpText, Tag, TokenIcon } from "../components/utilityComps";
import {
  polytxnidlink,
  qiserr,
  qissuccesss,
  q_genesis_hids,
  q_genesis_rewards,
  q_godowns,
  q_staking_dez_stake_info,
  q_staking_get_dez_staked,
  q_staking_transactions,
  q_vaultinfo,
  q_vaultbikes,
  useStepQuery,
  q_claim_reward,
  q_vault_reward,
  q_que_txn,
  q_staking_pending_transactions,
} from "../queries/queries";
import {
  cdelay,
  dec,
  getv,
  iso_format,
  jstr,
  nils,
  per_n,
  toeth,
  tofeth,
} from "../utils/utils";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { Loader01c } from "../components/anims";
import { mm_asset_signer } from "../contracts/contract_funcs";
import { Staking_v1 } from "../contracts/Staking_v1/Staking_v1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faRefresh,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { tablecn } from "../utils/cn_map";
import { polychainimg } from "../utils/links";
import { Link } from "react-router-dom";
import { IfInView } from "./FinishedRacesPage";
import { VaultBackLink } from "./Vault";

const StakingContext = createContext();
const useStakingContext = () => useContext(StakingContext);

const StakeDEZ = ({ qostk }) => {
  const scon = useStakingContext();
  const { vault } = scon;

  const [doc, set_doc] = useState({});
  const [resp, set_resp] = useState({});

  const submit_stake_dez = async () => {
    set_resp({ loading: true });
    try {
      if (nils(doc.amt)) throw new Error("please input amt to proceed");
      if (doc.amt <= 0) throw new Error("amt must be greater than 0");
      const dezcon = await mm_asset_signer("dez");
      let con = await Staking_v1.get_contract();

      set_resp({
        loading: true,
        msg: "Fetching DEZ Balance",
      });
      let bal = await dezcon.balanceOf(vault);
      bal = parseFloat(tofeth(bal));
      if (bal < doc.amt) throw new Error("insufficient balance");
      console.log(bal);

      let alw = await dezcon.allowance(vault, con.contractAddress);
      alw = parseFloat(tofeth(alw));
      if (alw < doc.amt) {
        set_resp({
          loading: true,
          msg: "Please confirm Metamask for contract allowance",
        });
        let res = await dezcon.approve(
          con.contractAddress,
          toeth(doc.amt * 1.05),
        );
        await res.wait();

        set_resp({
          loading: true,
          msg: "Setting Contract Allowance",
        });
        await res.wait();
      }

      set_resp({ loading: true, msg: "Please Confirm Metamask to Stake DEZ" });
      let res = await con.stake(toeth(doc.amt), "DEZ");
      console.log(res);
      if (!nils(res.hash)) {
        set_resp({
          loading: true,
          msg: "Staking DEZ...",
        });
        await q_que_txn({
          service: "staking",
          hash: res.hash,
          dets: {
            action: "stakedez",
            vault: vault,
          },
        }).queryFn();
        await cdelay(3 * 1e3);
        set_resp({
          loading: false,
          msg: "Staked DEZ Successfully!! please wait a few mins for it to show up",
        });
      } else {
        set_resp({ err: "Failed to stake DEZ" });
      }

      qostk.refetch();
      // throw new Error("Not Implemented");
    } catch (err) {
      console.log(err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      set_resp({ err: errmsg });
    }
  };

  return (
    <Card className={"w-full"}>
      <p class="my-2 font-digi text-center text-acc0"> Stake DEZ </p>
      <div class="fr-sc">
        <InpText
          label="Enter Amt"
          id="stake_dez_amt"
          setter={(v) => {
            if (v.startsWith(".")) v = `0${v}`;
            if (nils(parseFloat(v))) v = null;
            v = parseFloat(v);
            set_doc({ ...doc, amt: v });
          }}
        />
      </div>
      <div class="fr-sc ">
        <div class="flex-1"></div>
        <Tag
          onClick={() => {
            // console.log("stake dez");
            submit_stake_dez();
          }}
          className="font-digi bg-acc0/40 -skew-x-12"
        >
          <span>Stake $DEZ In</span>
        </Tag>
      </div>
      {!nils(resp) && (
        <p
          class={twMerge(
            "fr-sc resp-gap-1 rounded-md m-1 p-1 resp-text--2",
            !nils(resp.msg) && "text-green-400 border border-green-400",
            !nils(resp.err) && "text-red-400 border border-red-400",
          )}
        >
          {resp.loading == true && <Loader01c size="s" />}
          {!nils(resp.msg) && <span>{resp.msg}</span>}
          {!nils(resp.err) && <span>{resp.err}</span>}
        </p>
      )}
    </Card>
  );
};
const UnStakeDEZ = ({ qostk }) => {
  const scon = useStakingContext();
  const { vault } = scon;

  const [doc, set_doc] = useState({});
  const [resp, set_resp] = useState({});

  const stk = useMemo(() => getv(qostk, "data.result"), [qostk.dataUpdatedAt]);

  const submit_stake_dez = async () => {
    set_resp({ loading: true });
    try {
      if (nils(doc.amt)) throw new Error("please input amt to proceed");
      if (doc.amt <= 0) throw new Error("amt must be greater than 0");
      // const dezcon = await mm_asset_signer("dez");
      let con = await Staking_v1.get_contract();

      set_resp({
        loading: true,
        msg: "Please Confirm Metamask to UnStake DEZ",
      });
      let res = await con.unstake(toeth(doc.amt), "DEZ");
      console.log(res);
      if (!nils(res.hash)) {
        set_resp({
          loading: true,
          msg: "UnStaking DEZ...",
        });
        await q_que_txn({
          service: "unstaking",
          hash: res.hash,
          dets: {
            action: "stakedez",
            vault: vault,
          },
        }).queryFn();
        await cdelay(3 * 1e3);
        set_resp({
          loading: false,
          msg: "Staked DEZ Successfully!! please wait a few mins for it to show up",
        });
      } else {
        set_resp({ err: "Failed to stake DEZ" });
      }

      qostk.refetch();

      // throw new Error("Not Implemented");
    } catch (err) {
      console.log(err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      set_resp({ err: errmsg });
    }
  };

  return (
    <Card className={"w-full"}>
      <p class="my-2 font-digi text-center text-orange-400">UnStake DEZ </p>
      <div class="fr-sc">
        <InpText
          label="Enter Amt"
          id="stake_dez_amt"
          def_val={nils(doc.amt) ? "" : doc.amt}
          setter={(v) => {
            if (v.startsWith(".")) v = `0${v}`;
            if (nils(parseFloat(v))) v = null;
            v = parseFloat(v);
            set_doc({ ...doc, amt: v });
          }}
        />
        <div
          onClick={() => {
            let max = dec(stk?.staked, 3);
            max = parseFloat(max) - 0.001;
            if (nils(max)) return;
            set_doc({ ...doc, amt: max });
          }}
          class="resp-p-2 text-acc0 italic resp-text--1 cursor-pointer"
        >
          Use Max
        </div>
      </div>
      <div class="fr-sc ">
        <div class="flex-1"></div>
        <Tag
          onClick={() => {
            // console.log("stake dez");
            submit_stake_dez();
          }}
          className="font-digi bg-orange-500/40 -skew-x-12"
        >
          <span>UnStake $DEZ In</span>
        </Tag>
      </div>
      {!nils(resp) && (
        <p
          class={twMerge(
            "rounded-md fr-sc resp-gap-2 m-1 p-1 resp-text--2",
            !nils(resp.msg) && "text-green-400 border border-green-400",
            !nils(resp.err) && "text-red-400 border border-red-400",
          )}
        >
          {resp.loading == true && <Loader01c size="s" />}
          {!nils(resp.msg) && <span>{resp.msg}</span>}
          {!nils(resp.err) && <span>{resp.err}</span>}
        </p>
      )}
    </Card>
  );
};

const CurrentStakedSection = ({ qostk, qosti }) => {
  const stk = useMemo(() => getv(qostk, "data.result"), [qostk.dataUpdatedAt]);
  const sti = useMemo(() => getv(qosti, "data.result"), [qosti.dataUpdatedAt]);
  const token = "DEZ";

  return (
    <Card className={twMerge("w-full resp-text--1 fc-cc resp-gap-2")}>
      <div class="fr-sc w-full">
        <div class="flex-1"></div>
        <Tag
          onClick={qostk.refetch}
          className={twMerge(qostk.isLoading ? "spin-anim" : "")}
        >
          <FontAwesomeIcon icon={faRefresh} />
        </Tag>
      </div>

      <p className="flex flex-row justify-end items-end resp-gap-2 font-digi">
        <span>Staked:</span>
        <span className="resp-text-1">{dec(getv(stk, "staked", 0), 0)}</span>
        <span>{token}</span>
      </p>

      {/*
      <p className="flex flex-row justify-end items-end resp-gap-2 font-digi">
        <span>In Use:</span>
        <span className="resp-text-1">
          {dec(getv(stk, "stakeinuse", 0), 0)}
        </span>
        <span>{token}</span>
      </p>
      */}

      <p className="flex flex-row justify-end items-end resp-gap-2 font-digi">
        <span>%Pool Aquired</span>
        <span className="resp-text-1">
          {parseFloat(
            per_n(getv(stk, "staked", 0), getv(sti, "total_staked", 0)) * 100,
            6,
          ).toString()}
          %
        </span>
        <span>{token}</span>
      </p>

      <p class="w-full text-right resp-text--1 text-orange-400">
        Staked DEZ is used to payout profits only after{" "}
        {Math.abs(getv(sti, "threshdur.0"))} {getv(sti, "threshdur.1")} of
        staking
      </p>
    </Card>
  );
};

const StakingDezSection = () => {
  const scon = useStakingContext();
  const { vault, txns, qo_txns } = scon;

  // const [doc, set_doc] = useState({});
  const [qosti, qostk] = useQueries([
    q_staking_dez_stake_info(),
    q_staking_get_dez_staked({ vault }),
  ]);

  const comm_props = { qostk, qosti };
  const filt_txns = useMemo(() => {
    let ts = _.filter(txns, (t) => ["staked", "unstaked"].includes(t.type));
    console.log("stakign ts", ts);
    return ts;
  }, [jstr(txns)]);

  return (
    <div className="grid grid-cols-2 gap-2 my-4">
      <div class="col-span-2">
        <CurrentStakedSection {...{ ...comm_props }} />
      </div>

      <StakeDEZ {...{ ...comm_props }} />
      <UnStakeDEZ {...{ ...comm_props }} />
      <div class="col-span-2">
        <Card
          className={twMerge("w-full resp-text--1  overflow-auto h-[30rem]")}
        >
          <TransactionTable txns={filt_txns} />
          <IfInView
            runViewFn={!qo_txns.isFetching}
            onView={qo_txns.fetchNextPage}
          />
        </Card>
      </div>
    </div>
  );
};

const VaultRewardCard = ({ v: h = {} }) => {
  const { q } = h;
  const rew = useMemo(
    () => getv(q, "data.result.rewards") || {},
    [q.dataUpdatedAt],
  );
  const [resp, set_resp] = useState({});
  const [doc, set_doc] = useState({});

  const { vault: auth_vault, auth } = useAuthContext();
  const isau = !nils(auth_vault) && auth === true && h?.vault == auth_vault;

  const init_claim = async () => {
    try {
      set_resp({ loading: true });
      await cdelay(100);
      // console.log("init_claim", doc);
      if (nils(doc?.amt)) throw new Error("please input amt to proceed");
      if (doc.amt <= 0) throw new Error("amt must be greater than 0");
      let mx = rew[doc.token];
      if (mx < doc.amt) throw new Error("amt is greater than available");

      set_resp({
        loading: true,
        msg: "Claiming Reward...",
      });
      let r = await q_claim_reward({
        vault: h.vault,
        action: "vault_claim",
        amt: doc.amt,
        token: doc.token,
      }).queryFn();
      if (r.err) throw new Error(r.err);

      set_resp({
        loading: false,
        msg: "success!! should update in a few minutes",
      });
      set_doc({});
      setTimeout(q.refetch, 60 * 1e3);
    } catch (err) {
      console.log("claimerr", h.hid, doc, err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      set_resp({ err: errmsg });
    }
  };

  return (
    <>
      <Card className={twMerge("w-full resp-text--2")}>
        <div class="fr-sc font-digi resp-text--1 my-1">
          <span>{h.vault_name}</span>
          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              q.refetch();
            }}
            className={twMerge(
              q.isLoading ? "spin-anim" : "",
              "text-orange-400",
            )}
          >
            <FontAwesomeIcon icon={faRefresh} />
          </Tag>
        </div>
        {nils(q) ? null : q.isLoading ? (
          <Loader01c size="s" />
        ) : qiserr(q) ? (
          <p class="text-red-400">{qiserr(q)}</p>
        ) : qissuccesss(q) ? (
          <div class="grid grid-cols-3 resp-gap-2">
            {[
              ["WETH", 6, "bg-purple-400/40", "text-purple-400"],
              ["DEZ", 1, "bg-teal-400/40", "text-teal-400"],
              ["LC", 2, "bg-orange-400/40", "text-orange-400"],
            ].map(([token, decn, bcn, tcn]) => {
              let r = rew[token];
              let iszero = nils(r) || r == 0;
              let disptxt = iszero ? "--" : parseFloat(r).toString();
              return (
                <div class="fc-cc resp-gap-1">
                  <div class="fr-cc">
                    <TokenIcon token={token} />
                    <span className={twMerge(tcn)}>{token}</span>
                  </div>
                  <span
                    className={twMerge(tcn, "font-digi")}
                  >{`${disptxt}`}</span>
                  {isau == true ? (
                    <>
                      {iszero ||
                      doc?.init == true ||
                      doc.loading == true ? null : (
                        <Tag
                          onClick={() => {
                            set_doc({ init: true, token });
                          }}
                          className={twMerge(`${bcn} text-white`)}
                        >
                          Claim
                        </Tag>
                      )}
                    </>
                  ) : null}
                </div>
              );
            })}
            <div class="col-span-2">
              {doc?.init == true && (
                <div class="fr-sc w-full">
                  <div class="flex-1">
                    <InpText
                      {...{
                        id: `v-claim-amt-${h.vault}`,
                        def_val: getv(doc, "amt") || "",
                        autoComplete: "off",
                        contprops: { className: "w-full" },
                        inpprops: { className: "w-full" },
                        // label: `Enter Amt in ${doc.token} `,
                        placeholder: `Enter Amt in ${doc.token} `,
                        setter: (v) => {
                          if (v.startsWith(".")) v = `0${v}`;
                          if (nils(parseFloat(v))) v = null;
                          else v = parseFloat(v).toString();
                          set_doc({ ...doc, amt: v });
                        },
                      }}
                    />
                  </div>
                  {resp.loading ? (
                    <Loader01c size="s" />
                  ) : (
                    <>
                      <Tag
                        onClick={() => {
                          set_doc({ ...doc, amt: rew[doc.token] });
                        }}
                        className="text-acc0"
                      >
                        max
                      </Tag>
                      <Tag
                        onClick={() => {
                          init_claim();
                        }}
                        className="bg-acc0/40 -skew-x-12"
                      >
                        Claim
                      </Tag>

                      <Tag
                        onClick={() => {
                          set_doc({});
                        }}
                        className="bg-transparent text-red-400"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </Tag>
                    </>
                  )}
                </div>
              )}
              <div class="p-1">
                {!nils(resp?.err) ? (
                  <p class="text-left text-red-300">{resp.err}</p>
                ) : !nils(resp?.msg) ? (
                  <p class="text-left text-green-300">{resp.msg}</p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Card>
    </>
  );
};
const GodAllocationSection = () => {
  const scon = useStakingContext();
  const { vaultinfo: vdoc, vault, auth, txns, qo_txns } = scon;
  const { ptxns, queue_n, qptxns } = scon;

  const [qo_godowns, qo_vaultreward] = useQueries([
    q_godowns({ vault }),
    q_vault_reward({ vault }),
  ]);

  const godtoks = useMemo(
    () => getv(qo_godowns, "data.result.tokenids") || [],
    [qo_godowns.dataUpdatedAt],
  );
  const v = useMemo(() => {
    let v0 = getv(qo_vaultreward, "data.result");
    return { ...vdoc, ...v0, q: qo_vaultreward };
  }, [jstr(vdoc), qo_vaultreward?.dataUpdatedAt]);

  const filt_txns = useMemo(() => {
    let ts = _.filter(txns, (t) => {
      return t.type.includes("vault");
    });
    // console.log("god_alloc ts", ts);
    return ts;
  }, [jstr(txns)]);

  const filt_ptxns = useMemo(() => {
    let ts = _.filter(ptxns, (t) => {
      return t.action == "vault_claim";
    });
    return ts;
  }, [jstr(ptxns)]);

  return (
    <>
      <div class="grid cols-2 gap-2">
        <div class="col-span-2">
          <Card className={twMerge("w-full resp-text--1 fc-cc resp-gap-2")}>
            <p class="font-digi resp-text--1">
              <span>{"God Tokens Owned: "}</span>
              <>
                {qo_godowns.isLoading ? (
                  <Loader01c size="s" />
                ) : _.isEmpty(godtoks) ? (
                  <span className=" text-yellow-300">NONE</span>
                ) : (
                  <span className="resp-text-1 text-green-300">
                    {godtoks.length}{" "}
                  </span>
                )}
              </>
            </p>
            <div class="flex flex-col justify-end items-end w-full">
              {godtoks.map((tid) => (
                <span>GOD #{tid}</span>
              ))}
            </div>
          </Card>
        </div>
        <div class="col-span-2">
          <VaultRewardCard v={v} />
        </div>
        <div class="col-span-2">
          <Card className={twMerge("w-full resp-text--1  ")}>
            <PendingTransactionTable txns={filt_ptxns} queue_n={queue_n} />
          </Card>
        </div>
        <div class="col-span-2">
          <Card
            className={twMerge("w-full resp-text--1  overflow-auto h-[30rem]")}
          >
            <TransactionTable txns={filt_txns} />
            <IfInView
              runViewFn={!qo_txns.isFetching}
              onView={qo_txns.fetchNextPage}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

const GenBikeCard = ({ h = {} }) => {
  const { q } = h;
  const rew = useMemo(
    () => getv(q, "data.result.rewards") || {},
    [q.dataUpdatedAt],
  );
  const [resp, set_resp] = useState({});
  const [doc, set_doc] = useState({});

  const { vault: auth_vault, auth } = useAuthContext();
  const isau = !nils(auth_vault) && auth === true && h?.vault == auth_vault;
  // console.log("isau", isau, h?.vault, auth_vault);

  const init_claim = async () => {
    try {
      set_resp({ loading: true });
      await cdelay(100);
      console.log("init_claim", doc);
      if (nils(doc?.amt)) throw new Error("please input amt to proceed");
      if (doc.amt <= 0) throw new Error("amt must be greater than 0");
      let mx = rew[doc.token];
      if (mx < doc.amt) throw new Error("amt is greater than available");

      set_resp({
        loading: true,
        msg: "Claiming Reward...",
      });
      let r = await q_claim_reward({
        hid: h.hid,
        action: "bike_claim",
        amt: doc.amt,
        token: doc.token,
      }).queryFn();
      if (r.err) throw new Error(r.err);

      set_resp({
        loading: false,
        msg: "success!! should update in a few minutes",
      });
      set_doc({});
      setTimeout(q.refetch, 60 * 1e3);
    } catch (err) {
      console.log("claimerr", h.hid, doc, err);
      let errmsg = !nils(err.reason) ? err.reason : err.message;
      set_resp({ err: errmsg });
    }
  };
  // useEffect(() => {
  //   if (h.hid == 10) console.log(h.hid, q.status, q.dataUpdatedAt);
  // }, [q.status, q.dataUpdatedAt]);

  return (
    <>
      <Card className={twMerge("w-full resp-text--2")}>
        <div class="fr-sc font-digi resp-text--1 my-1">
          <span>#{h.hid}</span>
          <span>-</span>
          <span>{h.name}</span>
          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              q.refetch();
            }}
            className={twMerge(
              q.isLoading ? "spin-anim" : "",
              "text-orange-400",
            )}
          >
            <FontAwesomeIcon icon={faRefresh} />
          </Tag>
        </div>
        {nils(q) ? null : q.isLoading ? (
          <Loader01c size="s" />
        ) : qiserr(q) ? (
          <p class="text-red-400">{qiserr(q)}</p>
        ) : qissuccesss(q) ? (
          <div class="grid grid-cols-3 resp-gap-2">
            {[
              ["WETH", 6, "bg-purple-400/40", "text-purple-400"],
              ["DEZ", 1, "bg-teal-400/40", "text-teal-400"],
              ["LC", 2, "bg-orange-400/40", "text-orange-400"],
            ].map(([token, decn, bcn, tcn]) => {
              let r = rew[token];
              let iszero = nils(r) || r == 0;
              let disptxt = iszero ? "--" : parseFloat(r).toString();
              if (disptxt.length > 5) disptxt = dec(parseFloat(r), 5);
              return (
                <div class="fc-cc resp-gap-1">
                  <div class="fr-cc">
                    <TokenIcon token={token} />
                    <span className={twMerge(tcn)}>{token}</span>
                  </div>
                  <span
                    className={twMerge(tcn, "font-digi")}
                  >{`${disptxt}`}</span>
                  {isau == true || 1 ? (
                    <>
                      {iszero ||
                      doc?.init == true ||
                      doc.loading == true ? null : (
                        <Tag
                          onClick={() => {
                            set_doc({ init: true, token });
                          }}
                          className={twMerge(`${bcn} text-white`)}
                        >
                          Claim
                        </Tag>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
            <div class="col-span-2">
              {doc?.init == true && (
                <div class="fr-sc w-full">
                  <div class="flex-1">
                    <InpText
                      {...{
                        id: `claim-amt-${h.hid}`,
                        def_val: getv(doc, "amt") || "",
                        autoComplete: "off",
                        contprops: { className: "w-full" },
                        inpprops: { className: "w-full" },
                        // label: `Enter Amt in ${doc.token} `,
                        placeholder: `Enter Amt in ${doc.token} `,
                        setter: (v) => {
                          if (v.startsWith(".")) v = `0${v}`;
                          if (nils(parseFloat(v))) v = null;
                          else v = parseFloat(v).toString();
                          set_doc({ ...doc, amt: v });
                        },
                      }}
                    />
                  </div>
                  {resp.loading ? (
                    <Loader01c size="s" />
                  ) : (
                    <>
                      <Tag
                        onClick={() => {
                          // set_doc({ ...doc, amt: rew[doc.token] });
                          let max = dec(rew[doc.token], 3);
                          max = parseFloat(max) - 0.001;
                          if (nils(max)) return;
                          set_doc({ ...doc, amt: max });
                        }}
                        className="text-acc0"
                      >
                        Use Max
                      </Tag>
                      <Tag
                        onClick={() => {
                          init_claim();
                        }}
                        className="bg-acc0/40 -skew-x-12"
                      >
                        Claim
                      </Tag>
                      <Tag
                        onClick={() => {
                          set_doc({});
                        }}
                        className="bg-transparent text-red-400"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </Tag>
                    </>
                  )}
                </div>
              )}
              <div class="p-1">
                {!nils(resp?.err) ? (
                  <p class="text-left text-red-300">{resp.err}</p>
                ) : !nils(resp?.msg) ? (
                  <p class="text-left text-green-300">{resp.msg}</p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Card>
    </>
  );
};
const GensisRewardSection = () => {
  const scon = useStakingContext();
  const { vault, vhids, auth, txns, qo_txns } = scon;
  const { ptxns, queue_n, qptxns } = scon;

  const filt_txns = useMemo(() => {
    let ts = _.filter(txns, (t) => {
      // console.log(t.id, t.type);
      return (
        t.type == "bike_reward_claimed" ||
        (t.type == "bike_reward_added" &&
          vhids.includes(getv(t, "connects.hid")))
      );
      return true;

      return false;
    });
    // console.log("gen_rew ts", ts);
    return ts;
  }, [jstr(txns)]);

  const filt_ptxns = useMemo(() => {
    let ts = _.filter(ptxns, (t) => {
      return t.action == "bike_claim";
    });
    return ts;
  }, [jstr(ptxns)]);

  const [qo_ghids] = useQueries([q_genesis_hids({ vault })]);
  const ghs = useMemo(
    () => getv(qo_ghids, "data.result") || [],
    [qo_ghids.dataUpdatedAt],
  );
  // console.log("hids", ghids);

  const qs_genrewards = useStepQuery({
    key: "genesis_rewards",
    q_: q_genesis_rewards,
    par_ar: (ghs || []).map((h) => [{ hid: h?.hid }]),
    lim: 3,
    options: { enabled: true },
  });
  const genhs = useMemo(() => {
    let hs = [];
    for (let i = 0; i < ghs.length; i++) {
      let q = qs_genrewards.qs[i];
      let h = getv(q, "data.result") ?? {};
      let h0 = ghs[i];
      h = { ...h, ...h0, q };
      // console.log("h", h);
      hs.push(h);
    }
    return hs;
  }, [
    jstr(ghs),
    jstr(_.map(qs_genrewards.qs, "dataUpdatedAt")),
    jstr(_.map(qs_genrewards.qs, "status")),
  ]);

  return (
    <>
      <div class="grid grid-cols-2 resp-gap-2">
        <div class="col-span-2">
          <p class="text-center">Genesis Bike Reward Claims</p>
          <div class="w-full grid grid-cols-2 resp-gap-2">
            {genhs.map((h) => {
              return <GenBikeCard {...{ key: h.hid, h }} />;
            })}
          </div>
        </div>
        <div class="col-span-2">
          <hr className="max-w-[40rem] mx-auto my-2" />
        </div>
        <div class="col-span-2">
          <Card className={twMerge("w-full resp-text--1  ")}>
            <PendingTransactionTable txns={filt_ptxns} queue_n={queue_n} />
          </Card>
        </div>
        <div class="col-span-2">
          <Card
            className={twMerge("w-full resp-text--1  overflow-auto h-[30rem]")}
          >
            <TransactionTable txns={filt_txns} />
            <IfInView
              runViewFn={!qo_txns.isFetching}
              onView={qo_txns.fetchNextPage}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export const StakingSection = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const accon = useAuthContext();
  const { vault: auth_vault, auth } = accon;
  const [tab, set_tab] = useState(psearch?.tab || "dez_staking");
  const params = useParams();
  const vault = useMemo(() => {
    if (nils(params.vault)) return null;
    else return params.vault.toLowerCase();
  }, [jstr(params)]);

  const isau = auth === true && vault == auth_vault;

  const [qo_vau, qo_hids] = useQueries([
    q_vaultinfo({ vault }),
    q_vaultbikes({ vault }),
  ]);
  const vaultinfo = useMemo(
    () => getv(qo_vau, "data.result"),
    [qo_vau.dataUpdatedAt],
  );
  const vhids = useMemo(
    () => getv(qo_hids, "data.result") || [],
    [qo_hids.dataUpdatedAt],
  );

  // const [qo_txns] = useQueries([q_staking_transactions({ vault })]);
  const qo_txns = useInfiniteQuery({
    queryKey: ["staking_txns", vault],
    queryFn: (pars) => {
      // console.log("queryFn", pars.pageParam);
      if (getv(pars, "pageParam.page") == -1) return null;
      return q_staking_transactions({ vault, ...pars.pageParam }).queryFn();
    },
    getNextPageParam: (lastPage, allPages) => {
      let page = getv(lastPage, "result.next_page");
      return { page };
    },
    refetchInterval: 2 * 60 * 1e3,
  });
  const txns = useMemo(() => {
    let ts = _.chain(qo_txns.data?.pages)
      .filter((e) => getv(e, "status") == "success")
      .map((e) => getv(e, "result.txns"))
      .flatten()
      .uniqBy((e) => e.id ?? e.txkey)
      .value();
    // console.log("txns", ts);
    return ts;
  }, [qo_txns.dataUpdatedAt]);

  const [qptxns] = useQueries([
    q_staking_pending_transactions(
      { vault },
      {
        staleTime: 20 * 1e3,
        refetchInterval: 20 * 1e3,
      },
    ),
  ]);
  const [ptxns, queue_n] = useMemo(
    () =>
      [getv(qptxns, "data.result.txns"), getv(qptxns, "data.result.queue_n")] ||
      [],
    [qptxns.dataUpdatedAt],
  );

  useEffect(() => {
    let rem = { tab };
    upd_psearch(rem);
  }, [tab]);

  // if (!auth) return <></>;
  const scon = {
    auth_vault,
    vault,
    vhids,
    isau,

    qo_vau,
    vaultinfo,

    qo_txns,
    txns,

    qptxns,
    ptxns,
    queue_n,
  };

  return (
    <div className="p-2">
      <StakingContext.Provider value={scon}>
        <div class="fc-cc font-digi">
          <span className="resp-text--2 text-acc0">{vault}</span>
          <span className="resp-text--1">{vaultinfo?.vault_name}</span>
        </div>
        <hr class="my-2 mx-auto w-[40rem]" />
        <div class="fr-cc">
          {["dez_staking", "god_allocations", "genesis_rewards"].map((_tab) => {
            let active = tab === _tab;
            return (
              <Tag
                onClick={() => set_tab(_tab)}
                className={twMerge(
                  "font-digi text-center",
                  active ? "-skew-x-12 text-acc0" : "text-white",
                )}
              >
                <span className={"resp-text--1"}>
                  {_.chain(_tab).split("_").map(_.capitalize).join(" ").value()}
                </span>
              </Tag>
            );
          })}
        </div>
        <hr class="my-2 mx-auto w-[40rem]" />

        {tab == "dez_staking" && <StakingDezSection />}
        {tab == "god_allocations" && <GodAllocationSection />}
        {tab == "genesis_rewards" && <GensisRewardSection />}
      </StakingContext.Provider>
    </div>
  );
};

const process_args = (t) => {
  let ar = [];
  let a = getv(t, "connects.args");
  let tk = getv(t, "txkey") ?? "";
  let type = getv(t, "type");
  if (_.isString(a) && a.startsWith("[")) a = JSON.parse(a);
  else a = [a];

  if (!_.isEmpty(a)) {
    if (type == "vault_reward_added") {
      if (a[0].includes("pot")) {
        let [a1, a2] = a[0].split("-");
        ar.push(`${_.upperCase(a1)} id: ${a2}`);

        if (tk.includes("god")) {
          let [on, totn] = a[1].split("/");
          ar.push(`Godowned: ${on} / ${totn}`);
          ar.push(`God allocation: ${a[2]}%`);
        }

        if (tk.includes("dez")) {
          if (!nils(a[1])) {
            let [on, totn] = a[1].split("/");
            ar.push(`DEZ Staked[used]: ${dec(on, 1)}`);
          }
          if (!nils(a[2])) {
            ar.push(`DEZ %allocation: ${dec(a[2], 1)}`);
          }
        }
      } else if (a[0].includes("mint")) {
        let [a1, a2, a3] = a[0].split("-");
        ar.push(`Mint Allocation: '${a2} ${a3}`);
        if (tk.includes("god")) {
          let [on, totn] = a[1].split("/");
          ar.push(`Godowned: ${on} / ${totn}`);
          ar.push(`God allocation: ${a[2]}%`);
        }
      }
    } else if (type == "bike_reward_added") {
      if (!nils(a[0]) && a[0].includes("pot")) {
        let [a1, a2] = a[0].split("-");
        ar.push(`${_.upperCase(a1)} id: ${a2}`);
      } else if (!nils(a[0]) && a[0].includes("mint")) {
        let [a1, a2, a3] = a[0].split("-");
        ar.push(`Mint Allocation: '${a2} ${a3}`);
      }
      if (!nils(a[1])) {
        ar.push(`F${a[1]}`);
      }
      // if (a[0].includes("pot")) {
      //   let [a1, a2] = a[0].split("-");
      //   ar.push(`Jackpot: ${a1} id: ${a2}`);
      // }
    } else {
      ar = a;
    }
  }

  return ar;
};

export const TransactionTable = ({ txns = [] }) => {
  if (_.isEmpty(txns))
    return <p class="text-center text-yellow-400">No Transactions</p>;
  const amtcn = (type) => {
    return nils(type)
      ? ""
      : type.includes("payout")
        ? "text-green-300"
        : type.includes("claimed")
          ? "text-yellow-300"
          : type.includes("added")
            ? "text-green-300"
            : type == "staked"
              ? "text-purple-300"
              : type == "unstaked"
                ? "text-red-300"
                : "text-white";
  };
  return (
    <table
      className={twMerge(tablecn.table, "w-max mx-auto thintdrowp4-table")}
    >
      <tbody>
        {txns.map((t, i) => {
          return (
            <tr className="thintdrowp4">
              <td>
                {!nils(getv(t, "id")) && (
                  <Link to={polytxnidlink(getv(t, "id"))}>
                    <div className="xs:w-[1rem] lg:w-[2rem] ">
                      <Img img={polychainimg} />
                    </div>
                  </Link>
                )}
              </td>
              <td>
                <div class="fc-ss">
                  <span
                    className={twMerge("font-digi resp-text--1", amtcn(t.type))}
                  >
                    {_.chain(t.type)
                      .split("_")
                      .map(_.capitalize)
                      .join(" ")
                      .value()}
                  </span>
                  {t.type.includes("bike_reward") && (
                    <span>bike #{getv(t, "connects.hid")}</span>
                  )}
                </div>
              </td>
              <td>
                <div class={twMerge("fr-sc", amtcn(t.type))}>
                  <div class="flex-1"></div>
                  <span class="font-digi resp-text--3">
                    {/*
                    {dec(
                      getv(t, "connects.amt"),
                      tokdecn(getv(t, "connects.token")),
                    )}
                   */}
                    {getv(t, "connects.amt").toString()}
                  </span>
                  <TokenIcon token={getv(t, "connects.token")} size="xs" />
                </div>
              </td>
              <td>
                <div class="fc-ss resp-gap-1">
                  {process_args(t).map((a) => {
                    return <p>{a}</p>;
                  })}
                </div>
              </td>
              <td>
                <span>{iso_format(t.date)}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const PendingTransactionTable = ({ txns = [], queue_n = 0 }) => {
  return (
    <>
      {_.isEmpty(txns) ? (
        <div class="fc-ss">
          <p class="w-full text-center text-yellow-400">
            No Pending Transactions
          </p>
          <div class="w-full text-right">current queue: {queue_n}</div>
        </div>
      ) : (
        <table className={twMerge(tablecn.table, "w-full thintdrowp4-table")}>
          <tbody>
            {txns.map((t, i) => {
              return (
                <tr className="thintdrowp4">
                  <td>
                    <Loader01c size="s" />
                  </td>
                  <td>
                    <div class="fc-ss">
                      <span className="font-digi resp-text--1 text-blue-300 uppercase">
                        {t.action}
                      </span>

                      {t.action.includes("bike_claim") && (
                        <span>bike #{getv(t, "hid")}</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div class={twMerge("fr-sc", "text-blue-300")}>
                      <div class="flex-1"></div>
                      <span class="font-digi resp-text--3">
                        {/*
                    {dec(
                      getv(t, "connects.amt"),
                      tokdecn(getv(t, "connects.token")),
                    )}
                    */}
                        {getv(t, "amt").toString()}
                      </span>
                      <TokenIcon token={getv(t, "token")} size="xs" />
                    </div>
                  </td>
                  <td>
                    <div class="fc-ss resp-gap-1">
                      {process_args(t).map((a) => {
                        return <p>{a}</p>;
                      })}
                    </div>
                  </td>
                  <td>
                    <span>{iso_format(t.date)}</span>
                  </td>
                  <td>
                    <div class="fr-sc resp-gap-2 resp-text-0">
                      <span>{t.idx}</span>
                      <span>/</span>
                      <span>{queue_n}</span>
                      <span>in queue</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {queue_n > 50 ? (
        <div class="text-yellow-300 text-right fr-sc resp-gap-2 w-full">
          <div class="flex-1"></div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span>Claims might be delayed due to congession</span>
        </div>
      ) : null}
    </>
  );
};

export const StakingPage = () => {
  return (
    <div className="h-page">
      <div class="max-w-[98vw] w-[70rem] mx-auto">
        <div class="h-[3rem]"></div>
        <VaultBackLink />
        <p class="text-acc0 text-center font-digi resp-text-2">Staking</p>
        <StakingSection />
        <div class="h-[5rem]"></div>
      </div>
    </div>
  );
};
