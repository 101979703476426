const abi = require("./abi.json");
const ethers = require("ethers");
const _ = require("lodash");
const { contractAddress_list } = require("../constants.js");
const { nils } = require("../../utils/utils.js");

const getgasinfo = () => ({});

class SplicingContract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async enterArena(coreId, price_usd, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log(coreId, "enterArena nonce:", info.nonce);
    const tx = await this.contract.enterArena(coreId, price_usd, info);
    const resp = await tx.wait();
    return resp;
  }

  async removeCores(coreIds, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("removeCores nonce:", info.nonce);
    const tx = await this.contract.removeCores(coreIds, info);
    const resp = await tx.wait();
    return resp;
  }

  async coreExists(coreId) {
    return await this.contract.core_exists(coreId);
  }

  async spliceRequest(father_coreid, mother_coreid, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.spliceRequest(
      father_coreid,
      mother_coreid,
      info,
    );
    const resp = await tx.wait();
    return resp;
  }

  async setFundsWallet(wallet, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setFundsWallet(wallet, info);
    return tx;
  }

  async getFundsWallet() {
    const tx = await this.contract.fundsWallet();
    return tx;
  }

  async withdrawFunds(toAddress, amount, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("withdrawFunds nonce:", info.nonce);
    const tx = await this.contract.withdrawFunds(toAddress, amount, info);
    return tx;
  }

  async getEnteredCore(coreId) {
    let core = await this.contract.getEnteredCore(coreId);
    core = {
      coreid: core[0],
      vault: core[1]?.toLowerCase(),
      price_usd: parseFloat(core[2]),
    };
    return core;
  }

  async spliceRequest(father_coreid, mother_coreid, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("spliceRequest nonce:", info.nonce);
    const tx = await this.contract.spliceRequest(
      father_coreid,
      mother_coreid,
      info,
    );
    const resp = await tx.wait();
    return resp;
  }

  async spliceSetPrice(
    docid,
    father_fee,
    mother_fee,
    dna_fee,
    tot_fee,
    extinfo = {},
  ) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("spliceSetPrice nonce:", info.nonce);
    const tx = await this.contract.spliceSetPrice(
      docid,
      father_fee,
      mother_fee,
      dna_fee,
      tot_fee,
      info,
    );
    const resp = await tx.wait();
    return resp;
  }

  async splicePayUp(docid, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("splicePayUp nonce:", info.nonce);
    const tx = await this.contract.splicePayUp(docid, info);
    const resp = await tx.wait();
    return resp;
  }

  async getSpliceInfo(docid) {
    let doc = await this.contract.getSpliceInfo(docid);
    doc = {
      docid: Number(doc[0]),
      is_valid: doc[1],
      stage: Number(doc[2]),
      father_coreid: Number(doc[3]),
      mother_coreid: Number(doc[4]),
      baby_coreid: Number(doc[5]),
      father_fee: Number(doc[6]),
      mother_fee: doc[7],
      dna_fee: doc[8],
      total_fee: doc[9],
      mother_vault: doc[10]?.toLowerCase(),
      father_vault: doc[11]?.toLowerCase(),
      req_vault: doc[12]?.toLowerCase(),
    };
    return doc;
  }

  async getOwner() {
    return await this.contract.contowner();
  }

  async destroy(extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    // console.log("Destroying contract with nonce:", info.nonce);
    const tx = await this.contract.destroy(info);
    const resp = await tx.wait();
    return resp;
  }
}

const get_SplicingContract = async (ext = {}) => {
  const contractAddress = contractAddress_list.splicing;
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new SplicingContract(pars);
  return runner;
};

const Splicing = {
  class: SplicingContract,
  get_contract: get_SplicingContract,
};

module.exports = Splicing;
