import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../App";
import { InpText, Tag } from "../components/utilityComps";
import { extract_inp } from "../components/input.js";
import { dec, getv, nils } from "../utils/utils";
import {
  qissuccesss,
  q_misc_digh_stimmy,
  q_zlead_stable_dets,
} from "../queries/queries";
import { Loader01c } from "../components/anims";
import { useQueries } from "react-query";
import { useEnvironment } from "@react-three/drei";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";

export const DigHStimmyPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [vault, set_vault] = useState(
    !nils(psearch.stable) &&
      _.isString(psearch.stable) &&
      psearch.stable !== "null"
      ? psearch.stable.trim().toLowerCase()
      : null,
  );
  const [resp, set_resp] = useState({});

  const [qvau] = useQueries([
    q_zlead_stable_dets({ stable: vault }, { enabled: !nils(vault) }),
  ]);
  const v = useMemo(() => getv(qvau, "data.result"), [qvau.dataUpdatedAt]);
  useEffect(() => {
    set_resp(null);
    upd_psearch({ stable: vault });
  }, [vault]);

  const submit = async () => {
    try {
      set_resp({ loading: true, msgtype: "info", msg: "Checking eligibility" });
      let resp = await q_misc_digh_stimmy({ vault }, {}).queryFn();
      console.log("resp", resp);

      if (resp.err) throw new Error(resp.err);

      let elig = getv(resp, "result.eligible") == true;
      if (elig) {
        set_resp({
          loading: false,
          msgtype: "success",
          msg: "Success!! Vault is eligible for Digital Horse Stimmy and recorded",
        });
      } else {
        set_resp({
          loading: false,
          msgtype: "error",
          msg: "Sorry!! This vault doesnt seem to be eligible",
        });
      }
    } catch (err) {
      set_resp({
        loading: false,
        msgtype: "error",
        msg: err.message,
      });
    }
  };

  return (
    <div class="h-page">
      <div class="w-[40rem] max-w-[95vw] mx-auto">
        <div class="fc-cc w-full resp-gap-2">
          <div class="resp-text-2 font-digi my-[1rem]">
            Digital Horse Stimmy
          </div>

          <InpText
            {...{
              id: "inp_dighstimmy",
              label: "Digital Stable",
              autoComplete: "off",
              contprops: { className: "lg:w-[25rem] xs:w-full" },
              inpprops: { className: "w-full" },
              def_val: vault,
              placeholder: "Enter your ETH wallet /  Stable",
              setter: () => {
                let val = extract_inp("inp_dighstimmy");
                if (nils(val)) val = null;
                set_vault(val);
              },
            }}
          />

          {!nils(vault) ? (
            <>
              <div className="h-[0.1rem] max-w-[20rem] bg-white w-full  my-[1rem]" />
              {qvau.isLoading ? <Loader01c size="s" /> : null}
              {qissuccesss(qvau) && (
                <>
                  <div class="fr-cc">
                    <span className="resp-text-1 font-digi ">
                      {`Hello, ${v.stable_name}`}
                    </span>
                  </div>
                  {resp?.loading !== true && (
                    <Tag
                      onClick={() => {
                        submit();
                      }}
                      className="resp-text-1 bg-acc0/40 -skew-x-12 "
                    >
                      Submit for Stimmy
                    </Tag>
                  )}
                </>
              )}

              {resp?.loading ? <Loader01c /> : null}
              {_.isEmpty(resp) ? null : resp?.msgtype == "error" ? (
                <>
                  {}
                  <FontAwesomeIcon
                    className={twMerge(
                      "text-[2rem]",
                      resp.msg.includes("recorded")
                        ? "text-yellow-200"
                        : "text-red-300",
                    )}
                    icon={
                      resp.msg.includes("recorded")
                        ? faTriangleExclamation
                        : faTimesCircle
                    }
                  />
                  <p
                    class={twMerge(
                      "text-red-200 resp-text--1 font-digi",
                      resp.msg.includes("recorded")
                        ? "text-yellow-200"
                        : "text-red-300",
                    )}
                  >
                    {resp?.msg}
                  </p>
                </>
              ) : resp?.msgtype == "success" ? (
                <>
                  <FontAwesomeIcon
                    className="text-[2rem] text-green-300"
                    icon={faCheckCircle}
                  />
                  <p class="text-green-300 resp-text--1 font-digi">
                    {resp?.msg}
                  </p>
                </>
              ) : resp?.msgtype == "info" ? (
                <p class="text-acc4 resp-text-1 font-digi">{resp?.msg}</p>
              ) : null}
            </>
          ) : (
            <>
              <p class="resp-text--1 font-digi my-2">
                {"check if you are eligible by entering your wallet"}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
