import React, { createContext, useContext, useMemo, useState } from "react";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import { extract_inp } from "../components/input.js";
import { twMerge } from "tailwind-merge";
import { cdelay, gen_hash, getv, iso, jstr, nils } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import skinsmap from "../data/skinsmap.json";
import _, { set } from "lodash";
import { SkinCard, TrailCard } from "../components/CardComps.js";
import { q_arcade_enter } from "../queries/queries.js";
import { Link } from "react-router-dom";
import { Footer } from "./HomePage.js";

const ArcadeRaceContext = createContext();
export const useArcadeRaceContext = () => useContext(ArcadeRaceContext);

const InpSection = () => {
  const arcon = useArcadeRaceContext();
  const { inp, set_inp, resp, submit, reset } = arcon;
  const loading = resp?.loading ?? false;

  const [moreoptions, set_moreoptions] = useState(false);

  const validate = useMemo(() => {
    if (nils(inp.name)) return false;
    return true;
  }, [jstr(inp)]);

  const [refi, set_refi] = useState(0);
  const [pick, set_pick] = useState("skin");

  const randskins = useMemo(() => {
    return _.chain(skinsmap)
      .filter((e) => e[0] !== "common")
      .shuffle()
      .take(6)
      .map((e) => {
        return { rarity: e[0], skin: e[1], acc: e[2] };
      })
      .value();
  }, [jstr(skinsmap), refi]);

  const randtrails = useMemo(() => {
    return ["cold-plasma", "rainbow", "electric"];
  }, []);

  return (
    <Card className="card-basic-bg w-full ">
      <div className="my-2">
        <p className="text-center font-digi resp-text--1">
          Customize Your Own Bike to Race Now!!
        </p>
      </div>
      <div className="fc-cc">
        <div className="">
          <InpText
            id={`arcade-inp-name`}
            def_val={inp.name}
            label="Name"
            placeholder="Enter bike name"
            contprops={{
              className: "xs:w-[90vw] lg:w-[25rem] bg-r2dark/80  resp-text--1",
            }}
            inpprops={{ className: "w-full" }}
            setter={() => {
              let v = extract_inp(`arcade-inp-name`);
              set_inp({ ...inp, name: v });
            }}
          />
        </div>
        {nils(inp.name) ? (
          <></>
        ) : !moreoptions ? (
          <div className="fr-sc w-full">
            <div className="flex-1"></div>
            <Tag
              onClick={() => set_moreoptions(true)}
              className={
                "text-r2lig italic font-digi fr-sc resp-gap-1 resp-text--1 "
              }
            >
              <span>Choose a racing skin</span>
              <FontAwesomeIcon icon={faChevronDown} />
            </Tag>
          </div>
        ) : (
          <>
            <div className="my-2 w-full">
              <div
                onClick={() => set_pick(pick == "skin" ? null : "skin")}
                className="fr-sc resp-gap-2 my-2 cursor-pointer border-b border-r2lig/40"
              >
                <p className="font-digi text-r2lig resp-text-1">Pick a Skin</p>
                {pick == "skin" && (
                  <FontAwesomeIcon
                    className="resp-p-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      set_refi(refi + 1);
                    }}
                    icon={faSyncAlt}
                  />
                )}
                <div className="flex-1">
                  {!nils(inp.skin) && (
                    <>
                      <p className="w-full text-right font-mon resp-text--2">
                        {`Picked: ${getv(inp, "skin.skin")}`}
                      </p>
                    </>
                  )}
                </div>
                <FontAwesomeIcon
                  className={twMerge(
                    "transition duration-300 resp-p-2 cursor-pointer",
                    pick == "skin" ? "rotate-180" : "",
                  )}
                  icon={faChevronDown}
                />
              </div>
              {pick == "skin" && (
                <>
                  <div className="grid xs:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 resp-gap-2">
                    {randskins.map((s, i) => {
                      let active = getv(inp, "skin.skin") === getv(s, "skin");
                      return (
                        <div
                          onClick={() => {
                            set_inp({ ...inp, skin: s });
                            set_pick("trail");
                          }}
                          className={twMerge(
                            "col-span-1 overflow-hidden border-2 cursor-pointer",
                            active ? "border-r2lig" : "border-transparent",
                          )}
                        >
                          <SkinCard
                            className="xs:w-full"
                            skinname_cn="xs:text-transparent md:text-white"
                            rarityname_cn="xs:text-transparent md:text-white"
                            key={i}
                            skin={s.skin}
                            rarity={s.rarity}
                            viewdets={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <div
                onClick={() => set_pick(pick == "trail" ? null : "trail")}
                className="fr-sc resp-gap-2 my-2 cursor-pointer border-b border-acc0/40"
              >
                <p className="font-digi text-r2lig resp-text-1">Pick a Trail</p>
                {pick == "trail" && (
                  <FontAwesomeIcon
                    className="resp-p-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      set_refi(refi + 1);
                    }}
                    icon={faSyncAlt}
                  />
                )}
                <div className="flex-1">
                  {!nils(inp.trail) && (
                    <>
                      <p className="w-full text-right font-mon resp-text--2">
                        {`Picked: ${getv(inp, "trail")}`}
                      </p>
                    </>
                  )}
                </div>
                <FontAwesomeIcon
                  className={twMerge(
                    "transition duration-300 resp-p-2 cursor-pointer",
                    pick == "trail" ? "rotate-180" : "",
                  )}
                  icon={faChevronDown}
                />
              </div>
              {pick == "trail" && (
                <>
                  <div className="grid xs:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 resp-gap-2">
                    {randtrails.map((t, i) => {
                      let active = inp.trail === t;
                      return (
                        <div
                          onClick={() => {
                            set_inp({ ...inp, trail: t });
                            set_pick(null);
                          }}
                          className={twMerge(
                            "col-span-1 overflow-hidden border-2 cursor-pointer",
                            active ? "border-acc0" : "border-transparent",
                          )}
                        >
                          <TrailCard
                            className="xs:w-full"
                            trailname_cn="xs:text-transparent md:text-white"
                            trail={t}
                            key={i}
                            viewdets={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {resp.err && (
          <p className="resp-p-2 rounded-md resp-text--2 w-full text-left border border-red-300 text-red-300">
            {resp.err}
          </p>
        )}

        {resp.done == 1 && (
          <>
            <div className="fr-cc w-full">
              <Link to={`/arcaderace/${resp.rid}?is_arcade=true`}>
                <Tag
                  className={twMerge(
                    "bg-r2lig text-white font-digi -skew-x-12",
                  )}
                >
                  Watch Race Now
                </Tag>
              </Link>
            </div>
            <div className="fr-sc w-full">
              <Tag
                onClick={reset}
                className="text-red-400 border border-red-300"
              >
                {"reset"}
              </Tag>
            </div>
          </>
        )}

        {validate === true && resp.done !== 1 && (
          <>
            <div className="my-2">
              {loading == true ? (
                <p className="text-acc0 resp-text--1">Entering a Race...</p>
              ) : (
                <Tag
                  onClick={submit}
                  className={twMerge(
                    "bg-acc0/40 -skew-x-12 text-white shadow-md shadow-acc0 font-digi resp-text--1",
                  )}
                >
                  Hop in a Race
                </Tag>
              )}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export const ArcadeRace = () => {
  const [inp, set_inp] = useState({});

  const [resp, set_resp] = useState({});

  const submit = async () => {
    set_resp({ loading: true });
    try {
      let doc = {
        name: inp.name,
        skin: inp.skin,
        trail: inp.trail,
        now: iso(),
      };
      doc.rid = gen_hash(jstr(doc), 10);
      let resp = await q_arcade_enter(doc).queryFn();
      if (getv(resp, "status") == "success") {
        let d = getv(resp, "result");
        if (d.done) {
          // window.open(`/arcaderace/${doc.rid}?is_arcade=true`, `_blank`);
          // set_inp({});
          set_resp({
            done: 1,
            rid: d.rid,
          });
        }
      }

      if (resp.status == "error") set_resp({ loading: false, err: resp.err });
    } catch (err) {
      set_resp({ loading: false, err: err.message });
    }
  };

  const reset = () => {
    set_inp({});
    set_resp({});
  };

  const arcon = {
    inp,
    set_inp,
    resp,
    set_resp,
    submit,
    reset,
  };

  return (
    <ArcadeRaceContext.Provider value={arcon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto xs:min-h-[350px] lg:min-h-[750px]">
          <div className="h-[1rem]"></div>
          <p className="font-digi resp-text-1 text-center text-white my-2">
            Arcade
          </p>
          <InpSection />
          <div class="fc-cc min-h-[10rem] card-basic-bg">
            <span className="my-[2rem] text-white">--OR--</span>
            <p className="resp-text-0 text-white font-digi w-full text-center">
              Join Now
            </p>
            <p className="resp-text-0 text-r2lig font-digi w-full text-center">
              get a FREE Trainer Bike
            </p>

            <p className="resp-text-0 text-r2lig font-digi w-full text-center">
              Trainer Bike can race in FREEcard-basic-bg races for ETH prizes{" "}
            </p>
            <Link className="w-max mx-auto" to="/claim-trainer">
              <p className="pulse-anim cursor-pointer my-1 text-white font-digi text-center resp-text-0 bg-r2lig/30 mx-auto w-max p-1 rounded-md shadow-md shadow-r2lig max-w-[95vw]">
                {"Claim Trainer now"}
              </p>
            </Link>
          </div>
        </div>

        <div className="h-[1rem]"></div>
        <Footer joinmsg={"DNA Links"} />
      </div>
    </ArcadeRaceContext.Provider>
  );
};
