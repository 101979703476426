import { Tag } from "../components/utilityComps.js";
import { cdelay } from "../utils/utils.js";

export const ClearCache = () => {
  const clear = async () => {
    await cdelay(2000);
    localStorage.clear();

    await cdelay(2000);
    window.location = "/";
  };
  return (
    <div>
      <div className="h-[5rem]"></div>
      <div className="fr-cc">
        <Tag onClick={clear} className="bg-red-400 text-white">
          clear cache
        </Tag>
      </div>
    </div>
  );
};
