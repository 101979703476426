import {
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import _ from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext, useNowContext } from "../../App";
import { Loader01c } from "../../components/anims";
import { PopUp, PopupCloseBtn } from "../../components/popup";
import { Card, Img, Tag } from "../../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_factions_blitz_history,
  q_factions_blitz_info,
} from "../../queries/queries";
import { tablecn, elementmap, gendermap } from "../../utils/cn_map";
import { MoVariants } from "../../utils/motion_helper";
import {
  cdelay,
  from_time_mini,
  getv,
  iso_format,
  jparse,
  jstr,
  nano,
  nils,
} from "../../utils/utils";
import { useAuthContext } from "../../wrappers/AuthWrapper";
import { Show_Drop } from "../FQual";
import factions_helper, { facmap } from "./factions_helper";

const BlitzContext = createContext({});
export const useBlitzContext = () => useContext(BlitzContext);

const Rules = ({ bdoc }) => {
  // const { f } = useFQualContext();
  const rules = bdoc.rules || [];
  const title_rules = bdoc.title_rules || [];

  return (
    <>
      <>
        <div className="w-full">
          {_.isEmpty(title_rules) &&
            rules.map((c, i) => {
              return (
                <div className="flex flex-row justify-start items-start resp-gap-1 my-1">
                  <span className="resp-text-0">{i + 1}.</span>
                  <p className="resp-text-0 whitespace-pre flex-1">{c}</p>
                </div>
              );
            })}
        </div>
        <div className="w-full">
          <table className={twMerge(tablecn.table, "w-full")}>
            {title_rules.map(([a, b]) => (
              <tr className="thintdrow">
                <td className="resp-text-0 text-acc0">
                  <p className=" whitespace-pre-line resp-px-2 resp-text--2 font-digi">
                    {a}
                  </p>
                </td>
                <td className="resp-text-0">
                  <p className="whitespace-pre-line resp-px-2 resp-text--2 font-mon">
                    {b}
                  </p>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </>
    </>
  );
};

const BlitzHistory = () => {
  const { history = [], set_id } = useBlitzContext();
  const { now } = useNowContext();

  return (
    <Card className="w-full card-dark-bg card-grad-border my-2 resp-text--2 overflow-auto">
      <div class="fr-sc">
        <p class="text-white font-digi resp-text-1">History</p>
      </div>

      <table class="thintdrowp4-table w-max mx-auto resp-text--1">
        <thead>
          <tr>
            <td>id</td>
            <td>Started</td>
            <td>Ended</td>
            <td>Winner</td>
          </tr>
        </thead>
        <tbody>
          {history.map((b) => {
            let faction = getv(b, "winmap.faction");
            return (
              <tr>
                <td>
                  <Tag
                    onClick={() => {
                      console.log("blitz:setid", b.blzid);
                      set_id(b.blzid);
                    }}
                    className="bg-acc4/40 font-mono font-bold"
                  >
                    {b.blzid}
                  </Tag>
                </td>
                <td>{iso_format(b.starts_at)}</td>
                <td>
                  {b.status == "finished" ? (
                    <span>{iso_format(b.starts_at)}</span>
                  ) : (
                    <>
                      {nano(b.starts_at) > now ? (
                        <span className="text-yellow-300">
                          Starts in {from_time_mini(b.starts_at)}
                        </span>
                      ) : (
                        <span className="text-yellow-300">Ongoing</span>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {b.status == "finished" && !nils(b.winner) ? (
                    <div className="fr-sc resp-gap-2 ">
                      <div class="w-[3rem] aspect-[2/1]">
                        <Img img={factions_helper.facmap[b.winner]?.img} />
                      </div>
                      <span>{factions_helper.facmap[b.winner]?.name}</span>
                    </div>
                  ) : (
                    <div>
                      <span>---</span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const LogsTable = ({ logs, faction }) => {
  const { bdoc } = useBlitzContext();

  const hmap = getv(bdoc, "hmap");
  const rmap = getv(bdoc, "rmap");
  const agmap = getv(bdoc, "agmap");

  return (
    <div class="w-max mx-auto">
      <div class="fc-cc">
        <div class="w-[4rem] aspect-[2/1]">
          <Img img={factions_helper.facmap[faction]?.img} />
        </div>
        <span className="resp-text-0 font-digi text-acc4 text-center">
          {factions_helper.facmap[faction]?.name}
        </span>
      </div>
      {_.isEmpty(logs) ? null : (
        <table className="thintdrowp4-table w-max mx-auto overflow-auto resp-text--2">
          <thead>
            <tr className="resp-text-0 font-digi text-acc4 text-center">
              <td>Race</td>
              <td>Agent</td>
              <td>Bike</td>
            </tr>
          </thead>
          <tbody>
            {logs.map((l, idx) => {
              let h = getv(hmap, l.hid);
              let ag = getv(agmap, l.agid);
              let r = getv(rmap, l.rid);

              return (
                <tr>
                  <td>
                    <Link
                      target={"_blank"}
                      to={`/race/${l.rid}`}
                      className="fr-sc resp-gap-1 text-slate-300"
                    >
                      <span>#{idx + 1}</span>
                      <FontAwesomeIcon icon={faLink} className="resp-text-1" />
                      <div class="fc-ss">
                        <span>Race</span>
                        <span className="resp-text--1 font-mono ">{l.rid}</span>
                        <span>{r?.race_name}</span>
                      </div>
                    </Link>
                  </td>
                  <td>
                    <div class="fc-ss">
                      <span className="text-acc4 font-digi resp-text--1">
                        {ag.name}
                      </span>
                      <div class="fr-sc">
                        <span>Ag#{l.agid}</span>
                        <span>-</span>
                        <span>{ag.rarity}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fc-ss">
                      <span className="text-acc4 font-digi resp-text--1">
                        {h?.name}
                      </span>
                      <div class="fr-sc resp-gap-1">
                        <span>#{l.hid}</span>
                        <FontAwesomeIcon
                          className={elementmap[h.element]?.text}
                          icon={elementmap[h.element]?.icon}
                        />
                        <FontAwesomeIcon
                          className={gendermap[h.gender]?.text}
                          icon={gendermap[h.gender]?.icon}
                        />
                        <span>F{h.fno}</span>
                        <span className="capitalize">{h.type}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
const TaskBox = ({ t, tidx, curr_round, trmap }) => {
  const { bdoc } = useBlitzContext();

  const active = tidx == curr_round?.tidx;
  const tr = trmap[tidx];

  const [pop, set_pop] = useState(false);
  const onclose = () => set_pop(false);

  const [fac_a, fac_b] = getv(bdoc, "factions");

  const logs = useMemo(() => {
    let logs = [];
    for (let i = 0; i < 2; i++) {
      let faction = getv(bdoc, `factions.${i}`);
      let ls = (tr?.logs || []).filter((l) => l.faction == faction);
      logs.push(ls);
    }
    return logs;
  }, [jstr(bdoc), jstr(tr)]);

  return (
    <>
      <div
        onClick={() => set_pop(true)}
        class={twMerge(
          "cursor-pointer",
          "aspect-[1/1] w-[7rem] fc-cc rounded-full bg-r2reg/40 border-2",
          active ? "bg-r2dark/80 border-yellow-400" : "border-transparent",
        )}
      >
        {nils(tr) ? (
          <>
            <span className="opacity-0">-</span>
          </>
        ) : (
          <>
            <span className="">#{tr.ridx + 1}</span>
          </>
        )}
        <span className="resp-text--1 font-digi">
          {_.chain(t).split("_").map(_.upperFirst).join(" ").value()}
        </span>
        {nils(tr) ? (
          <span className="resp-text-1 font-digi text-slate-400">{"0-0"}</span>
        ) : (
          <span className="resp-text-1 font-digi text-yellow-400">
            {getv(tr, "scores.0")}-{getv(tr, "scores.1")}
          </span>
        )}
      </div>

      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        openstate={pop}
        overlayclose={true}
        onClose={() => set_pop(false)}
      >
        <Card className="w-[80rem] card-dark-bg backdrop-blur-md max-w-[98vw] mx-auto card-grad-border">
          <div class="fr-sc">
            <div class="flex-1"></div>
            <PopupCloseBtn closepopup={onclose} />
          </div>
          <div class="h-[25rem] overflow-auto w-full">
            <div class="fc-cc resp-gap-2">
              <span className="resp-text-1 font-digi">
                {_.chain(t).split("_").map(_.upperFirst).join(" ").value()}
              </span>
              {nils(tr) ? (
                <span className="resp-text-1 font-digi text-slate-400">
                  {"0-0"}
                </span>
              ) : (
                <span className="resp-text-1 font-digi text-yellow-400">
                  {getv(tr, "scores.0")}-{getv(tr, "scores.1")}
                </span>
              )}
            </div>

            <div class="grid grid-cols-2 w-full ">
              <div class=" border-r-2 border-slate-600">
                <LogsTable faction={fac_a} logs={logs[0]} />
              </div>
              <div class="">
                <LogsTable faction={fac_b} logs={logs[1]} />
              </div>
            </div>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const BlitzCard = ({}) => {
  const { now } = useNowContext();
  const { bdoc } = useBlitzContext();
  const [fac_a, fac_b] = getv(bdoc, "factions");

  const winner = getv(bdoc, "winner");
  const curr_round = useMemo(() => {
    const rounds = getv(bdoc, "rounds");
    let r = rounds[rounds.length - 1];
    r.rname = `Round #${r.ridx + 1}`;
    return r;
  }, [jstr(bdoc)]);

  const trmap = useMemo(() => {
    // let rourev = _.reverse(_.cloneDeep(getv(bdoc, "rounds")));
    let rourev = _.chain(getv(bdoc, "rounds"))
      .cloneDeep()
      .map((r) => {
        r.rname = `Rnd-${r.ridx + 1}`;
        return r;
      })
      .value();
    let trmap = _.chain(rourev)
      .groupBy("tidx")
      .entries()
      .map(([k, vs]) => {
        let v = _.maxBy(vs, "ridx");
        return [k, v];
      })
      .fromPairs()
      .value();
    console.log("trmap", trmap);
    return trmap;
  }, [jstr(bdoc)]);

  const loser_cn = "grayscale opacity-50";

  return (
    <Card className={"card-basic-bg resp-p-2 w-full min-h-[10rem] "}>
      <div class="fc-cc gap-2 mb-2">
        <p class="resp-text-1 fr-sc gap-2 font-digi">
          <span className="">{facmap[fac_a]?.name}</span>
          <span className="text-yellow-400">vs</span>
          <span className="">{facmap[fac_b]?.name}</span>
        </p>
        {!nils(curr_round) ? (
          <>
            {bdoc?.status == "finished" ? (
              <>
                <p className="text-green-400 font-digi resp-text-1">
                  Winner: {facmap[bdoc?.winner]?.name}
                </p>
                <p className="text-red-300 font-digi resp-text--1">
                  Ended at {iso_format(bdoc?.ends_at)}
                </p>
              </>
            ) : (
              <p className="text-yellow-400 font-digi resp-text-1">
                {curr_round?.rname}
              </p>
            )}
            {now < nano(curr_round?.starts_at) ? (
              <p className="text-yellow-400 resp-text--1">
                Starts in {from_time_mini(curr_round?.starts_at)}
              </p>
            ) : now < nano(curr_round?.ends_at) ? (
              <p className="text-yellow-400 resp-text--1">
                {from_time_mini(curr_round?.ends_at)} left
              </p>
            ) : null}
          </>
        ) : null}
      </div>
      <div class="fr-cc gap-[1rem]">
        <div
          class={twMerge(
            "aspect-[2/1] w-[5rem]",
            !nils(winner) && fac_a !== winner ? loser_cn : "",
          )}
        >
          <Img img={factions_helper.facmap[fac_a]?.img} />
        </div>

        {bdoc.tasks.map((t, tidx) => {
          return (
            <TaskBox
              {...{
                t,
                tidx,
                curr_round,
                trmap,
              }}
              key={tidx}
            />
          );
        })}

        <div
          class={twMerge(
            "aspect-[2/1] w-[5rem]",

            !nils(winner) && fac_b !== winner ? loser_cn : "",
          )}
        >
          <Img img={factions_helper.facmap[fac_b]?.img} />
        </div>
      </div>
    </Card>
  );
};

export const FactionsBlitz = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [id, set_id] = useState(null);

  const [qobin, qobhis] = useQueries([
    q_factions_blitz_info(
      { blzid: id },
      { staleTime: 15 * 1e3, refetchInterval: 15 * 1e3, enabled: !nils(id) },
    ),
    q_factions_blitz_history({}, { staleTime: 2 * 60 * 1e3 }),
  ]);
  const bdoc = useMemo(() => getv(qobin, "data.result"), [qobin.dataUpdatedAt]);
  const history = useMemo(() => {
    let ar = getv(qobhis, "data.result");
    if (!_.isEmpty(ar)) set_id(getv(ar, "0.blzid"));
    return ar;
  }, [qobhis.dataUpdatedAt]);
  const bcon = {
    id,
    set_id,

    bdoc,
    qobin,

    qobhis,
    history,
  };

  return (
    <BlitzContext.Provider value={bcon}>
      <div class="">
        {qobin.isLoading ? (
          <Loader01c />
        ) : qiserr(qobin) ? (
          <p class="text-center text-red-300">{qiserr(qobin)}</p>
        ) : qissuccesss(qobin) ? (
          <>
            {!nils(bdoc?.name) && (
              <p class="text-center text-acc0 resp-text-2 font-digi my-1 text-white">
                {bdoc?.name}
              </p>
            )}
            <p class="text-center resp-text--1 font-digi my-1 text-white">
              Blitz#{bdoc?.blzid}
            </p>
            {!_.isEmpty(bdoc.title_rules) && (
              <div class="mx-auto xs:max-w-[98vw] lg:max-w-[40rem]">
                <Show_Drop show_txt="Show Rules">
                  <Rules bdoc={bdoc} />
                </Show_Drop>
              </div>
            )}
            <div class=" ">{!_.isEmpty(bdoc) && <BlitzCard />}</div>
          </>
        ) : null}

        <BlitzHistory />
      </div>
    </BlitzContext.Provider>
  );
};
