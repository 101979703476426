import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { Card } from "../components/utilityComps";
import { qissuccesss, q_sheet_pages_chainplay } from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { getv, nils } from "../utils/utils";

const { useState, useMemo } = require("react");
const { useQueries } = require("react-query");

const TableView = ({ head, d }) => {
  return (
    <div>
      {!nils(head) && (
        <p class="text-acc4 resp-text-1 text-left font-digi mt-[1rem] mb-1">
          {head}
        </p>
      )}
      <Card className={"card-dark-bg w-full rounded-xl"}>
        <table className={twMerge("thintdrowp4-table", "w-full")}>
          <thead>
            {(d || []).slice(0, 1).map((r) => {
              return (
                <tr>
                  {r.map((e) => {
                    return <td class="text-left text-acc4 font-digi">{e}</td>;
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {(d || []).slice(1).map((r) => {
              return (
                <tr>
                  {r.map((e) => {
                    return <td class="text-left font-mon">{e}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export const ChainPlayPage = () => {
  const [q] = useQueries([q_sheet_pages_chainplay({})]);
  const o = useMemo(() => getv(q, "data.result"), [q.dataUpdatedAt]);

  return (
    <>
      <div class="h-page">
        <div class="mx-auto w-[80rem] max-w-[98vw]">
          <div class="h-[2rem]"></div>
          <p class="my-2 resp-text-2 font-digi text-acc4 text-center">
            chainplay.gg
          </p>
          {qissuccesss(q) && (
            <>
              <div class="grid xs:grid-cols-3  lg:grid-cols-6 resp-gap-1 resp-text--1  text-slate-200">
                <div class="col-span-3">
                  <Card className={"card-basic-bg w-full h-full"}>
                    <div class="resp-text-1 font-digi text-acc4 text-center">
                      {getv(o, "grid1.head")}
                    </div>
                    <hr className="my-2" />
                    <>
                      {(getv(o, "grid1.info") || []).map((r) => {
                        return (
                          <div class="fr-sc w-full resp-gap-2">
                            <FontAwesomeIcon
                              className="text-[0.5rem]"
                              icon={faCircle}
                            />
                            {r.map((e) => {
                              return <p class="my-1 font-mon px-1">{e}</p>;
                            })}
                          </div>
                        );
                      })}
                    </>
                    <hr className="my-2" />

                    <TableView
                      head="Rounds"
                      d={getv(o, "grid1.rounds") || []}
                    />

                    <TableView
                      head="Segments"
                      d={getv(o, "grid1.segments") || []}
                    />
                  </Card>
                </div>
                <div class="col-span-3">
                  <Card className={"card-basic-bg w-full h-full"}>
                    <div class="resp-text-1 font-digi text-acc4 text-center">
                      {getv(o, "grid2.head")}
                    </div>
                    <TableView
                      head="Scoring"
                      d={getv(o, "grid2.scoring") || []}
                    />
                    <div class="flex flex-row justify-around items-start gap-2">
                      <TableView head="" d={getv(o, "grid2.posmap") || []} />
                      <TableView head="" d={getv(o, "grid2.overall") || []} />
                    </div>
                  </Card>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
